// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

var defaultError = {
  type_: "server_error",
  code: "",
  message: "Something went wrong"
};

function getError(dict, str) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            type_: Utils$OrcaPaymentPage.getString(json, "type", ""),
                            code: Utils$OrcaPaymentPage.getString(json, "code", ""),
                            message: Utils$OrcaPaymentPage.getString(json, "message", "")
                          };
                  })), defaultError);
}

function itemToObjMapper(dict) {
  return {
          error: getError(dict, "error")
        };
}

export {
  defaultError ,
  getError ,
  itemToObjMapper ,
}
/* Utils-OrcaPaymentPage Not a pure module */
