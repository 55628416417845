// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Recoil from "recoil";
import * as Recoil$1 from "./recoil";
import * as LoggerUtils$OrcaPaymentPage from "../Utilities/LoggerUtils.bs.js";

var RecoilRoot = {};

function atom(prim0, prim1) {
  return Recoil$1.atom(prim0, prim1);
}

function useLoggedRecoilState(atomName, type_, logger) {
  var match = Recoil.useRecoilState(atomName);
  var setState = match[1];
  var newSetState = function (value) {
    LoggerUtils$OrcaPaymentPage.logInputChangeInfo(type_, logger);
    setState(value);
  };
  return [
          match[0],
          newSetState
        ];
}

function useLoggedSetRecoilState(atomName, type_, logger) {
  var setState = Recoil.useSetRecoilState(atomName);
  return function (value) {
    LoggerUtils$OrcaPaymentPage.logInputChangeInfo(type_, logger);
    setState(value);
  };
}

export {
  RecoilRoot ,
  atom ,
  useLoggedRecoilState ,
  useLoggedSetRecoilState ,
}
/* recoil Not a pure module */
