// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Types$OrcaPaymentPage from "./Types.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Sentry$OrcaPaymentPage from "../hyper-log-catcher/Sentry.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as Elements$OrcaPaymentPage from "./Elements.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "../Utilities/ApiEndpoint.bs.js";
import * as HyperLogger$OrcaPaymentPage from "../hyper-log-catcher/HyperLogger.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "../Utilities/LoggerUtils.bs.js";
import * as PaymentSession$OrcaPaymentPage from "./PaymentSession.bs.js";
import * as ApplePayHelpers$OrcaPaymentPage from "../Utilities/ApplePayHelpers.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "../Utilities/EventListenerManager.bs.js";
import * as PaymentMethodsManagementElements$OrcaPaymentPage from "./PaymentMethodsManagementElements.bs.js";

function checkAndAppend(selector, child) {
  if (Caml_option.nullable_to_opt(document.querySelector(selector)) === undefined) {
    document.body.appendChild(child);
    return ;
  }
  
}

if (document.querySelectorAll("script[src=\"" + sentryScriptUrl + "\"]").length === 0 && typeof sentryScriptUrl !== "undefined") {
  try {
    var script = document.createElement("script");
    script.src = sentryScriptUrl;
    script.onerror = (function (err) {
        console.log("ERROR DURING LOADING Sentry on HyperLoader", err);
      });
    script.onload = (function () {
        Sentry$OrcaPaymentPage.initiateSentryJs(sentryDSN);
      });
    window.onload = (function () {
        document.body.appendChild(script);
      });
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.log("Sentry load exited", e);
  }
}

function preloadFile(type_, hrefOpt) {
  var href = hrefOpt !== undefined ? hrefOpt : "";
  var link = document.createElement("link");
  link.href = href;
  link.as = type_;
  link.rel = "prefetch";
  link.crossorigin = "anonymous";
  checkAndAppend("link[href=\"" + href + "\"]", link);
}

function preloader() {
  preloadFile("script", ApiEndpoint$OrcaPaymentPage.sdkDomainUrl + "/app.js");
  preloadFile("style", ApiEndpoint$OrcaPaymentPage.sdkDomainUrl + "/app.css");
  preloadFile("image", ApiEndpoint$OrcaPaymentPage.sdkDomainUrl + "/icons/orca.svg");
  preloadFile("style", "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600;700;800&display=swap");
  preloadFile("style", "https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&family=Qwitcher+Grypen:wght@400;700&display=swap");
  preloadFile("script", "https://js.braintreegateway.com/web/3.88.4/js/paypal-checkout.min.js");
  preloadFile("script", "https://js.braintreegateway.com/web/3.88.4/js/client.min.js");
}

function handleHyperApplePayMounted($$event) {
  var json = $$event.data;
  var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
  var applePaySessionRef = {
    contents: null
  };
  var componentName = Utils$OrcaPaymentPage.getString(dict, "componentName", "payment");
  if (Core__Option.isSome(dict["hyperApplePayCanMakePayments"])) {
    var msg = Object.fromEntries([
          [
            "applePayCanMakePayments",
            true
          ],
          [
            "componentName",
            componentName
          ]
        ]);
    return Window$OrcaPaymentPage.sendPostMessageJSON($$event.source, msg);
  }
  if (!Core__Option.isSome(dict["hyperApplePayButtonClicked"])) {
    return ;
  }
  var paymentRequest = Core__Option.getOr(dict["paymentRequest"], null);
  var applePayPresent = dict["applePayPresent"];
  var clientSecret = Utils$OrcaPaymentPage.getString(dict, "clientSecret", "");
  var publishableKey = Utils$OrcaPaymentPage.getString(dict, "publishableKey", "");
  var isTaxCalculationEnabled = Utils$OrcaPaymentPage.getBool(dict, "isTaxCalculationEnabled", false);
  var sdkSessionId = Utils$OrcaPaymentPage.getString(dict, "sdkSessionId", "");
  var analyticsMetadata = Utils$OrcaPaymentPage.getJsonFromDict(dict, "analyticsMetadata", null);
  var logger = HyperLogger$OrcaPaymentPage.make(sdkSessionId, "Loader", clientSecret, publishableKey, analyticsMetadata, undefined);
  var callBackFunc = function (payment) {
    var msg = Object.fromEntries([
          [
            "applePayPaymentToken",
            payment.token
          ],
          [
            "applePayBillingContact",
            payment.billingContact
          ],
          [
            "applePayShippingContact",
            payment.shippingContact
          ],
          [
            "componentName",
            componentName
          ]
        ]);
    Window$OrcaPaymentPage.sendPostMessageJSON($$event.source, msg);
  };
  var resolvePromise = function (param) {
    var msg = Object.fromEntries([
          [
            "showApplePayButton",
            true
          ],
          [
            "componentName",
            componentName
          ]
        ]);
    Window$OrcaPaymentPage.sendPostMessageJSON($$event.source, msg);
  };
  ApplePayHelpers$OrcaPaymentPage.startApplePaySession(paymentRequest, applePaySessionRef, applePayPresent, logger, callBackFunc, resolvePromise, clientSecret, publishableKey, isTaxCalculationEnabled);
}

EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleHyperApplePayMounted, "onHyperApplePayMount");

function make(publishableKey, options, analyticsInfo) {
  try {
    var isPreloadEnabled = Utils$OrcaPaymentPage.getBool(Utils$OrcaPaymentPage.getDictFromJson(Core__Option.getOr(options, null)), "isPreloadEnabled", true);
    var shouldUseTopRedirection = Utils$OrcaPaymentPage.getBool(Utils$OrcaPaymentPage.getDictFromJson(Core__Option.getOr(options, null)), "shouldUseTopRedirection", false);
    var analyticsMetadata = Utils$OrcaPaymentPage.getJsonObjectFromDict(Utils$OrcaPaymentPage.getDictFromObj(Utils$OrcaPaymentPage.getDictFromJson(Core__Option.getOr(options, null)), "analytics"), "metadata");
    if (isPreloadEnabled) {
      preloader();
    }
    var analyticsInfoDict = Core__Option.getOr(Core__Option.flatMap(analyticsInfo, Core__JSON.Decode.object), {});
    var sessionID = Utils$OrcaPaymentPage.getString(analyticsInfoDict, "sessionID", "hyp_" + Utils$OrcaPaymentPage.generateRandomString(8));
    var sdkTimestamp = Utils$OrcaPaymentPage.getString(analyticsInfoDict, "timeStamp", Date.now().toString());
    var logger = HyperLogger$OrcaPaymentPage.make(sessionID, "Loader", undefined, publishableKey, analyticsMetadata, undefined);
    var isReadyPromise = new Promise((function (resolve, param) {
            var handleOnReady = function ($$event) {
              var json = $$event.data;
              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
              if (Core__Option.getOr(Core__JSON.Decode.bool(Core__Option.getOr(dict["ready"], false)), false)) {
                return resolve(Date.now());
              }
              
            };
            EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleOnReady, "handleOnReady");
          }));
    if (options !== undefined) {
      var customBackendUrl = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(options), (function (x) {
                      return x["customBackendUrl"];
                    })), Core__JSON.Decode.string), "");
      if (customBackendUrl === "") {
        
      } else {
        ApiEndpoint$OrcaPaymentPage.setApiEndPoint(customBackendUrl);
      }
    }
    Sentry$OrcaPaymentPage.sentryLogger(function () {
          logger.setMerchantId(publishableKey);
          logger.setSessionId(sessionID);
          logger.setLogInfo(Window$OrcaPaymentPage.hrefWithoutSearch, undefined, "APP_INITIATED", sdkTimestamp, undefined, undefined, undefined, undefined);
        });
    var isSecure = window.location.protocol === "https:";
    var isLocal = Core__Option.isSome([
            "localhost",
            "127.0.0.1"
          ].find(function (url) {
              return window.location.hostname.includes(url);
            }));
    if (!isSecure && !isLocal) {
      ErrorUtils$OrcaPaymentPage.manageErrorWarning("HTTP_NOT_ALLOWED", Window$OrcaPaymentPage.hrefWithoutSearch, logger);
      Js_exn.raiseError("Insecure domain: " + Window$OrcaPaymentPage.hrefWithoutSearch);
    }
    var hyperMethod = window.HyperMethod;
    if (hyperMethod == null) {
      var loaderTimestamp = Date.now().toString();
      Sentry$OrcaPaymentPage.sentryLogger(function () {
            logger.setLogInfo("loadHyper has been called", undefined, "LOADER_CALLED", loaderTimestamp, undefined, undefined, undefined, undefined);
            if (publishableKey === "" || !(publishableKey.startsWith("pk_dev_") || publishableKey.startsWith("pk_snd_") || publishableKey.startsWith("pk_prd_"))) {
              ErrorUtils$OrcaPaymentPage.manageErrorWarning("INVALID_PK", undefined, logger);
            }
            if (document.querySelectorAll("script[src=\"https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js\"]").length !== 0) {
              return ;
            }
            var script = document.createElement("script");
            script.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
            script.onerror = (function (err) {
                console.log("ERROR DURING LOADING APPLE PAY", err);
              });
            document.body.appendChild(script);
          });
      if (document.querySelectorAll("script[src=\"https://pay.google.com/gp/p/js/pay.js\"]").length === 0) {
        var googlePayScript = document.createElement("script");
        googlePayScript.src = "https://pay.google.com/gp/p/js/pay.js";
        googlePayScript.onerror = (function (err) {
            logger.setLogError("ERROR DURING LOADING GOOGLE PAY SCRIPT", JSON.stringify(Utils$OrcaPaymentPage.formatException(err)), "GOOGLE_PAY_SCRIPT", undefined, undefined, undefined, undefined, "GOOGLE_PAY");
          });
        document.body.appendChild(googlePayScript);
        logger.setLogInfo("GooglePay Script Loaded", undefined, "GOOGLE_PAY_SCRIPT", undefined, undefined, undefined, undefined, undefined);
      }
      if (document.querySelectorAll("script[src=\"https://img.mpay.samsung.com/gsmpi/sdk/samsungpay_web_sdk.js\"]").length === 0) {
        var samsungPayScript = document.createElement("script");
        samsungPayScript.src = "https://img.mpay.samsung.com/gsmpi/sdk/samsungpay_web_sdk.js";
        samsungPayScript.onerror = (function (err) {
            logger.setLogError("ERROR DURING LOADING SAMSUNG PAY SCRIPT", JSON.stringify(Utils$OrcaPaymentPage.formatException(err)), "SAMSUNG_PAY_SCRIPT", undefined, undefined, undefined, undefined, "SAMSUNG_PAY");
          });
        document.body.appendChild(samsungPayScript);
        samsungPayScript.onload = (function () {
            logger.setLogInfo("SamsungPay Script Loaded", undefined, "SAMSUNG_PAY_SCRIPT", undefined, undefined, undefined, undefined, undefined);
          });
      }
      var iframeRef = {
        contents: []
      };
      var clientSecret = {
        contents: ""
      };
      var ephemeralKey = {
        contents: ""
      };
      var setIframeRef = function (ref) {
        iframeRef.contents.push(ref);
      };
      var retrievePaymentIntentFn = function (clientSecret) {
        var headers = {
          Accept: "application/json",
          "api-key": publishableKey
        };
        var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(publishableKey, undefined);
        var paymentIntentID = Utils$OrcaPaymentPage.getPaymentId(clientSecret);
        var retrievePaymentUrl = endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret;
        LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL_INIT", undefined, undefined, "Request", retrievePaymentUrl, undefined, undefined, logger, "INFO", "API", undefined);
        return fetch(retrievePaymentUrl, {
                        method: "GET",
                        headers: Caml_option.some(new Headers(headers))
                      }).then(function (resp) {
                      var statusCode = resp.status.toString();
                      if (statusCode.charAt(0) !== "2") {
                        resp.json().then(function (data) {
                              LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL", statusCode, data, "Err", retrievePaymentUrl, undefined, undefined, logger, "ERROR", "API", undefined);
                              return Promise.resolve();
                            });
                      } else {
                        LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL", statusCode, undefined, "Response", retrievePaymentUrl, undefined, undefined, logger, "INFO", "API", undefined);
                      }
                      return resp.json();
                    }).then(function (data) {
                    return Promise.resolve(Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                      "paymentIntent",
                                      data
                                    ]]));
                  });
      };
      var confirmPaymentWrapper = function (payload, isOneClick, result, isSdkButtonOpt) {
        var isSdkButton = isSdkButtonOpt !== undefined ? isSdkButtonOpt : false;
        var confirmTimestamp = Date.now();
        var confirmParams = Core__Option.getOr(Core__Option.flatMap(Core__JSON.Decode.object(payload), (function (x) {
                    return x["confirmParams"];
                  })), {});
        var redirect = Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(payload), "redirect", "if_required");
        var url = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(confirmParams), (function (x) {
                        return x["return_url"];
                      })), Core__JSON.Decode.string), "");
        var postSubmitMessage = function (message) {
          iframeRef.contents.forEach(function (ifR) {
                Window$OrcaPaymentPage.iframePostMessage(ifR, message);
              });
        };
        return new Promise((function (resolve1, param) {
                      isReadyPromise.then(function (readyTimestamp) {
                            var handleMessage = function ($$event) {
                              var json = $$event.data;
                              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                              var val = dict["submitSuccessful"];
                              if (val === undefined) {
                                return ;
                              }
                              LoggerUtils$OrcaPaymentPage.logApi("CONFIRM_PAYMENT", undefined, undefined, "Method", undefined, "confirmPayment", val, logger, undefined, undefined, undefined);
                              var data = Core__Option.getOr(dict["data"], {});
                              var returnUrl = Core__Option.getOr(Core__Option.flatMap(dict["url"], Core__JSON.Decode.string), url);
                              if (isOneClick) {
                                iframeRef.contents.forEach(function (ifR) {
                                      Window$OrcaPaymentPage.iframePostMessage(ifR, Object.fromEntries([[
                                                  "oneClickDoSubmit",
                                                  false
                                                ]]));
                                    });
                              }
                              postSubmitMessage(dict);
                              var submitSuccessfulValue = Core__Option.getOr(Core__JSON.Decode.bool(val), false);
                              if (isSdkButton && submitSuccessfulValue || submitSuccessfulValue && redirect === "always") {
                                return Window$OrcaPaymentPage.replaceRootHref(returnUrl, shouldUseTopRedirection);
                              } else if (submitSuccessfulValue) {
                                return resolve1(data);
                              } else {
                                return resolve1(json);
                              }
                            };
                            var message = isOneClick ? Object.fromEntries([[
                                      "oneClickDoSubmit",
                                      result
                                    ]]) : Object.fromEntries([
                                    [
                                      "doSubmit",
                                      true
                                    ],
                                    [
                                      "clientSecret",
                                      clientSecret.contents
                                    ],
                                    [
                                      "confirmTimestamp",
                                      confirmTimestamp
                                    ],
                                    [
                                      "readyTimestamp",
                                      readyTimestamp
                                    ],
                                    [
                                      "confirmParams",
                                      Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                            [
                                              "return_url",
                                              url
                                            ],
                                            [
                                              "publishableKey",
                                              publishableKey
                                            ],
                                            [
                                              "redirect",
                                              redirect
                                            ]
                                          ])
                                    ]
                                  ]);
                            EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleMessage, "onSubmit");
                            postSubmitMessage(message);
                            return Promise.resolve(null);
                          });
                    }));
      };
      var confirmPayment = function (payload) {
        return confirmPaymentWrapper(payload, false, true, undefined);
      };
      var confirmOneClickPayment = function (payload, result) {
        return confirmPaymentWrapper(payload, true, result, undefined);
      };
      var confirmPaymentViaSDKButton = function (payload) {
        return confirmPaymentWrapper(payload, false, true, true);
      };
      var handleSdkConfirm = function ($$event) {
        var json = $$event.data;
        var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
        var payload = dict["handleSdkConfirm"];
        if (payload !== undefined) {
          confirmPaymentViaSDKButton(payload);
          return ;
        }
        
      };
      EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleSdkConfirm, "handleSdkConfirm");
      var elements = function (elementsOptions) {
        var elementsOptionsDict = Core__JSON.Decode.object(elementsOptions);
        Core__Option.forEach(elementsOptionsDict, (function (x) {
                x["launchTime"] = Date.now();
              }));
        var clientSecretId = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(elementsOptionsDict, (function (x) {
                        return x["clientSecret"];
                      })), Core__JSON.Decode.string), "");
        var elementsOptions$1 = Core__Option.mapOr(elementsOptionsDict, elementsOptions, (function (prim) {
                return prim;
              }));
        clientSecret.contents = clientSecretId;
        new Promise((function (resolve, param) {
                  logger.setClientSecret(clientSecretId);
                  resolve(null);
                })).then(function (param) {
              logger.setLogInfo(Window$OrcaPaymentPage.hrefWithoutSearch, undefined, "ORCA_ELEMENTS_CALLED", undefined, undefined, undefined, undefined, undefined);
              return Promise.resolve();
            });
        return Elements$OrcaPaymentPage.make(elementsOptions$1, setIframeRef, clientSecretId, sessionID, publishableKey, logger, analyticsMetadata, Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(Core__Option.getOr(options, null)), "customBackendUrl", ""), shouldUseTopRedirection);
      };
      var paymentMethodsManagementElements = function (paymentMethodsManagementElementsOptions) {
        var paymentMethodsManagementElementsOptionsDict = Core__JSON.Decode.object(paymentMethodsManagementElementsOptions);
        Core__Option.forEach(paymentMethodsManagementElementsOptionsDict, (function (x) {
                x["launchTime"] = Date.now();
              }));
        var ephemeralKeyId = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(paymentMethodsManagementElementsOptionsDict, (function (x) {
                        return x["ephemeralKey"];
                      })), Core__JSON.Decode.string), "");
        var paymentMethodsManagementElementsOptions$1 = Core__Option.mapOr(paymentMethodsManagementElementsOptionsDict, paymentMethodsManagementElementsOptions, (function (prim) {
                return prim;
              }));
        ephemeralKey.contents = ephemeralKeyId;
        new Promise((function (resolve, param) {
                  logger.setEphemeralKey(ephemeralKeyId);
                  resolve(null);
                })).then(function (param) {
              logger.setLogInfo(Window$OrcaPaymentPage.hrefWithoutSearch, undefined, "PAYMENT_MANAGEMENT_ELEMENTS_CALLED", undefined, undefined, undefined, undefined, undefined);
              return Promise.resolve();
            });
        return PaymentMethodsManagementElements$OrcaPaymentPage.make(paymentMethodsManagementElementsOptions$1, setIframeRef, ephemeralKeyId, sessionID, publishableKey, logger, analyticsMetadata, Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(Core__Option.getOr(options, null)), "customBackendUrl", ""));
      };
      var confirmCardPaymentFn = function (clientSecretId, data, _options) {
        var decodedData = Core__Option.getOr(Core__Option.flatMap(data, Core__JSON.Decode.object), {});
        return new Promise((function (resolve, param) {
                      iframeRef.contents.map(function (iframe) {
                            Window$OrcaPaymentPage.iframePostMessage(iframe, Object.fromEntries([
                                      [
                                        "doSubmit",
                                        true
                                      ],
                                      [
                                        "clientSecret",
                                        clientSecretId
                                      ],
                                      [
                                        "confirmParams",
                                        Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                                "publishableKey",
                                                publishableKey
                                              ]])
                                      ]
                                    ]));
                            var handleMessage = function ($$event) {
                              var json = $$event.data;
                              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                              var val = dict["submitSuccessful"];
                              if (val === undefined) {
                                return resolve(json);
                              }
                              LoggerUtils$OrcaPaymentPage.logApi("CONFIRM_CARD_PAYMENT", undefined, undefined, "Method", undefined, "confirmCardPayment", val, logger, undefined, undefined, undefined);
                              var url = Utils$OrcaPaymentPage.getString(decodedData, "return_url", "/");
                              if (Core__Option.getOr(Core__JSON.Decode.bool(val), false) && url !== "/") {
                                return Window$OrcaPaymentPage.replaceRootHref(url, shouldUseTopRedirection);
                              } else {
                                return resolve(json);
                              }
                            };
                            EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleMessage, "");
                          });
                    }));
      };
      var addAmountToDict = function (dict, currency) {
        if (Core__Option.isNone(dict["amount"])) {
          console.error("Amount is not specified, please input an amount");
        }
        var amount = Core__Option.getOr(dict["amount"], 0.0);
        dict["amount"] = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
              [
                "currency",
                currency
              ],
              [
                "value",
                amount
              ]
            ]);
        return dict;
      };
      var paymentRequest = function (options) {
        var optionsDict = Utils$OrcaPaymentPage.getDictFromJson(options);
        var currency = Utils$OrcaPaymentPage.getJsonStringFromDict(optionsDict, "currency", "");
        var optionsTotal = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(optionsDict["total"], Core__JSON.Decode.object), (function (x) {
                    return addAmountToDict(x, currency);
                  })), {});
        var displayItems = Utils$OrcaPaymentPage.getJsonArrayFromDict(optionsDict, "displayItems", []);
        var requestPayerName = Utils$OrcaPaymentPage.getJsonStringFromDict(optionsDict, "requestPayerName", "");
        var requestPayerEmail = Utils$OrcaPaymentPage.getJsonBoolValue(optionsDict, "requestPayerEmail", false);
        var requestPayerPhone = Utils$OrcaPaymentPage.getJsonBoolValue(optionsDict, "requestPayerPhone", false);
        var requestShipping = Utils$OrcaPaymentPage.getJsonBoolValue(optionsDict, "requestShipping", false);
        var shippingOptions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(optionsDict["shippingOptions"], Core__JSON.Decode.object), (function (x) {
                    return addAmountToDict(x, currency);
                  })), {});
        var applePayPaymentMethodData = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
              [
                "supportedMethods",
                "https://apple.com/apple-pay"
              ],
              [
                "data",
                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                        "version",
                        12.00
                      ]])
              ]
            ]);
        var methodData = [applePayPaymentMethodData];
        var details = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
              [
                "id",
                publishableKey
              ],
              [
                "displayItems",
                displayItems
              ],
              [
                "total",
                optionsTotal
              ],
              [
                "shippingOptions",
                shippingOptions
              ]
            ]);
        var optionsForPaymentRequest = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
              [
                "requestPayerName",
                requestPayerName
              ],
              [
                "requestPayerEmail",
                requestPayerEmail
              ],
              [
                "requestPayerPhone",
                requestPayerPhone
              ],
              [
                "requestShipping",
                requestShipping
              ],
              [
                "shippingType",
                "shipping"
              ]
            ]);
        return methodData.PaymentRequest(details, optionsForPaymentRequest);
      };
      var initPaymentSession = function (paymentSessionOptions) {
        var clientSecretId = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(paymentSessionOptions), (function (x) {
                        return x["clientSecret"];
                      })), Core__JSON.Decode.string), "");
        clientSecret.contents = clientSecretId;
        new Promise((function (resolve, param) {
                  logger.setClientSecret(clientSecretId);
                  resolve(null);
                })).then(function (param) {
              logger.setLogInfo(Window$OrcaPaymentPage.hrefWithoutSearch, undefined, "PAYMENT_SESSION_INITIATED", undefined, undefined, undefined, undefined, undefined);
              return Promise.resolve();
            });
        return PaymentSession$OrcaPaymentPage.make(paymentSessionOptions, clientSecretId, publishableKey, logger, ephemeralKey.contents, shouldUseTopRedirection);
      };
      var returnObject = {
        confirmOneClickPayment: confirmOneClickPayment,
        confirmPayment: confirmPayment,
        elements: elements,
        confirmCardPayment: confirmCardPaymentFn,
        retrievePaymentIntent: retrievePaymentIntentFn,
        widgets: elements,
        paymentRequest: paymentRequest,
        initPaymentSession: initPaymentSession,
        paymentMethodsManagementElements: paymentMethodsManagementElements
      };
      window.HyperMethod = returnObject;
      return returnObject;
    }
    logger.setLogInfo("orca-sdk initiated", undefined, "APP_REINITIATED", sdkTimestamp, undefined, undefined, undefined, undefined);
    return hyperMethod;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    Sentry$OrcaPaymentPage.captureException(e);
    return Types$OrcaPaymentPage.defaultHyperInstance;
  }
}

export {
  checkAndAppend ,
  preloadFile ,
  preloader ,
  handleHyperApplePayMounted ,
  make ,
}
/*  Not a pure module */
