// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "./PaymentHelpers.bs.js";

function taxResponseToObjMapper(resp) {
  return Core__Option.map(Core__JSON.Decode.object(resp), (function (dict) {
                return {
                        payment_id: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                        net_amount: Utils$OrcaPaymentPage.getInt(dict, "net_amount", 0),
                        order_tax_amount: Utils$OrcaPaymentPage.getInt(dict, "order_tax_amount", 0),
                        shipping_cost: Utils$OrcaPaymentPage.getInt(dict, "shipping_cost", 0)
                      };
              }));
}

function calculateTax(shippingAddress, logger, clientSecret, publishableKey, paymentMethodType, sessionIdOpt) {
  var sessionId = sessionIdOpt !== undefined ? Caml_option.valFromOption(sessionIdOpt) : undefined;
  return PaymentHelpers$OrcaPaymentPage.calculateTax(publishableKey, Utils$OrcaPaymentPage.getPaymentId(clientSecret), clientSecret, paymentMethodType, shippingAddress, logger, "", sessionId);
}

export {
  taxResponseToObjMapper ,
  calculateTax ,
}
/* Utils-OrcaPaymentPage Not a pure module */
