// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

var defaultConfirm = {
  return_url: "",
  publishableKey: "",
  redirect: "if_required"
};

function getConfirmParams(dict, str) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            return_url: Utils$OrcaPaymentPage.getString(json, "return_url", ""),
                            publishableKey: Utils$OrcaPaymentPage.getString(json, "publishableKey", ""),
                            redirect: Utils$OrcaPaymentPage.getString(json, "redirect", "if_required")
                          };
                  })), defaultConfirm);
}

function itemToObjMapper(dict) {
  return {
          doSubmit: Utils$OrcaPaymentPage.getBool(dict, "doSubmit", false),
          clientSecret: Utils$OrcaPaymentPage.getString(dict, "clientSecret", ""),
          confirmParams: getConfirmParams(dict, "confirmParams"),
          confirmTimestamp: Utils$OrcaPaymentPage.getFloat(dict, "confirmTimestamp", 0.0),
          readyTimestamp: Utils$OrcaPaymentPage.getFloat(dict, "readyTimestamp", 0.0)
        };
}

export {
  defaultConfirm ,
  getConfirmParams ,
  itemToObjMapper ,
}
/* Utils-OrcaPaymentPage Not a pure module */
