// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__Int from "@rescript/core/src/Core__Int.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Float from "@rescript/core/src/Core__Float.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import Phone_numberJson from "./Phone_number.json";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as Country$OrcaPaymentPage from "../Country.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "./ErrorUtils.bs.js";
import * as HyperLogger$OrcaPaymentPage from "../hyper-log-catcher/HyperLogger.bs.js";
import * as PaymentDetails$OrcaPaymentPage from "../PaymentDetails.bs.js";
import * as PostalCodeType$OrcaPaymentPage from "../Types/PostalCodeType.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "./EventListenerManager.bs.js";

var phoneNumberJson = Phone_numberJson;

function getJsonFromArrayOfJson(arr) {
  return Object.fromEntries(arr);
}

function messageWindow($$window, targetOriginOpt, messageArr) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  $$window.postMessage(Object.fromEntries(messageArr), targetOrigin);
}

function messageTopWindow(targetOriginOpt, messageArr) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  messageWindow(window.top, targetOrigin, messageArr);
}

function messageParentWindow(targetOriginOpt, messageArr) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  messageWindow(window.parent, targetOrigin, messageArr);
}

function messageCurrentWindow(targetOriginOpt, messageArr) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  messageWindow(window, targetOrigin, messageArr);
}

function handleOnFocusPostMessage(targetOriginOpt) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  messageParentWindow(targetOrigin, [[
          "focus",
          true
        ]]);
}

function handleOnCompleteDoThisMessage(targetOriginOpt) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  messageParentWindow(targetOrigin, [[
          "completeDoThis",
          true
        ]]);
}

function handleOnBlurPostMessage(targetOriginOpt) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  messageParentWindow(targetOrigin, [[
          "blur",
          true
        ]]);
}

function handleOnClickPostMessage(targetOriginOpt, ev) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  messageParentWindow(targetOrigin, [
        [
          "clickTriggered",
          true
        ],
        [
          "event",
          JSON.stringify(ev)
        ]
      ]);
}

function handleOnConfirmPostMessage(targetOriginOpt, isOneClickOpt) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  var isOneClick = isOneClickOpt !== undefined ? isOneClickOpt : false;
  var message = isOneClick ? "oneClickConfirmTriggered" : "confirmTriggered";
  messageParentWindow(targetOrigin, [[
          message,
          true
        ]]);
}

function getOptionString(dict, key) {
  return Core__Option.flatMap(dict[key], Core__JSON.Decode.string);
}

function getString(dict, key, $$default) {
  return Core__Option.getOr(getOptionString(dict, key), $$default);
}

function getStringFromJson(json, $$default) {
  return Core__Option.getOr(Core__JSON.Decode.string(json), $$default);
}

function getInt(dict, key, $$default) {
  return Core__Option.getOr(Core__Option.flatMap(dict[key], Core__JSON.Decode.$$float), $$default) | 0;
}

function getFloatFromString(str, $$default) {
  return Core__Option.getOr(Core__Float.fromString(str), $$default);
}

function getFloatFromJson(json, $$default) {
  var str = Core__JSON.Classify.classify(json);
  if (typeof str !== "object") {
    return $$default;
  }
  switch (str.TAG) {
    case "String" :
        return getFloatFromString(str._0, $$default);
    case "Number" :
        return str._0;
    default:
      return $$default;
  }
}

function getFloat(dict, key, $$default) {
  return Core__Option.getOr(Core__Option.map(dict[key], (function (json) {
                    return getFloatFromJson(json, $$default);
                  })), $$default);
}

function getJsonBoolValue(dict, key, $$default) {
  return Core__Option.getOr(dict[key], $$default);
}

function getJsonStringFromDict(dict, key, $$default) {
  return Core__Option.getOr(dict[key], $$default);
}

function getJsonArrayFromDict(dict, key, $$default) {
  return Core__Option.getOr(dict[key], $$default);
}

function getJsonFromDict(dict, key, $$default) {
  return Core__Option.getOr(dict[key], $$default);
}

function getJsonObjFromDict(dict, key, $$default) {
  return Core__Option.getOr(Core__Option.flatMap(dict[key], Core__JSON.Decode.object), $$default);
}

function getDecodedStringFromJson(json, callbackFunc, defaultValue) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(json), callbackFunc), Core__JSON.Decode.string), defaultValue);
}

function getDecodedBoolFromJson(json, callbackFunc, defaultValue) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(json), callbackFunc), Core__JSON.Decode.bool), defaultValue);
}

function getRequiredString(dict, key, $$default, logger) {
  var optionalStr = getOptionString(dict, key);
  if (optionalStr !== undefined) {
    if (optionalStr === "") {
      ErrorUtils$OrcaPaymentPage.manageErrorWarning("REQUIRED_PARAMETER", key, logger);
    }
    return optionalStr;
  } else {
    ErrorUtils$OrcaPaymentPage.manageErrorWarning("REQUIRED_PARAMETER", key, logger);
    return Core__Option.getOr(optionalStr, $$default);
  }
}

function getWarningString(dict, key, $$default, logger) {
  var val = dict[key];
  if (val === undefined) {
    return $$default;
  }
  var val$1 = Core__JSON.Decode.string(val);
  if (val$1 !== undefined) {
    return val$1;
  } else {
    ErrorUtils$OrcaPaymentPage.manageErrorWarning("TYPE_STRING_ERROR", key, logger);
    return $$default;
  }
}

function getDictFromObj(dict, key) {
  return Core__Option.getOr(Core__Option.flatMap(dict[key], Core__JSON.Decode.object), {});
}

function getJsonObjectFromDict(dict, key) {
  return Core__Option.getOr(dict[key], {});
}

function getOptionBool(dict, key) {
  return Core__Option.flatMap(dict[key], Core__JSON.Decode.bool);
}

function getDictFromJson(json) {
  return Core__Option.getOr(Core__JSON.Decode.object(json), {});
}

function getDictFromDict(dict, key) {
  return getDictFromJson(getJsonObjectFromDict(dict, key));
}

function getBool(dict, key, $$default) {
  return Core__Option.getOr(getOptionBool(dict, key), $$default);
}

function getBoolWithWarning(dict, key, $$default, logger) {
  var val = dict[key];
  if (val === undefined) {
    return $$default;
  }
  var val$1 = Core__JSON.Decode.bool(val);
  if (val$1 !== undefined) {
    return val$1;
  } else {
    ErrorUtils$OrcaPaymentPage.manageErrorWarning("TYPE_BOOL_ERROR", key, logger);
    return $$default;
  }
}

function getNumberWithWarning(dict, key, logger, $$default) {
  var val = dict[key];
  if (val === undefined) {
    return $$default;
  }
  var val$1 = Core__JSON.Decode.$$float(val);
  if (val$1 !== undefined) {
    return val$1 | 0;
  } else {
    ErrorUtils$OrcaPaymentPage.manageErrorWarning("TYPE_INT_ERROR", key, logger);
    return $$default;
  }
}

function getOptionalArrayFromDict(dict, key) {
  return Core__Option.flatMap(dict[key], Core__JSON.Decode.array);
}

function getArray(dict, key) {
  return Core__Option.getOr(getOptionalArrayFromDict(dict, key), []);
}

function getStrArray(dict, key) {
  return Core__Option.getOr(getOptionalArrayFromDict(dict, key), []).map(function (json) {
              return getStringFromJson(json, "");
            });
}

function getOptionalStrArray(dict, key) {
  var val = getOptionalArrayFromDict(dict, key);
  if (val !== undefined && val.length !== 0) {
    return val.map(function (json) {
                return getStringFromJson(json, "");
              });
  }
  
}

function getBoolValue(val) {
  return Core__Option.getOr(val, false);
}

function toKebabCase(str) {
  return str.split("").map(function (item, i) {
                if (item.toUpperCase() === item) {
                  return (
                          i !== 0 ? "-" : ""
                        ) + item.toLowerCase();
                } else {
                  return item;
                }
              }).join("");
}

function handleMessage(fun, _errorMessage) {
  var handle = function (ev) {
    try {
      return fun(ev);
    }
    catch (_err){
      return ;
    }
  };
  window.addEventListener("message", handle);
  return (function () {
            window.removeEventListener("message", handle);
          });
}

function useSubmitPaymentData(callback) {
  React.useEffect((function () {
          return handleMessage(callback, "");
        }), [callback]);
}

function useWindowSize() {
  var match = React.useState(function () {
        return [
                0,
                0
              ];
      });
  var setSize = match[1];
  React.useLayoutEffect((function () {
          var updateSize = function () {
            setSize(function (param) {
                  return [
                          window.innerWidth,
                          window.innerHeight
                        ];
                });
          };
          window.addEventListener("resize", updateSize);
          updateSize();
          return (function () {
                    window.removeEventListener("resize", updateSize);
                  });
        }), []);
  return match[0];
}

function mergeJsons(json1, json2) {
  var obj1 = getDictFromJson(json1);
  var obj2 = getDictFromJson(json2);
  var merge = function (obj1, obj2) {
    if (Caml_obj.notequal(obj1, obj2)) {
      Object.keys(obj2).map(function (key) {
            var overrideProp = getJsonObjectFromDict(obj2, key);
            var defaultProp = getJsonObjectFromDict(obj1, key);
            if (Object.keys(getDictFromJson(defaultProp)).length === 0) {
              obj1[key] = overrideProp;
              return ;
            } else if (Core__Option.isSome(Core__JSON.Decode.object(overrideProp)) && Core__Option.isSome(Core__JSON.Decode.object(defaultProp))) {
              return merge(getDictFromJson(defaultProp), getDictFromJson(overrideProp));
            } else if (overrideProp !== defaultProp) {
              obj1[key] = overrideProp;
              return ;
            } else {
              return ;
            }
          });
    }
    
  };
  merge(obj1, obj2);
  return obj1;
}

function postFailedSubmitResponse(errortype, message) {
  var errorDict = Object.fromEntries([
        [
          "type",
          errortype
        ],
        [
          "message",
          message
        ]
      ]);
  messageParentWindow(undefined, [
        [
          "submitSuccessful",
          false
        ],
        [
          "error",
          errorDict
        ]
      ]);
}

function postSubmitResponse(jsonData, url) {
  messageParentWindow(undefined, [
        [
          "submitSuccessful",
          true
        ],
        [
          "data",
          jsonData
        ],
        [
          "url",
          url
        ]
      ]);
}

function getFailedSubmitResponse(errorType, message) {
  return Object.fromEntries([[
                "error",
                Object.fromEntries([
                      [
                        "type",
                        errorType
                      ],
                      [
                        "message",
                        message
                      ]
                    ])
              ]]);
}

function toCamelCase(str) {
  if (str.includes(":")) {
    return str;
  } else {
    return str.toLowerCase().replace(/([-_][a-z])/g, (function (letter, param, param$1) {
                    return letter.toUpperCase();
                  })).replace(/[^a-zA-Z]/g, "");
  }
}

function toSnakeCase(str) {
  return str.replace(/[A-Z]/g, (function (letter, param, param$1) {
                return "_" + letter.toLowerCase();
              }));
}

function transformKeys(json, to) {
  var toCase;
  switch (to) {
    case "CamelCase" :
        toCase = toCamelCase;
        break;
    case "SnakeCase" :
        toCase = toSnakeCase;
        break;
    case "KebabCase" :
        toCase = toKebabCase;
        break;
    
  }
  var dict = getDictFromJson(json);
  return Object.fromEntries(Object.entries(dict).map(function (param) {
                  var value = param[1];
                  var key = param[0];
                  var obj = Core__JSON.Classify.classify(value);
                  if (typeof obj !== "object") {
                    return [
                            toCase(key),
                            value
                          ];
                  }
                  switch (obj.TAG) {
                    case "Bool" :
                        return [
                                toCase(key),
                                value
                              ];
                    case "String" :
                        var str = obj._0;
                        var val = str === "Final" ? "FINAL" : (
                            str === "example" || str === "Adyen" ? "adyen" : str
                          );
                        return [
                                toCase(key),
                                val
                              ];
                    case "Number" :
                        return [
                                toCase(key),
                                obj._0.toString()
                              ];
                    case "Object" :
                        return [
                                toCase(key),
                                transformKeys(obj._0, to)
                              ];
                    case "Array" :
                        return [
                                toCase(key),
                                obj._0.map(function (item) {
                                      if (Core__Option.isSome(Core__JSON.Decode.object(item))) {
                                        return transformKeys(item, to);
                                      } else {
                                        return item;
                                      }
                                    })
                              ];
                    
                  }
                }));
}

function getClientCountry(clientTimeZone) {
  return Core__Option.getOr(Country$OrcaPaymentPage.country.find(function (item) {
                  return Core__Option.isSome(item.timeZones.find(function (i) {
                                  return i === clientTimeZone;
                                }));
                }), Country$OrcaPaymentPage.defaultTimeZone);
}

function removeDuplicate(arr) {
  return arr.filter(function (item, i) {
              return arr.indexOf(item) === i;
            });
}

function isEmailValid(email) {
  var _match = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  if (_match == null) {
    if (email.length > 0) {
      return false;
    } else {
      return ;
    }
  } else {
    return true;
  }
}

function isVpaIdValid(vpaId) {
  var _match = vpaId.match(/^[a-zA-Z0-9]([a-zA-Z0-9.-]{1,50})[a-zA-Z0-9]@[a-zA-Z0-9]{2,}$/);
  if (_match == null) {
    if (vpaId.length > 0) {
      return false;
    } else {
      return ;
    }
  } else {
    return true;
  }
}

function checkEmailValid(email, fn) {
  var _match = email.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  if (_match == null) {
    if (email.value.length > 0) {
      return fn(function (prev) {
                  var newrecord = Caml_obj.obj_dup(prev);
                  newrecord.isValid = false;
                  return newrecord;
                });
    } else {
      return fn(function (prev) {
                  var newrecord = Caml_obj.obj_dup(prev);
                  newrecord.isValid = undefined;
                  return newrecord;
                });
    }
  } else {
    return fn(function (prev) {
                var newrecord = Caml_obj.obj_dup(prev);
                newrecord.isValid = true;
                return newrecord;
              });
  }
}

function validatePhoneNumber(countryCode, number) {
  var phoneNumberDict = Core__Option.getOr(Core__JSON.Decode.object(phoneNumberJson), {});
  var countriesArr = Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(phoneNumberDict["countries"], Core__JSON.Decode.array), []), Core__JSON.Decode.object);
  var filteredArr = countriesArr.filter(function (countryObj) {
        return Core__Option.getOr(Core__Option.flatMap(countryObj["phone_number_code"], Core__JSON.Decode.string), "") === countryCode;
      });
  var obj = filteredArr[0];
  if (obj === undefined) {
    return false;
  }
  var regex = Core__Option.getOr(Core__Option.flatMap(obj["validation_regex"], Core__JSON.Decode.string), "");
  return new RegExp(regex).test(number);
}

function sortBasedOnPriority(sortArr, priorityArr) {
  var finalPriorityArr = priorityArr.filter(function (val) {
        return sortArr.includes(val);
      });
  sortArr.map(function (item) {
        if (finalPriorityArr.includes(item)) {
          return ;
        } else {
          finalPriorityArr.push(item);
          return ;
        }
      });
  return finalPriorityArr;
}

function isAllValid(card, cardSupported, cvc, expiry, zip, paymentMode) {
  if (Core__Option.getOr(card, false) && Core__Option.getOr(cardSupported, false) && Core__Option.getOr(cvc, false) && Core__Option.getOr(expiry, false)) {
    if (paymentMode === "payment") {
      return true;
    } else {
      return zip;
    }
  } else {
    return false;
  }
}

function getCountryPostal(countryCode, postalCodes) {
  return Core__Option.getOr(postalCodes.find(function (item) {
                  return item.iso === countryCode;
                }), PostalCodeType$OrcaPaymentPage.defaultPostalCode);
}

function getCountryNames(list) {
  return Core__Array.reduce(list, [], (function (arr, item) {
                arr.push(item.countryName);
                return arr;
              }));
}

function getBankNames(list, allBanks) {
  return Core__Array.reduce(list, [], (function (arr, item) {
                if (allBanks.includes(item.hyperSwitch)) {
                  arr.push(item.displayName);
                }
                return arr;
              }));
}

function getBankKeys(str, banks, $$default) {
  return Core__Option.getOr(banks.find(function (item) {
                  return item.displayName === str;
                }), $$default).hyperSwitch;
}

function constructClass(classname, dict) {
  var puseduoArr = [];
  var modifiedArr = [];
  Object.entries(dict).map(function (entry) {
        var value = entry[1];
        var key = entry[0];
        var $$class;
        if (!key.startsWith(":") && !key.startsWith(".")) {
          var str = Core__JSON.Decode.string(value);
          $$class = str !== undefined ? toKebabCase(key) + ":" + str : "";
        } else if (key.startsWith(":")) {
          var obj = Core__JSON.Decode.object(value);
          if (obj !== undefined) {
            var style = Object.entries(obj).map(function (entry) {
                  var str = Core__JSON.Decode.string(entry[1]);
                  if (str !== undefined) {
                    return toKebabCase(entry[0]) + ":" + str;
                  } else {
                    return "";
                  }
                });
            $$class = "." + classname + key + " {" + style.join(";") + "}";
          } else {
            $$class = "";
          }
        } else if (key.startsWith(".")) {
          var obj$1 = Core__JSON.Decode.object(value);
          if (obj$1 !== undefined) {
            var style$1 = Object.entries(obj$1).map(function (entry) {
                  var str = Core__JSON.Decode.string(entry[1]);
                  if (str !== undefined) {
                    return toKebabCase(entry[0]) + ":" + str;
                  } else {
                    return "";
                  }
                });
            $$class = key + " {" + style$1.join(";") + "} ";
          } else {
            $$class = "";
          }
        } else {
          $$class = "";
        }
        if (!key.startsWith(":") && !key.startsWith(".")) {
          modifiedArr.push($$class);
          return ;
        } else if (key.startsWith(":") || key.startsWith(".")) {
          puseduoArr.push($$class);
          return ;
        } else {
          return ;
        }
      });
  if (classname.length === 0) {
    return modifiedArr.join(";") + " " + puseduoArr.join(" ");
  } else {
    return "." + classname + " {" + modifiedArr.join(";") + "} " + puseduoArr.join(" ");
  }
}

function generateStyleSheet(classname, dict, id) {
  var createStyle = function () {
    var style = document.createElement("style");
    style.type = "text/css";
    style.id = id;
    style.appendChild(document.createTextNode(constructClass(classname, dict)));
    document.body.appendChild(style);
  };
  var val = window.document.getElementById(id);
  if (val == null) {
    return createStyle();
  } else {
    val.remove();
    return createStyle();
  }
}

function openUrl(url) {
  messageParentWindow(undefined, [[
          "openurl",
          url
        ]]);
}

function getArrofJsonString(arr) {
  return arr.map(function (item) {
              return item;
            });
}

function getPaymentDetails(arr) {
  var finalArr = [];
  arr.map(function (item) {
        var optionalVal = PaymentDetails$OrcaPaymentPage.details.find(function (i) {
              return i.type_ === item;
            });
        if (optionalVal !== undefined) {
          finalArr.push(optionalVal);
          return ;
        }
        
      });
  return finalArr;
}

function getOptionalArr(arr) {
  if (arr !== undefined) {
    return arr;
  } else {
    return [];
  }
}

function checkPriorityList(paymentMethodOrder) {
  if (Core__Option.getOr((
            paymentMethodOrder !== undefined ? paymentMethodOrder : []
          )[0], "") === "card") {
    return true;
  } else {
    return Core__Option.isNone(paymentMethodOrder);
  }
}

function addSize(str, value, unit) {
  var getUnit = function (unit) {
    switch (unit) {
      case "Pixel" :
          return "px";
      case "Rem" :
          return "rem";
      case "Em" :
          return "em";
      
    }
  };
  var unitInString = getUnit(unit);
  if (!str.endsWith(unitInString)) {
    return str;
  }
  var arr = str.split("");
  var val = Core__Option.getOr(Core__Float.fromString(arr.slice(0, arr.length - unitInString.length | 0).join("")), 0.0);
  return (val + value).toString() + unitInString;
}

function toInt(val) {
  return Core__Option.getOr(Core__Int.fromString(undefined, val), 0);
}

function validateRountingNumber(str) {
  if (str.length !== 9) {
    return false;
  }
  var weights = [
    3,
    7,
    1,
    3,
    7,
    1,
    3,
    7,
    1
  ];
  var sum = Core__Array.reduce(str.split("").map(function (item, i) {
            return Math.imul(toInt(item), Core__Option.getOr(weights[i], 3));
          }), 0, (function (acc, val) {
          return acc + val | 0;
        }));
  return sum % 10 === 0;
}

function handlePostMessageEvents(complete, empty, paymentType, loggerState, savedMethodOpt) {
  var savedMethod = savedMethodOpt !== undefined ? savedMethodOpt : false;
  if (complete && paymentType !== "") {
    var value = "Payment Data Filled" + (
      savedMethod ? ": Saved Payment Method" : ": New Payment Method"
    );
    loggerState.setLogInfo(value, undefined, "PAYMENT_DATA_FILLED", undefined, undefined, undefined, undefined, paymentType);
  }
  messageParentWindow(undefined, [
        [
          "elementType",
          "payment"
        ],
        [
          "complete",
          complete
        ],
        [
          "empty",
          empty
        ],
        [
          "value",
          Object.fromEntries([[
                  "type",
                  paymentType
                ]])
        ]
      ]);
}

function onlyDigits(str) {
  return str.replace(/\D/g, "");
}

function getCountryCode(country) {
  return Core__Option.getOr(Country$OrcaPaymentPage.country.find(function (item) {
                  return item.countryName === country;
                }), Country$OrcaPaymentPage.defaultTimeZone);
}

function getStateNames(list, country) {
  var options = Core__Option.getOr(getOptionalArrayFromDict(getDictFromJson(list), getCountryCode(country.value).isoAlpha2), []);
  return Core__Array.reduce(options, [], (function (arr, item) {
                arr.push(Core__Option.getOr(Core__Option.flatMap(getDictFromJson(item)["name"], Core__JSON.Decode.string), ""));
                return arr;
              }));
}

function isAddressComplete(line1, city, postalCode, country, state) {
  if (line1.value !== "" && city.value !== "" && postalCode.value !== "" && country.value !== "") {
    return state.value !== "";
  } else {
    return false;
  }
}

function deepCopyDict(dict) {
  var emptyDict = {};
  Object.entries(dict).map(function (item) {
        emptyDict[item[0]] = item[1];
      });
  return emptyDict;
}

function snakeToTitleCase(str) {
  var words = str.split("_");
  return words.map(function (item) {
                return item.charAt(0).toUpperCase() + item.slice(1);
              }).join(" ");
}

function formatIBAN(iban) {
  var formatted = iban.replace(/[^a-zA-Z0-9]/g, "");
  var countryCode = formatted.substring(0, 2).toUpperCase();
  var codeLastTwo = formatted.substring(2, 4);
  var remaining = formatted.substring(4);
  var matches = remaining.match(/(.{1,4})/g);
  var chunks = (matches == null) ? [] : matches;
  return (countryCode + codeLastTwo + " " + chunks.join(" ")).trim();
}

function formatBSB(bsb) {
  var formatted = bsb.replace(/\D+/g, "");
  var firstPart = formatted.substring(0, 3);
  var secondPart = formatted.substring(3, 6);
  if (formatted.length <= 3) {
    return firstPart;
  } else if (formatted.length > 3 && formatted.length <= 6) {
    return firstPart + "-" + secondPart;
  } else {
    return formatted;
  }
}

function getDictIsSome(dict, key) {
  return Core__Option.isSome(dict[key]);
}

function rgbaTorgb(bgColor) {
  var cleanBgColor = bgColor.trim();
  if (!(cleanBgColor.startsWith("rgba") || cleanBgColor.startsWith("rgb"))) {
    return cleanBgColor;
  }
  var start = cleanBgColor.indexOf("(");
  var end = cleanBgColor.indexOf(")");
  var colorArr = cleanBgColor.substring(start + 1 | 0, end).split(",");
  if (colorArr.length === 3) {
    return cleanBgColor;
  }
  var red = Core__Option.getOr(colorArr[0], "0");
  var green = Core__Option.getOr(colorArr[1], "0");
  var blue = Core__Option.getOr(colorArr[2], "0");
  return "rgba(" + red + ", " + green + ", " + blue + ")";
}

function delay(timeOut) {
  return new Promise((function (resolve, _reject) {
                setTimeout((function () {
                        resolve({});
                      }), timeOut);
              }));
}

function getHeaders(uri, token, headersOpt) {
  var headers = headersOpt !== undefined ? headersOpt : ({});
  var headerObj = Object.fromEntries([
        [
          "Content-Type",
          "application/json"
        ],
        [
          "X-Client-Version",
          Window$OrcaPaymentPage.version
        ],
        [
          "X-Payment-Confirm-Source",
          "sdk"
        ],
        [
          "X-Browser-Name",
          Core__Option.getOr(HyperLogger$OrcaPaymentPage.arrayOfNameAndVersion[0], "Others")
        ],
        [
          "X-Browser-Version",
          Core__Option.getOr(HyperLogger$OrcaPaymentPage.arrayOfNameAndVersion[1], "0")
        ],
        [
          "X-Client-Platform",
          "web"
        ]
      ]);
  if (token !== undefined && uri !== undefined) {
    headerObj["Authorization"] = token;
  }
  Object.entries(headers).forEach(function (entries) {
        headerObj[entries[0]] = entries[1];
      });
  return new Headers(headerObj);
}

function formatException(exc) {
  if (exc.RE_EXN_ID !== Js_exn.$$Error) {
    return exc;
  }
  var obj = exc._1;
  var message = obj.message;
  var name = obj.name;
  var stack = obj.stack;
  var fileName = obj.fileName;
  if (Core__Option.isSome(message) || Core__Option.isSome(name) || Core__Option.isSome(stack) || Core__Option.isSome(fileName)) {
    return Object.fromEntries([
                [
                  "message",
                  Core__Option.getOr(message, "Unknown Error")
                ],
                [
                  "type",
                  Core__Option.getOr(name, "Unknown")
                ],
                [
                  "stack",
                  Core__Option.getOr(stack, "Unknown")
                ],
                [
                  "fileName",
                  Core__Option.getOr(fileName, "Unknown")
                ]
              ]);
  } else {
    return exc;
  }
}

function fetchApi(uri, bodyStrOpt, headersOpt, method) {
  var bodyStr = bodyStrOpt !== undefined ? bodyStrOpt : "";
  var headers = headersOpt !== undefined ? headersOpt : ({});
  var body = method === "GET" ? Promise.resolve(undefined) : Promise.resolve(Caml_option.some(bodyStr));
  return body.then(function (body) {
              return Core__Promise.$$catch(fetch(uri, {
                                method: method,
                                body: body,
                                headers: Caml_option.some(getHeaders(uri, undefined, headers))
                              }), (function (err) {
                              return Promise.reject(err);
                            })).then(function (resp) {
                          return Promise.resolve(resp);
                        });
            });
}

function arrayJsonToCamelCase(arr) {
  return arr.map(function (item) {
              return transformKeys(item, "CamelCase");
            });
}

function getArrayValFromJsonDict(dict, key, arrayKey) {
  return Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(Core__Option.getOr(Core__Option.flatMap(dict[key], Core__JSON.Decode.object), {})[arrayKey], Core__JSON.Decode.array), []), Core__JSON.Decode.string);
}

function isOtherElements(componentType) {
  if (componentType === "card" || componentType === "cardNumber" || componentType === "cardExpiry") {
    return true;
  } else {
    return componentType === "cardCvc";
  }
}

function callbackFuncForExtractingValFromDict(key) {
  return function (x) {
    return x[key];
  };
}

function getClasses(options, key) {
  var classes = getDictFromObj(options, "classes");
  return getString(classes, key, "");
}

function safeParseOpt(st) {
  try {
    return JSON.parse(st);
  }
  catch (exn){
    return ;
  }
}

function safeParse(st) {
  return Core__Option.getOr(safeParseOpt(st), null);
}

function getArrayOfTupleFromDict(dict) {
  return Object.keys(dict).map(function (key) {
              return [
                      key,
                      Core__Option.getOr(dict[key], null)
                    ];
            });
}

function getOptionalJsonFromJson(json, str) {
  return Core__Option.getOr(Core__JSON.Decode.object(json), {})[str];
}

function getStringFromOptionalJson(json, $$default) {
  return Core__Option.getOr(Core__Option.flatMap(json, Core__JSON.Decode.string), $$default);
}

function getBoolFromOptionalJson(json, $$default) {
  return Core__Option.getOr(Core__Option.flatMap(json, Core__JSON.Decode.bool), $$default);
}

function getBoolFromJson(json, $$default) {
  return Core__Option.getOr(Core__JSON.Decode.bool(json), $$default);
}

function getOptionalJson(json, str) {
  return Core__Option.getOr(Core__JSON.Decode.object(Core__Option.getOr(Core__Option.flatMap(Core__JSON.Decode.object(json), (function (x) {
                              return x["data"];
                            })), {})), {})[str];
}

function setNested(_dict, _keys, value) {
  while(true) {
    var keys = _keys;
    var dict = _dict;
    var firstKey = keys[0];
    if (firstKey === undefined) {
      return ;
    }
    if (keys.length === 1) {
      dict[firstKey] = value;
      return ;
    }
    var json = dict[firstKey];
    var subDict;
    if (json !== undefined) {
      var obj = Core__JSON.Decode.object(json);
      subDict = obj !== undefined ? obj : dict;
    } else {
      var subDict$1 = {};
      dict[firstKey] = subDict$1;
      subDict = subDict$1;
    }
    var remainingKeys = keys.slice(1);
    _keys = remainingKeys;
    _dict = subDict;
    continue ;
  };
}

function unflattenObject(obj) {
  var newDict = {};
  var dict = Core__JSON.Decode.object(obj);
  if (dict !== undefined) {
    Object.entries(dict).forEach(function (entry) {
          setNested(newDict, entry[0].split("."), entry[1]);
        });
  }
  return newDict;
}

function mergeTwoFlattenedJsonDicts(dict1, dict2) {
  return unflattenObject(Object.fromEntries(Belt_Array.concatMany([
                      Object.entries(dict1),
                      Object.entries(dict2)
                    ])));
}

function flattenObject(obj, addIndicatorForObject) {
  var newDict = {};
  var obj$1 = Core__JSON.Decode.object(obj);
  if (obj$1 !== undefined) {
    Object.entries(obj$1).forEach(function (entry) {
          var value = entry[1];
          var key = entry[0];
          if (value == null) {
            newDict[key] = value;
            return ;
          }
          var _valueObj = Core__JSON.Decode.object(value);
          if (_valueObj !== undefined) {
            if (addIndicatorForObject) {
              newDict[key] = {};
            }
            var flattenedSubObj = flattenObject(value, addIndicatorForObject);
            Object.entries(flattenedSubObj).forEach(function (subEntry) {
                  newDict[key + "." + subEntry[0]] = subEntry[1];
                });
            return ;
          }
          newDict[key] = value;
        });
  }
  return newDict;
}

function flattenObjectWithStringifiedJson(obj, addIndicatorForObject, keepParent) {
  var newDict = {};
  var obj$1 = Core__JSON.Decode.object(obj);
  if (obj$1 !== undefined) {
    Object.entries(obj$1).forEach(function (entry) {
          var value = entry[1];
          var key = entry[0];
          if (value == null) {
            newDict[key] = value;
            return ;
          }
          var _valueObj = Core__JSON.Decode.object(safeParse(getStringFromJson(value, "")));
          if (_valueObj !== undefined) {
            if (addIndicatorForObject) {
              newDict[key] = {};
            }
            var flattenedSubObj = flattenObjectWithStringifiedJson(safeParse(getStringFromJson(value, "")), addIndicatorForObject, keepParent);
            Object.entries(flattenedSubObj).forEach(function (subEntry) {
                  var subKey = subEntry[0];
                  var keyN = keepParent ? key + "." + subKey : subKey;
                  newDict[keyN] = subEntry[1];
                });
            return ;
          }
          newDict[key] = value;
        });
  }
  return newDict;
}

function flatten(obj, addIndicatorForObject) {
  var newDict = {};
  var obj$1 = Core__JSON.Classify.classify(obj);
  if (typeof obj$1 === "object" && obj$1.TAG === "Object") {
    Object.entries(obj$1._0).forEach(function (entry) {
          var value = entry[1];
          var key = entry[0];
          if (value == null) {
            newDict[key] = value;
            return ;
          }
          var _valueObjDict = Core__JSON.Classify.classify(value);
          if (typeof _valueObjDict !== "object") {
            newDict[key] = value;
            return ;
          }
          switch (_valueObjDict.TAG) {
            case "Object" :
                if (addIndicatorForObject) {
                  newDict[key] = {};
                }
                var flattenedSubObj = flatten(value, addIndicatorForObject);
                Object.entries(flattenedSubObj).forEach(function (subEntry) {
                      newDict[key + "." + subEntry[0]] = subEntry[1];
                    });
                return ;
            case "Array" :
                var stringArray = [];
                var arrayArray = [];
                _valueObjDict._0.forEach(function (item, index) {
                      var _str = Core__JSON.Classify.classify(item);
                      if (typeof _str !== "object") {
                        arrayArray.push(item);
                        return ;
                      }
                      switch (_str.TAG) {
                        case "String" :
                            stringArray.push(item);
                            return ;
                        case "Object" :
                            var flattenedSubObj = flatten(item, addIndicatorForObject);
                            Object.entries(flattenedSubObj).forEach(function (subEntry) {
                                  newDict[key + "[" + index.toString() + "]." + subEntry[0]] = subEntry[1];
                                });
                            return ;
                        default:
                          arrayArray.push(item);
                          return ;
                      }
                    });
                if (stringArray.length > 0) {
                  newDict[key] = stringArray;
                }
                if (arrayArray.length > 0) {
                  newDict[key] = arrayArray;
                  return ;
                } else {
                  return ;
                }
            default:
              newDict[key] = value;
              return ;
          }
        });
  }
  return newDict;
}

function eventHandlerFunc(condition, eventHandler, evType, activity) {
  var changeHandler = function (ev) {
    if (!condition(ev)) {
      return ;
    }
    switch (evType) {
      case "Escape" :
      case "None" :
          return ;
      default:
        if (eventHandler !== undefined) {
          return eventHandler(ev.data);
        } else {
          return ;
        }
    }
  };
  EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", changeHandler, activity);
}

function makeIframe(element, url) {
  return new Promise((function (resolve, param) {
                var iframe = document.createElement("iframe");
                iframe.id = "orca-fullscreen";
                iframe.src = url;
                iframe.name = "fullscreen";
                iframe.style = "position: fixed; inset: 0; width: 100vw; height: 100vh; border: 0; z-index: 422222133323; ";
                iframe.onload = (function () {
                    resolve({});
                  });
                element.appendChild(iframe);
              }));
}

function makeForm(element, url, id) {
  var form = document.createElement("form");
  form.id = id;
  form.name = id;
  form.action = url;
  form.method = "POST";
  form.enctype = "application/x-www-form-urlencoded;charset=UTF-8";
  form.style = "display: hidden;";
  element.appendChild(form);
  return form;
}

function getThemePromise(dict) {
  var theme = getString(getDictFromJson(getJsonObjectFromDict(dict, "appearance")), "theme", "default");
  switch (theme) {
    case "brutal" :
        return Caml_option.some(import("../BrutalTheme.bs.js"));
    case "charcoal" :
        return Caml_option.some(import("../CharcoalTheme.bs.js"));
    case "midnight" :
        return Caml_option.some(import("../MidnightTheme.bs.js"));
    case "none" :
        return Caml_option.some(import("../NoTheme.bs.js"));
    case "soft" :
        return Caml_option.some(import("../SoftTheme.bs.js"));
    default:
      return ;
  }
}

function makeOneClickHandlerPromise(sdkHandleIsThere) {
  return new Promise((function (resolve, param) {
                if (!sdkHandleIsThere) {
                  return resolve(true);
                }
                var handleMessage = function ($$event) {
                  var json;
                  try {
                    json = safeParse($$event.data);
                  }
                  catch (exn){
                    json = null;
                  }
                  var dict = getDictFromJson(json);
                  if (Core__Option.isSome(dict["walletClickEvent"])) {
                    return resolve(Core__Option.getOr(dict["walletClickEvent"], true));
                  }
                  
                };
                window.addEventListener("message", handleMessage);
                handleOnConfirmPostMessage("*", true);
              }));
}

function generateRandomString(length) {
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var result = {
    contents: ""
  };
  var charactersLength = characters.length;
  Core__Int.range(0, length).forEach(function (param) {
        var charIndex = Caml_int32.mod_(Math.random() * 100.0 | 0, charactersLength);
        result.contents = result.contents + characters.charAt(charIndex);
      });
  return result.contents;
}

function getWalletPaymentMethod(wallets, paymentType) {
  switch (paymentType) {
    case "GooglePayElement" :
        return wallets.filter(function (item) {
                    return item === "google_pay";
                  });
    case "PayPalElement" :
        return wallets.filter(function (item) {
                    return item === "paypal";
                  });
    case "ApplePayElement" :
        return wallets.filter(function (item) {
                    return item === "apple_pay";
                  });
    case "SamsungPayElement" :
        return wallets.filter(function (item) {
                    return item === "samsung_pay";
                  });
    case "KlarnaElement" :
        return wallets.filter(function (item) {
                    return item === "klarna";
                  });
    case "PazeElement" :
        return wallets.filter(function (item) {
                    return item === "paze";
                  });
    default:
      return wallets;
  }
}

var expressCheckoutComponents = [
  "googlePay",
  "payPal",
  "applePay",
  "klarna",
  "paze",
  "samsungPay",
  "expressCheckout"
];

var spmComponents = ["paymentMethodCollect"].concat(expressCheckoutComponents);

var componentsForPaymentElementCreate = [
    "payment",
    "paymentMethodCollect",
    "paymentMethodsManagement"
  ].concat(expressCheckoutComponents);

function getIsExpressCheckoutComponent(componentType) {
  return expressCheckoutComponents.includes(componentType);
}

function getIsComponentTypeForPaymentElementCreate(componentType) {
  return componentsForPaymentElementCreate.includes(componentType);
}

var walletElementPaymentType = [
  "GooglePayElement",
  "PayPalElement",
  "ApplePayElement",
  "SamsungPayElement",
  "KlarnaElement",
  "PazeElement",
  "ExpressCheckoutElement"
];

function getIsWalletElementPaymentType(paymentType) {
  return walletElementPaymentType.includes(paymentType);
}

function getUniqueArray(arr) {
  return Object.keys(Object.fromEntries(arr.map(function (item) {
                      return [
                              item,
                              ""
                            ];
                    })));
}

function getStateNameFromStateCodeAndCountry(list, stateCode, country) {
  var options = getOptionalArrayFromDict(getDictFromJson(list), country);
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(options, (function (__x) {
                        return __x.find(function (item) {
                                    return getString(getDictFromJson(item), "code", "") === stateCode;
                                  });
                      })), (function (stateObj) {
                    return getOptionString(getDictFromJson(stateObj), "name");
                  })), stateCode);
}

function removeHyphen(str) {
  return str.replace(/-/g, "");
}

function compareLogic(a, b) {
  if (Caml_obj.equal(a, b)) {
    return 0;
  } else if (Caml_obj.greaterthan(a, b)) {
    return -1;
  } else {
    return 1;
  }
}

var currencyNetworksDict = Object.fromEntries([
      [
        "BTC",
        [
          "bitcoin",
          "bnb_smart_chain"
        ]
      ],
      [
        "LTC",
        [
          "litecoin",
          "bnb_smart_chain"
        ]
      ],
      [
        "ETH",
        [
          "ethereum",
          "bnb_smart_chain"
        ]
      ],
      [
        "XRP",
        [
          "ripple",
          "bnb_smart_chain"
        ]
      ],
      [
        "XLM",
        [
          "stellar",
          "bnb_smart_chain"
        ]
      ],
      [
        "BCH",
        [
          "bitcoin_cash",
          "bnb_smart_chain"
        ]
      ],
      [
        "ADA",
        [
          "cardano",
          "bnb_smart_chain"
        ]
      ],
      [
        "SOL",
        [
          "solana",
          "bnb_smart_chain"
        ]
      ],
      [
        "SHIB",
        [
          "ethereum",
          "bnb_smart_chain"
        ]
      ],
      [
        "TRX",
        [
          "tron",
          "bnb_smart_chain"
        ]
      ],
      [
        "DOGE",
        [
          "dogecoin",
          "bnb_smart_chain"
        ]
      ],
      [
        "BNB",
        ["bnb_smart_chain"]
      ],
      [
        "USDT",
        [
          "ethereum",
          "tron",
          "bnb_smart_chain"
        ]
      ],
      [
        "USDC",
        [
          "ethereum",
          "tron",
          "bnb_smart_chain"
        ]
      ],
      [
        "DAI",
        [
          "ethereum",
          "bnb_smart_chain"
        ]
      ]
    ]);

function toSpacedUpperCase(str, delimiter) {
  return str.toUpperCase().split(delimiter).join(" ");
}

function handleFailureResponse(message, errorType) {
  return Object.fromEntries([[
                "error",
                Object.fromEntries([
                      [
                        "type",
                        errorType
                      ],
                      [
                        "message",
                        message
                      ]
                    ])
              ]]);
}

function getPaymentId(clientSecret) {
  return Core__Option.getOr(clientSecret.split("_secret_")[0], "");
}

function checkIs18OrAbove(dateOfBirth) {
  var currentDate = new Date();
  var year = currentDate.getFullYear() - 18 | 0;
  var month = currentDate.getMonth();
  var date = currentDate.getDate();
  var compareDate = new Date(year, month, date);
  return Caml_obj.lessequal(dateOfBirth, compareDate);
}

function getFirstAndLastNameFromFullName(fullName) {
  var nameStrings = fullName.split(" ");
  var firstName = Core__Option.getOr(Core__Option.flatMap(nameStrings[0], (function (x) {
              return x;
            })), null);
  var lastNameStr = nameStrings.slice(1).join(" ").trim();
  var lastNameJson = lastNameStr === "" ? null : lastNameStr;
  return [
          firstName,
          lastNameJson
        ];
}

function isKeyPresentInDict(dict, key) {
  return Core__Option.isSome(dict[key]);
}

function checkIsTestCardWildcard(val) {
  return ["1111222233334444"].includes(val);
}

function minorUnitToString(val) {
  return (val / 100).toString();
}

function mergeAndFlattenToTuples(body, requiredFieldsBody) {
  return getArrayOfTupleFromDict(mergeTwoFlattenedJsonDicts(flattenObject(Object.fromEntries(body), true), requiredFieldsBody));
}

function handleIframePostMessageForWallets(msg, componentName, mountedIframeRef) {
  var isMessageSent = {
    contents: false
  };
  var iframes = document.querySelectorAll("iframe");
  iframes.forEach(function (iframe) {
        var iframeSrc = Core__Option.getOr(iframe.getAttribute("src"), "");
        if (iframeSrc.includes("componentName=" + componentName)) {
          Window$OrcaPaymentPage.iframePostMessage(iframe, msg);
          isMessageSent.contents = true;
          return ;
        }
        
      });
  if (!isMessageSent.contents) {
    return Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
  }
  
}

function isDigitLimitExceeded(val, digit) {
  var matches = val.match(/\d/g);
  if (matches == null) {
    return false;
  } else {
    return matches.length > digit;
  }
}

var nbsp = "\u00A0";

var brandIconSize = 28;

export {
  phoneNumberJson ,
  getJsonFromArrayOfJson ,
  messageWindow ,
  messageTopWindow ,
  messageParentWindow ,
  messageCurrentWindow ,
  handleOnFocusPostMessage ,
  handleOnCompleteDoThisMessage ,
  handleOnBlurPostMessage ,
  handleOnClickPostMessage ,
  handleOnConfirmPostMessage ,
  getOptionString ,
  getString ,
  getStringFromJson ,
  getInt ,
  getFloatFromString ,
  getFloatFromJson ,
  getFloat ,
  getJsonBoolValue ,
  getJsonStringFromDict ,
  getJsonArrayFromDict ,
  getJsonFromDict ,
  getJsonObjFromDict ,
  getDecodedStringFromJson ,
  getDecodedBoolFromJson ,
  getRequiredString ,
  getWarningString ,
  getDictFromObj ,
  getJsonObjectFromDict ,
  getOptionBool ,
  getDictFromJson ,
  getDictFromDict ,
  getBool ,
  getBoolWithWarning ,
  getNumberWithWarning ,
  getOptionalArrayFromDict ,
  getArray ,
  getStrArray ,
  getOptionalStrArray ,
  getBoolValue ,
  toKebabCase ,
  handleMessage ,
  useSubmitPaymentData ,
  useWindowSize ,
  mergeJsons ,
  postFailedSubmitResponse ,
  postSubmitResponse ,
  getFailedSubmitResponse ,
  toCamelCase ,
  toSnakeCase ,
  transformKeys ,
  getClientCountry ,
  removeDuplicate ,
  isEmailValid ,
  isVpaIdValid ,
  checkEmailValid ,
  validatePhoneNumber ,
  sortBasedOnPriority ,
  isAllValid ,
  getCountryPostal ,
  getCountryNames ,
  getBankNames ,
  getBankKeys ,
  constructClass ,
  generateStyleSheet ,
  openUrl ,
  getArrofJsonString ,
  getPaymentDetails ,
  getOptionalArr ,
  checkPriorityList ,
  addSize ,
  toInt ,
  validateRountingNumber ,
  handlePostMessageEvents ,
  onlyDigits ,
  getCountryCode ,
  getStateNames ,
  isAddressComplete ,
  deepCopyDict ,
  snakeToTitleCase ,
  formatIBAN ,
  formatBSB ,
  getDictIsSome ,
  rgbaTorgb ,
  delay ,
  getHeaders ,
  formatException ,
  fetchApi ,
  arrayJsonToCamelCase ,
  getArrayValFromJsonDict ,
  isOtherElements ,
  nbsp ,
  callbackFuncForExtractingValFromDict ,
  brandIconSize ,
  getClasses ,
  safeParseOpt ,
  safeParse ,
  getArrayOfTupleFromDict ,
  getOptionalJsonFromJson ,
  getStringFromOptionalJson ,
  getBoolFromOptionalJson ,
  getBoolFromJson ,
  getOptionalJson ,
  setNested ,
  unflattenObject ,
  mergeTwoFlattenedJsonDicts ,
  flattenObject ,
  flattenObjectWithStringifiedJson ,
  flatten ,
  eventHandlerFunc ,
  makeIframe ,
  makeForm ,
  getThemePromise ,
  makeOneClickHandlerPromise ,
  generateRandomString ,
  getWalletPaymentMethod ,
  expressCheckoutComponents ,
  spmComponents ,
  componentsForPaymentElementCreate ,
  getIsExpressCheckoutComponent ,
  getIsComponentTypeForPaymentElementCreate ,
  walletElementPaymentType ,
  getIsWalletElementPaymentType ,
  getUniqueArray ,
  getStateNameFromStateCodeAndCountry ,
  removeHyphen ,
  compareLogic ,
  currencyNetworksDict ,
  toSpacedUpperCase ,
  handleFailureResponse ,
  getPaymentId ,
  checkIs18OrAbove ,
  getFirstAndLastNameFromFullName ,
  isKeyPresentInDict ,
  checkIsTestCardWildcard ,
  minorUnitToString ,
  mergeAndFlattenToTuples ,
  handleIframePostMessageForWallets ,
  isDigitLimitExceeded ,
}
/* phoneNumberJson Not a pure module */
