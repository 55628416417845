// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as React$1 from "@sentry/react";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var ErrorBoundary = {};

function initiateSentry(dsn) {
  try {
    React$1.init({
          dsn: dsn,
          integrations: [
            new React$1.BrowserTracing({
                  routingInstrumentation: React$1.reactRouterV6Instrumentation(function (prim) {
                        React.useEffect(prim);
                      })
                }),
            new React$1.Replay()
          ],
          tracesSampleRate: 0.1,
          tracePropagationTargets: [
            "localhost",
            "https://dev.hyperswitch.io",
            "https://beta.hyperswitch.io",
            "https://checkout.hyperswitch.io"
          ],
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
          beforeSend: (function ($$event, hint) {
              if (hint.originalException.tag === "HyperTag") {
                return Caml_option.some($$event);
              }
              
            })
        });
    return ;
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    console.log(err);
    return ;
  }
}

function initiateSentryJs(dsn) {
  try {
    Sentry.init({
          dsn: dsn,
          integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay()
          ],
          tracesSampleRate: 1.0,
          tracePropagationTargets: ["localhost"],
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
          beforeSend: (function ($$event, hint) {
              if (hint.originalException.tag === "HyperTag") {
                return Caml_option.some($$event);
              }
              
            })
        });
    return ;
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    console.log(err);
    return ;
  }
}

function captureException(err) {
  var _val = window.Sentry;
  if (_val == null) {
    return ;
  }
  if (err === undefined) {
    return ;
  }
  var e = Caml_option.valFromOption(err);
  e.tag = "HyperTag";
  Sentry.captureException(e);
}

function sentryLogger(callback) {
  try {
    return callback();
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    return captureException(err);
  }
}

export {
  ErrorBoundary ,
  initiateSentry ,
  initiateSentryJs ,
  captureException ,
  sentryLogger ,
}
/* react Not a pure module */
