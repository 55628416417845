// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Country$OrcaPaymentPage from "../Country.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as ConfirmType$OrcaPaymentPage from "./ConfirmType.bs.js";

var defaultCardDetails = {
  scheme: undefined,
  last4Digits: "",
  expiryMonth: "",
  expiryYear: "",
  cardToken: "",
  cardHolderName: undefined,
  nickname: ""
};

var defaultCustomerMethods_bank = {
  mask: ""
};

var defaultCustomerMethods = {
  paymentToken: "",
  customerId: "",
  paymentMethod: "",
  paymentMethodId: "",
  paymentMethodIssuer: undefined,
  card: defaultCardDetails,
  paymentMethodType: undefined,
  defaultPaymentMethodSet: false,
  requiresCvv: true,
  lastUsedAt: "",
  bank: defaultCustomerMethods_bank,
  recurringEnabled: false
};

var defaultLayout = {
  defaultCollapsed: false,
  radios: false,
  spacedAccordionItems: false,
  maxAccordionItems: 4,
  type: "Tabs"
};

var defaultAddress = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  country: "",
  postal_code: ""
};

var defaultBillingDetails = {
  name: "",
  email: "",
  phone: "",
  address: defaultAddress
};

var defaultBusiness = {
  name: ""
};

var defaultDefaultValues = {
  billingDetails: defaultBillingDetails
};

var defaultshowAddress = {
  line1: "Auto",
  line2: "Auto",
  city: "Auto",
  state: "Auto",
  country: "Auto",
  postal_code: "Auto"
};

var defaultNeverShowAddress = {
  line1: "Never",
  line2: "Never",
  city: "Never",
  state: "Never",
  country: "Never",
  postal_code: "Never"
};

var defaultBilling_address = {
  TAG: "JSONObject",
  _0: defaultshowAddress
};

var defaultBilling = {
  name: "Auto",
  email: "Auto",
  phone: "Auto",
  address: defaultBilling_address
};

var defaultNeverBilling_address = {
  TAG: "JSONObject",
  _0: defaultNeverShowAddress
};

var defaultNeverBilling = {
  name: "Never",
  email: "Never",
  phone: "Never",
  address: defaultNeverBilling_address
};

var defaultTerms = {
  auBecsDebit: "Auto",
  bancontact: "Auto",
  card: "Auto",
  ideal: "Auto",
  sepaDebit: "Auto",
  sofort: "Auto",
  usBankAccount: "Auto"
};

var defaultFields = {
  billingDetails: {
    TAG: "JSONObject",
    _0: defaultBilling
  }
};

var defaultStyle = {
  type_: [
    {
      TAG: "ApplePay",
      _0: "Default"
    },
    {
      TAG: "GooglePay",
      _0: "Default"
    },
    {
      TAG: "Paypal",
      _0: "Paypal"
    },
    {
      TAG: "SamsungPay",
      _0: "Buy"
    }
  ],
  theme: "Light",
  height: [
    {
      TAG: "ApplePay",
      _0: 48
    },
    {
      TAG: "GooglePay",
      _0: 48
    },
    {
      TAG: "Paypal",
      _0: 48
    },
    {
      TAG: "Klarna",
      _0: 48
    },
    {
      TAG: "SamsungPay",
      _0: 48
    }
  ],
  buttonRadius: 2
};

var defaultWallets = {
  walletReturnUrl: "",
  applePay: "Auto",
  googlePay: "Auto",
  payPal: "Auto",
  klarna: "Auto",
  paze: "Auto",
  samsungPay: "Auto",
  style: defaultStyle
};

var defaultBillingAddress = {
  isUseBillingAddress: false,
  usePrefilledValues: "Auto"
};

var defaultSdkHandleConfirmPayment = {
  handleConfirm: false,
  confirmParams: ConfirmType$OrcaPaymentPage.defaultConfirm
};

var defaultOptions_layout = {
  TAG: "ObjectLayout",
  _0: defaultLayout
};

var defaultOptions_customMethodNames = [];

var defaultOptions = {
  defaultValues: defaultDefaultValues,
  layout: defaultOptions_layout,
  business: defaultBusiness,
  customerPaymentMethods: "LoadingSavedCards",
  savedPaymentMethods: "LoadingSavedCards",
  displaySavedPaymentMethodsCheckbox: true,
  displaySavedPaymentMethods: true,
  fields: defaultFields,
  readOnly: false,
  terms: defaultTerms,
  wallets: defaultWallets,
  customMethodNames: defaultOptions_customMethodNames,
  branding: "Auto",
  payButtonStyle: defaultStyle,
  showCardFormByDefault: true,
  billingAddress: defaultBillingAddress,
  sdkHandleConfirmPayment: defaultSdkHandleConfirmPayment,
  hideExpiredPaymentMethods: false,
  displayDefaultSavedPaymentIcon: true,
  hideCardNicknameField: false,
  customMessageForCardTerms: ""
};

function getLayout(str, logger) {
  switch (str) {
    case "accordion" :
        return "Accordion";
    case "tabs" :
        return "Tabs";
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
            "tabs",
            "accordion"
          ], "options.layout", logger);
      return "Tabs";
  }
}

function getAddress(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    var countryNames = [];
                    Country$OrcaPaymentPage.country.map(function (item) {
                          countryNames.push(item.countryName);
                        });
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "line1",
                          "line2",
                          "city",
                          "state",
                          "country",
                          "postal_code"
                        ], json, "options.defaultValues.billingDetails.address", logger);
                    var country = Utils$OrcaPaymentPage.getWarningString(json, "country", "", logger);
                    if (country !== "") {
                      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(country, countryNames, "options.defaultValues.billingDetails.address.country", logger);
                    }
                    return {
                            line1: Utils$OrcaPaymentPage.getWarningString(json, "line1", "", logger),
                            line2: Utils$OrcaPaymentPage.getWarningString(json, "line2", "", logger),
                            city: Utils$OrcaPaymentPage.getWarningString(json, "city", "", logger),
                            state: Utils$OrcaPaymentPage.getWarningString(json, "state", "", logger),
                            country: country,
                            postal_code: Utils$OrcaPaymentPage.getWarningString(json, "postal_code", "", logger)
                          };
                  })), defaultAddress);
}

function getBillingDetails(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "name",
                          "email",
                          "phone",
                          "address"
                        ], json, "options.defaultValues.billingDetails", logger);
                    return {
                            name: Utils$OrcaPaymentPage.getWarningString(json, "name", "", logger),
                            email: Utils$OrcaPaymentPage.getWarningString(json, "email", "", logger),
                            phone: Utils$OrcaPaymentPage.getWarningString(json, "phone", "", logger),
                            address: getAddress(json, "address", logger)
                          };
                  })), defaultBillingDetails);
}

function getDefaultValues(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning(["billingDetails"], json, "options.defaultValues", logger);
                    return {
                            billingDetails: getBillingDetails(json, "billingDetails", logger)
                          };
                  })), defaultDefaultValues);
}

function getBusiness(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning(["name"], json, "options.business", logger);
                    return {
                            name: Utils$OrcaPaymentPage.getWarningString(json, "name", "", logger)
                          };
                  })), defaultBusiness);
}

function getShowType(str, key, logger) {
  switch (str) {
    case "auto" :
        return "Auto";
    case "never" :
        return "Never";
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
            "auto",
            "never"
          ], key, logger);
      return "Auto";
  }
}

function getApplePayType(str) {
  switch (str) {
    case "add-money" :
    case "addmoney" :
        return {
                TAG: "ApplePay",
                _0: "Addmoney"
              };
    case "buy" :
    case "buynow" :
        return {
                TAG: "ApplePay",
                _0: "Buy"
              };
    case "check-out" :
    case "checkout" :
        return {
                TAG: "ApplePay",
                _0: "Checkout"
              };
    case "contribute" :
        return {
                TAG: "ApplePay",
                _0: "Contribute"
              };
    case "donate" :
        return {
                TAG: "ApplePay",
                _0: "Donate"
              };
    case "order" :
        return {
                TAG: "ApplePay",
                _0: "Order"
              };
    case "reload" :
        return {
                TAG: "ApplePay",
                _0: "Reload"
              };
    case "rent" :
        return {
                TAG: "ApplePay",
                _0: "Rent"
              };
    case "subscribe" :
        return {
                TAG: "ApplePay",
                _0: "Subscribe"
              };
    case "support" :
        return {
                TAG: "ApplePay",
                _0: "Support"
              };
    case "tip" :
        return {
                TAG: "ApplePay",
                _0: "Tip"
              };
    case "top-up" :
    case "topup" :
        return {
                TAG: "ApplePay",
                _0: "Topup"
              };
    default:
      return {
              TAG: "ApplePay",
              _0: "Default"
            };
  }
}

function getGooglePayType(str) {
  switch (str) {
    case "book" :
        return {
                TAG: "GooglePay",
                _0: "Book"
              };
    case "buy" :
    case "buynow" :
        return {
                TAG: "GooglePay",
                _0: "Buy"
              };
    case "check-out" :
    case "checkout" :
        return {
                TAG: "GooglePay",
                _0: "Checkout"
              };
    case "donate" :
        return {
                TAG: "GooglePay",
                _0: "Donate"
              };
    case "order" :
        return {
                TAG: "GooglePay",
                _0: "Order"
              };
    case "pay" :
        return {
                TAG: "GooglePay",
                _0: "Pay"
              };
    case "subscribe" :
        return {
                TAG: "GooglePay",
                _0: "Subscribe"
              };
    default:
      return {
              TAG: "GooglePay",
              _0: "Default"
            };
  }
}

function getSamsungPayType(str) {
  return {
          TAG: "SamsungPay",
          _0: "Buy"
        };
}

function getPayPalType(str) {
  switch (str) {
    case "buy" :
    case "buynow" :
        return {
                TAG: "Paypal",
                _0: "Buynow"
              };
    case "check-out" :
    case "checkout" :
        return {
                TAG: "Paypal",
                _0: "Checkout"
              };
    case "installment" :
        return {
                TAG: "Paypal",
                _0: "Installment"
              };
    case "pay" :
        return {
                TAG: "Paypal",
                _0: "Pay"
              };
    default:
      return {
              TAG: "Paypal",
              _0: "Paypal"
            };
  }
}

function getTypeArray(str, logger) {
  var goodVals = [
    "checkout",
    "pay",
    "buy",
    "installment",
    "pay",
    "default",
    "book",
    "donate",
    "order",
    "addmoney",
    "topup",
    "rent",
    "subscribe",
    "reload",
    "support",
    "tip",
    "contribute"
  ];
  if (!goodVals.includes(str)) {
    ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, goodVals, "options.wallets.style.type", logger);
  }
  return [
          getApplePayType(str),
          getGooglePayType(str),
          getPayPalType(str),
          {
            TAG: "SamsungPay",
            _0: "Buy"
          }
        ];
}

function getShowDetails(billingDetails, logger) {
  if (billingDetails.TAG === "JSONString") {
    if (getShowType(billingDetails._0, "fields.billingDetails", logger) === "Never") {
      return defaultNeverBilling;
    } else {
      return defaultBilling;
    }
  } else {
    return billingDetails._0;
  }
}

function getShowAddressDetails(billingDetails, logger) {
  if (billingDetails.TAG === "JSONString") {
    if (getShowType(billingDetails._0, "fields.billingDetails", logger) === "Never") {
      return defaultNeverShowAddress;
    } else {
      return defaultshowAddress;
    }
  }
  var str = billingDetails._0.address;
  if (str.TAG === "JSONString") {
    if (getShowType(str._0, "fields.billingDetails.address", logger) === "Never") {
      return defaultNeverShowAddress;
    } else {
      return defaultshowAddress;
    }
  } else {
    return str._0;
  }
}

function getShowTerms(str, key, logger) {
  switch (str) {
    case "always" :
        return "Always";
    case "auto" :
        return "Auto";
    case "never" :
        return "Never";
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
            "auto",
            "always",
            "never"
          ], key, logger);
      return "Auto";
  }
}

function getShowAddress(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            line1: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "line1", "auto", logger), "options.fields.address.line1", logger),
                            line2: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "line2", "auto", logger), "options.fields.address.line2", logger),
                            city: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "city", "auto", logger), "options.fields.address.city", logger),
                            state: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "state", "auto", logger), "options.fields.address.state", logger),
                            country: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "country", "auto", logger), "options.fields.address.country", logger),
                            postal_code: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "postal_code", "auto", logger), "options.fields.name.postal_code", logger)
                          };
                  })), defaultshowAddress);
}

function getDeatils(val, logger) {
  var str = Core__JSON.Classify.classify(val);
  if (typeof str !== "object") {
    return {
            TAG: "JSONString",
            _0: ""
          };
  }
  switch (str.TAG) {
    case "String" :
        return {
                TAG: "JSONString",
                _0: str._0
              };
    case "Object" :
        var json = str._0;
        return {
                TAG: "JSONObject",
                _0: {
                  name: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "name", "auto", logger), "options.fields.name", logger),
                  email: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "email", "auto", logger), "options.fields.email", logger),
                  phone: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "phone", "auto", logger), "options.fields.phone", logger),
                  address: {
                    TAG: "JSONObject",
                    _0: getShowAddress(json, "address", logger)
                  }
                }
              };
    default:
      return {
              TAG: "JSONString",
              _0: ""
            };
  }
}

function getBilling(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(dict[str], (function (json) {
                    return getDeatils(json, logger);
                  })), defaultFields.billingDetails);
}

function getFields(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            billingDetails: getBilling(json, "billingDetails", logger)
                          };
                  })), defaultFields);
}

function getLayoutValues(val, logger) {
  var str = Core__JSON.Classify.classify(val);
  if (typeof str !== "object") {
    return {
            TAG: "StringLayout",
            _0: "Tabs"
          };
  }
  switch (str.TAG) {
    case "String" :
        return {
                TAG: "StringLayout",
                _0: getLayout(str._0, logger)
              };
    case "Object" :
        var json = str._0;
        var layoutType = Utils$OrcaPaymentPage.getWarningString(json, "type", "tabs", logger);
        return {
                TAG: "ObjectLayout",
                _0: (ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                        "defaultCollapsed",
                        "radios",
                        "spacedAccordionItems",
                        "type",
                        "maxAccordionItems"
                      ], json, "options.layout", logger), {
                    defaultCollapsed: Utils$OrcaPaymentPage.getBoolWithWarning(json, "defaultCollapsed", false, logger),
                    radios: Utils$OrcaPaymentPage.getBoolWithWarning(json, "radios", false, logger),
                    spacedAccordionItems: Utils$OrcaPaymentPage.getBoolWithWarning(json, "spacedAccordionItems", false, logger),
                    maxAccordionItems: Utils$OrcaPaymentPage.getNumberWithWarning(json, "maxAccordionItems", logger, 4),
                    type: getLayout(layoutType, logger)
                  })
              };
    default:
      return {
              TAG: "StringLayout",
              _0: "Tabs"
            };
  }
}

function getTerms(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "auBecsDebit",
                          "bancontact",
                          "card",
                          "ideal",
                          "sepaDebit",
                          "sofort",
                          "usBankAccount"
                        ], json, "options.terms", logger);
                    return {
                            auBecsDebit: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "auBecsDebit", "auto", logger), "options.terms.auBecsDebit", logger),
                            bancontact: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "bancontact", "auto", logger), "options.terms.bancontact", logger),
                            card: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "card", "auto", logger), "options.terms.card", logger),
                            ideal: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "ideal", "auto", logger), "options.terms.ideal", logger),
                            sepaDebit: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "sepaDebit", "auto", logger), "options.terms.sepaDebit", logger),
                            sofort: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "sofort", "auto", logger), "options.terms.sofort", logger),
                            usBankAccount: getShowTerms(Utils$OrcaPaymentPage.getWarningString(json, "usBankAccount", "auto", logger), "options.terms.usBankAccount", logger)
                          };
                  })), defaultTerms);
}

function getApplePayHeight(val, logger) {
  if (val >= 45) {
    return {
            TAG: "ApplePay",
            _0: val
          };
  } else {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[h>=45] - ApplePay. Value set to min", logger);
    return {
            TAG: "ApplePay",
            _0: 48
          };
  }
}

function getGooglePayHeight(val, logger) {
  if (val >= 45) {
    return {
            TAG: "GooglePay",
            _0: val
          };
  } else {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[h>=45] - GooglePay. Value set to min", logger);
    return {
            TAG: "GooglePay",
            _0: 48
          };
  }
}

function getSamsungPayHeight(val, logger) {
  if (val >= 45) {
    return {
            TAG: "SamsungPay",
            _0: val
          };
  } else {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[h>=45] - SamsungPay. Value set to min", logger);
    return {
            TAG: "SamsungPay",
            _0: 48
          };
  }
}

function getPaypalHeight(val, logger) {
  if (val < 25) {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[25-55] - Paypal. Value set to min", logger);
    return {
            TAG: "Paypal",
            _0: 25
          };
  } else if (val > 55) {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[25-55] - Paypal. Value set to max", logger);
    return {
            TAG: "Paypal",
            _0: 55
          };
  } else {
    return {
            TAG: "Paypal",
            _0: val
          };
  }
}

function getKlarnaHeight(val, logger) {
  if (val < 40) {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[40-60] - Klarna. Value set to min", logger);
    return {
            TAG: "Klarna",
            _0: 40
          };
  } else if (val > 60) {
    ErrorUtils$OrcaPaymentPage.valueOutRangeWarning(val, "options.style.height", "[40-60] - Paypal. Value set to max", logger);
    return {
            TAG: "Klarna",
            _0: 60
          };
  } else {
    return {
            TAG: "Klarna",
            _0: val
          };
  }
}

function getTheme(str, logger) {
  switch (str) {
    case "dark" :
        return "Dark";
    case "light" :
        return "Light";
    case "outline" :
        return "Outline";
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
            "outline",
            "light",
            "dark"
          ], "options.styles.theme", logger);
      return "Dark";
  }
}

function getHeightArray(val, logger) {
  return [
          getApplePayHeight(val, logger),
          getGooglePayHeight(val, logger),
          getPaypalHeight(val, logger),
          getKlarnaHeight(val, logger),
          getSamsungPayHeight(val, logger)
        ];
}

function getStyle(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "type",
                          "theme",
                          "height"
                        ], json, "options.wallets.style", logger);
                    return {
                            type_: getTypeArray(Utils$OrcaPaymentPage.getWarningString(json, "type", "", logger), logger),
                            theme: getTheme(Utils$OrcaPaymentPage.getWarningString(json, "theme", "", logger), logger),
                            height: getHeightArray(Utils$OrcaPaymentPage.getNumberWithWarning(json, "height", logger, 48), logger),
                            buttonRadius: Utils$OrcaPaymentPage.getNumberWithWarning(json, "buttonRadius", logger, 2)
                          };
                  })), defaultStyle);
}

function getWallets(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "applePay",
                          "googlePay",
                          "style",
                          "walletReturnUrl",
                          "payPal",
                          "klarna",
                          "samsungPay"
                        ], json, "options.wallets", logger);
                    return {
                            walletReturnUrl: Utils$OrcaPaymentPage.getRequiredString(json, "walletReturnUrl", "", logger),
                            applePay: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "applePay", "auto", logger), "options.wallets.applePay", logger),
                            googlePay: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "googlePay", "auto", logger), "options.wallets.googlePay", logger),
                            payPal: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "payPal", "auto", logger), "options.wallets.payPal", logger),
                            klarna: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "klarna", "auto", logger), "options.wallets.klarna", logger),
                            paze: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "paze", "auto", logger), "options.wallets.paze", logger),
                            samsungPay: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "samsungPay", "auto", logger), "options.wallets.samsungPay", logger),
                            style: getStyle(json, "style", logger)
                          };
                  })), defaultWallets);
}

function getLayout$1(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(dict[str], (function (json) {
                    return getLayoutValues(json, logger);
                  })), {
              TAG: "ObjectLayout",
              _0: defaultLayout
            });
}

function getCardDetails(dict, str) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            scheme: Utils$OrcaPaymentPage.getString(json, "scheme", ""),
                            last4Digits: Utils$OrcaPaymentPage.getString(json, "last4_digits", ""),
                            expiryMonth: Utils$OrcaPaymentPage.getString(json, "expiry_month", ""),
                            expiryYear: Utils$OrcaPaymentPage.getString(json, "expiry_year", ""),
                            cardToken: Utils$OrcaPaymentPage.getString(json, "card_token", ""),
                            cardHolderName: Utils$OrcaPaymentPage.getOptionString(json, "card_holder_name"),
                            nickname: Utils$OrcaPaymentPage.getString(json, "nick_name", "")
                          };
                  })), defaultCardDetails);
}

function getPaymentMethodType(dict) {
  return Core__Option.flatMap(dict["payment_method_type"], Core__JSON.Decode.string);
}

function getBank(dict) {
  return {
          mask: Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromDict(dict, "bank"), "mask", "")
        };
}

function itemToCustomerObjMapper(customerDict) {
  var customerArr = Utils$OrcaPaymentPage.getArray(customerDict, "customer_payment_methods");
  var isGuestCustomer = Utils$OrcaPaymentPage.getBool(customerDict, "is_guest_customer", false);
  var customerPaymentMethods = Belt_Array.keepMap(customerArr, Core__JSON.Decode.object).map(function (dict) {
        return {
                paymentToken: Utils$OrcaPaymentPage.getString(dict, "payment_token", ""),
                customerId: Utils$OrcaPaymentPage.getString(dict, "customer_id", ""),
                paymentMethod: Utils$OrcaPaymentPage.getString(dict, "payment_method", ""),
                paymentMethodId: Utils$OrcaPaymentPage.getString(dict, "payment_method_id", ""),
                paymentMethodIssuer: Utils$OrcaPaymentPage.getOptionString(dict, "payment_method_issuer"),
                card: getCardDetails(dict, "card"),
                paymentMethodType: getPaymentMethodType(dict),
                defaultPaymentMethodSet: Utils$OrcaPaymentPage.getBool(dict, "default_payment_method_set", false),
                requiresCvv: Utils$OrcaPaymentPage.getBool(dict, "requires_cvv", true),
                lastUsedAt: Utils$OrcaPaymentPage.getString(dict, "last_used_at", ""),
                bank: getBank(dict),
                recurringEnabled: Utils$OrcaPaymentPage.getBool(dict, "recurring_enabled", false)
              };
      });
  return [
          customerPaymentMethods,
          isGuestCustomer
        ];
}

function createCustomerObjArr(dict, key) {
  var customerDict = Core__Option.getOr(Core__Option.flatMap(dict[key], Core__JSON.Decode.object), {});
  var match = itemToCustomerObjMapper(customerDict);
  return {
          TAG: "LoadedSavedCards",
          _0: match[0],
          _1: match[1]
        };
}

function getCustomerMethods(dict, str) {
  var customerArr = Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []);
  if (customerArr.length === 0) {
    return "LoadingSavedCards";
  }
  var customerPaymentMethods = Belt_Array.keepMap(customerArr, Core__JSON.Decode.object).map(function (json) {
        return {
                paymentToken: Utils$OrcaPaymentPage.getString(json, "payment_token", ""),
                customerId: Utils$OrcaPaymentPage.getString(json, "customer_id", ""),
                paymentMethod: Utils$OrcaPaymentPage.getString(json, "payment_method", ""),
                paymentMethodId: Utils$OrcaPaymentPage.getString(json, "payment_method_id", ""),
                paymentMethodIssuer: Utils$OrcaPaymentPage.getString(json, "payment_method_issuer", ""),
                card: getCardDetails(json, "card"),
                paymentMethodType: getPaymentMethodType(dict),
                defaultPaymentMethodSet: Utils$OrcaPaymentPage.getBool(dict, "default_payment_method_set", false),
                requiresCvv: Utils$OrcaPaymentPage.getBool(dict, "requires_cvv", true),
                lastUsedAt: Utils$OrcaPaymentPage.getString(dict, "last_used_at", ""),
                bank: getBank(dict),
                recurringEnabled: Utils$OrcaPaymentPage.getBool(dict, "recurring_enabled", false)
              };
      });
  return {
          TAG: "LoadedSavedCards",
          _0: customerPaymentMethods,
          _1: false
        };
}

function getCustomMethodNames(dict, str) {
  return Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []), Core__JSON.Decode.object).map(function (json) {
              return {
                      paymentMethodName: Utils$OrcaPaymentPage.getString(json, "paymentMethodName", ""),
                      aliasName: Utils$OrcaPaymentPage.getString(json, "aliasName", "")
                    };
            });
}

function getBillingAddress(dict, str, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "isUseBillingAddress",
                          "usePrefilledValues"
                        ], json, "options.billingAddress", logger);
                    return {
                            isUseBillingAddress: Utils$OrcaPaymentPage.getBoolWithWarning(json, "isUseBillingAddress", false, logger),
                            usePrefilledValues: getShowType(Utils$OrcaPaymentPage.getWarningString(json, "usePrefilledValues", "auto", logger), "options.billingAddress.usePrefilledValues", logger)
                          };
                  })), defaultBillingAddress);
}

function getConfirmParams(dict) {
  return {
          return_url: Utils$OrcaPaymentPage.getString(dict, "return_url", ""),
          publishableKey: Utils$OrcaPaymentPage.getString(dict, "publishableKey", ""),
          redirect: Utils$OrcaPaymentPage.getString(dict, "redirect", "if_required")
        };
}

function getSdkHandleConfirmPaymentProps(dict) {
  return {
          handleConfirm: Utils$OrcaPaymentPage.getBool(dict, "handleConfirm", false),
          buttonText: Utils$OrcaPaymentPage.getOptionString(dict, "buttonText"),
          confirmParams: getConfirmParams(Utils$OrcaPaymentPage.getDictFromDict(dict, "confirmParams"))
        };
}

function itemToObjMapper(dict, logger) {
  ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
        "defaultValues",
        "business",
        "layout",
        "paymentMethodOrder",
        "customerPaymentMethods",
        "fields",
        "readOnly",
        "terms",
        "wallets",
        "showCardFormByDefault",
        "displaySavedPaymentMethodsCheckbox",
        "displaySavedPaymentMethods",
        "sdkHandleOneClickConfirmPayment",
        "showCardFormByDefault",
        "sdkHandleConfirmPayment",
        "paymentMethodsHeaderText",
        "savedPaymentMethodsHeaderText",
        "hideExpiredPaymentMethods",
        "branding",
        "displayDefaultSavedPaymentIcon",
        "hideCardNicknameField",
        "customMessageForCardTerms"
      ], dict, "options", logger);
  return {
          defaultValues: getDefaultValues(dict, "defaultValues", logger),
          layout: getLayout$1(dict, "layout", logger),
          business: getBusiness(dict, "business", logger),
          customerPaymentMethods: getCustomerMethods(dict, "customerPaymentMethods"),
          savedPaymentMethods: getCustomerMethods(dict, "customerPaymentMethods"),
          paymentMethodOrder: Utils$OrcaPaymentPage.getOptionalStrArray(dict, "paymentMethodOrder"),
          displaySavedPaymentMethodsCheckbox: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "displaySavedPaymentMethodsCheckbox", true, logger),
          displaySavedPaymentMethods: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "displaySavedPaymentMethods", true, logger),
          fields: getFields(dict, "fields", logger),
          readOnly: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "readOnly", false, logger),
          terms: getTerms(dict, "terms", logger),
          wallets: getWallets(dict, "wallets", logger),
          customMethodNames: getCustomMethodNames(dict, "customMethodNames"),
          branding: getShowType(Utils$OrcaPaymentPage.getWarningString(dict, "branding", "auto", logger), "options.branding", logger),
          payButtonStyle: getStyle(dict, "payButtonStyle", logger),
          showCardFormByDefault: Utils$OrcaPaymentPage.getBool(dict, "showCardFormByDefault", true),
          billingAddress: getBillingAddress(dict, "billingAddress", logger),
          sdkHandleConfirmPayment: getSdkHandleConfirmPaymentProps(Utils$OrcaPaymentPage.getDictFromDict(dict, "sdkHandleConfirmPayment")),
          paymentMethodsHeaderText: Utils$OrcaPaymentPage.getOptionString(dict, "paymentMethodsHeaderText"),
          savedPaymentMethodsHeaderText: Utils$OrcaPaymentPage.getOptionString(dict, "savedPaymentMethodsHeaderText"),
          hideExpiredPaymentMethods: Utils$OrcaPaymentPage.getBool(dict, "hideExpiredPaymentMethods", false),
          displayDefaultSavedPaymentIcon: Utils$OrcaPaymentPage.getBool(dict, "displayDefaultSavedPaymentIcon", true),
          hideCardNicknameField: Utils$OrcaPaymentPage.getBool(dict, "hideCardNicknameField", false),
          customMessageForCardTerms: Utils$OrcaPaymentPage.getString(dict, "customMessageForCardTerms", "")
        };
}

function getIsStoredPaymentMethodHasName(savedMethod) {
  return Core__Option.getOr(savedMethod.card.cardHolderName, "").length > 0;
}

function itemToPayerDetailsObjectMapper(dict) {
  return {
          email: Core__Option.flatMap(dict["email_address"], Core__JSON.Decode.string),
          phone: Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(dict["phone"], Core__JSON.Decode.object), (function (__x) {
                              return __x["phone_number"];
                            })), Core__JSON.Decode.object), (function (__x) {
                      return __x["national_number"];
                    })), Core__JSON.Decode.string)
        };
}

export {
  defaultCardDetails ,
  defaultCustomerMethods ,
  defaultLayout ,
  defaultAddress ,
  defaultBillingDetails ,
  defaultBusiness ,
  defaultDefaultValues ,
  defaultshowAddress ,
  defaultNeverShowAddress ,
  defaultBilling ,
  defaultNeverBilling ,
  defaultTerms ,
  defaultFields ,
  defaultStyle ,
  defaultWallets ,
  defaultBillingAddress ,
  defaultSdkHandleConfirmPayment ,
  defaultOptions ,
  getAddress ,
  getBillingDetails ,
  getDefaultValues ,
  getBusiness ,
  getShowType ,
  getApplePayType ,
  getGooglePayType ,
  getSamsungPayType ,
  getPayPalType ,
  getTypeArray ,
  getShowDetails ,
  getShowAddressDetails ,
  getShowTerms ,
  getShowAddress ,
  getDeatils ,
  getBilling ,
  getFields ,
  getLayoutValues ,
  getTerms ,
  getApplePayHeight ,
  getGooglePayHeight ,
  getSamsungPayHeight ,
  getPaypalHeight ,
  getKlarnaHeight ,
  getTheme ,
  getHeightArray ,
  getStyle ,
  getWallets ,
  getLayout$1 as getLayout,
  getCardDetails ,
  getPaymentMethodType ,
  getBank ,
  itemToCustomerObjMapper ,
  createCustomerObjArr ,
  getCustomerMethods ,
  getCustomMethodNames ,
  getBillingAddress ,
  getConfirmParams ,
  getSdkHandleConfirmPaymentProps ,
  itemToObjMapper ,
  getIsStoredPaymentMethodHasName ,
  itemToPayerDetailsObjectMapper ,
}
/* Utils-OrcaPaymentPage Not a pure module */
