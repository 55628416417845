// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as Recoil$OrcaPaymentPage from "../libraries/Recoil.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as GlobalVars$OrcaPaymentPage from "../GlobalVars.bs.js";
import * as PaymentBody$OrcaPaymentPage from "./PaymentBody.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./RecoilAtoms.bs.js";
import * as SessionsType$OrcaPaymentPage from "../Types/SessionsType.bs.js";
import * as PayPalHelpers$OrcaPaymentPage from "../Payments/PayPalHelpers.bs.js";
import * as PaymentModeType$OrcaPaymentPage from "../Types/PaymentModeType.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

var paymentMethodListValue = Recoil$OrcaPaymentPage.atom("paymentMethodListValue", PaymentMethodsRecord$OrcaPaymentPage.defaultList);

function paymentListLookupNew(list, order, isShowPaypal, isShowKlarnaOneClick, isKlarnaSDKFlow, paymentMethodListValue, areAllGooglePayRequiredFieldsPrefilled, isGooglePayReady, shouldDisplayApplePayInTabs, shouldDisplayPayPalInTabs) {
  var pmList = PaymentMethodsRecord$OrcaPaymentPage.buildFromPaymentList(list);
  var walletsList = [];
  var walletToBeDisplayedInTabs = [
    "mb_way",
    "ali_pay",
    "ali_pay_hk",
    "mobile_pay",
    "we_chat_pay",
    "vipps",
    "twint",
    "dana",
    "go_pay",
    "kakao_pay",
    "gcash",
    "momo",
    "touch_n_go",
    "mifinity"
  ];
  var otherPaymentList = [];
  if (shouldDisplayApplePayInTabs) {
    walletToBeDisplayedInTabs.push("apple_pay");
  }
  if (shouldDisplayPayPalInTabs) {
    walletToBeDisplayedInTabs.push("paypal");
  }
  if (!paymentMethodListValue.collect_billing_details_from_wallets && !areAllGooglePayRequiredFieldsPrefilled && isGooglePayReady) {
    walletToBeDisplayedInTabs.push("google_pay");
  }
  pmList.forEach(function (item) {
        if (walletToBeDisplayedInTabs.includes(item.paymentMethodName)) {
          otherPaymentList.push(item.paymentMethodName);
          return ;
        }
        if (item.methodType === "wallet") {
          if (item.paymentMethodName !== "paypal" || isShowPaypal) {
            walletsList.push(item.paymentMethodName);
            return ;
          } else {
            return ;
          }
        }
        if (item.methodType === "bank_debit") {
          otherPaymentList.push(item.paymentMethodName + "_debit");
          return ;
        }
        if (item.methodType === "bank_transfer") {
          otherPaymentList.push(item.paymentMethodName + "_transfer");
          return ;
        }
        if (item.methodType === "card") {
          otherPaymentList.push("card");
          return ;
        }
        if (item.methodType === "reward") {
          otherPaymentList.push(item.paymentMethodName);
          return ;
        }
        if (item.methodType === "pay_later") {
          if (item.paymentMethodName === "klarna") {
            var klarnaPaymentMethodExperience = PaymentMethodsRecord$OrcaPaymentPage.getPaymentExperienceTypeFromPML(paymentMethodListValue, item.methodType, item.paymentMethodName);
            var isInvokeSDKExperience = klarnaPaymentMethodExperience.includes("InvokeSDK");
            var isRedirectExperience = klarnaPaymentMethodExperience.includes("RedirectToURL");
            if (isKlarnaSDKFlow && isShowKlarnaOneClick && isInvokeSDKExperience) {
              walletsList.push(item.paymentMethodName);
              return ;
            } else if (isRedirectExperience) {
              otherPaymentList.push(item.paymentMethodName);
              return ;
            } else {
              return ;
            }
          }
          otherPaymentList.push(item.paymentMethodName);
          return ;
        }
        otherPaymentList.push(item.paymentMethodName);
      });
  return [
          Utils$OrcaPaymentPage.sortBasedOnPriority(Utils$OrcaPaymentPage.removeDuplicate(walletsList), order),
          Utils$OrcaPaymentPage.sortBasedOnPriority(Utils$OrcaPaymentPage.removeDuplicate(otherPaymentList), order)
        ];
}

function getMethod(method) {
  if (typeof method !== "object") {
    return "crypto";
  }
  switch (method.TAG) {
    case "PayLater" :
        return "pay_later";
    case "Wallets" :
        return "wallet";
    case "Cards" :
        return "card";
    case "Banks" :
        return "bank_redirect";
    case "BankTransfer" :
        return "bank_transfer";
    case "BankDebit" :
        return "bank_debit";
    
  }
}

function getMethodType(method) {
  if (typeof method !== "object") {
    return "crypto_currency";
  }
  switch (method.TAG) {
    case "PayLater" :
        switch (method._0.TAG) {
          case "Klarna" :
              return "klarna";
          case "AfterPay" :
              return "afterpay_clearpay";
          case "Affirm" :
              return "affirm";
          
        }
    case "Wallets" :
        switch (method._0.TAG) {
          case "Gpay" :
              return "google_pay";
          case "ApplePay" :
              return "apple_pay";
          case "Paypal" :
              return "paypal";
          
        }
    case "Cards" :
        return "card";
    case "Banks" :
        switch (method._0) {
          case "Sofort" :
              return "sofort";
          case "Eps" :
              return "eps";
          case "GiroPay" :
              return "giropay";
          case "Ideal" :
              return "ideal";
          
        }
    case "BankTransfer" :
    case "BankDebit" :
        break;
    
  }
  switch (method._0) {
    case "ACH" :
        return "ach";
    case "Sepa" :
        return "sepa";
    case "Bacs" :
        return "bacs";
    
  }
}

function getExperience(val) {
  if (val === "Redirect") {
    return "redirect_to_url";
  } else {
    return "invoke_sdk_client";
  }
}

function getPaymentExperienceType(val) {
  switch (val) {
    case "InvokeSDK" :
        return "invoke_sdk_client";
    case "RedirectToURL" :
        return "redirect_to_url";
    case "QrFlow" :
        return "display_qr_code";
    
  }
}

function getExperienceType(method) {
  if (typeof method !== "object") {
    return "redirect_to_url";
  }
  switch (method.TAG) {
    case "PayLater" :
    case "Wallets" :
        return getExperience(method._0._0);
    case "Cards" :
        return "card";
    default:
      return "";
  }
}

function getConnectors(list, method) {
  var paymentMethod = list.payment_methods.find(function (item) {
        return item.payment_method === getMethod(method);
      });
  if (paymentMethod === undefined) {
    return [
            [],
            []
          ];
  }
  var paymentMethodType = paymentMethod.payment_method_types.find(function (item) {
        return item.payment_method_type === getMethodType(method);
      });
  if (paymentMethodType === undefined) {
    return [
            [],
            []
          ];
  }
  var experienceType = paymentMethodType.payment_experience.find(function (item) {
        return getPaymentExperienceType(item.payment_experience_type) === getExperienceType(method);
      });
  var eligibleConnectors = experienceType !== undefined ? experienceType.eligible_connectors : [];
  if (typeof method !== "object") {
    return [
            eligibleConnectors,
            []
          ];
  }
  switch (method.TAG) {
    case "Banks" :
        return [
                [],
                paymentMethodType.bank_names
              ];
    case "BankTransfer" :
        return [
                paymentMethodType.bank_transfers_connectors,
                []
              ];
    case "BankDebit" :
        return [
                paymentMethodType.bank_debits_connectors,
                []
              ];
    default:
      return [
              eligibleConnectors,
              []
            ];
  }
}

function getDisplayNameAndIcon(customNames, paymentMethodName, defaultName, defaultIcon) {
  var customNameObj = customNames.filter(function (item) {
          return item.paymentMethodName === paymentMethodName;
        })[0];
  if (customNameObj === undefined) {
    return [
            defaultName,
            defaultIcon
          ];
  }
  if (!(customNameObj.paymentMethodName === "classic" || customNameObj.paymentMethodName === "evoucher")) {
    return [
            defaultName,
            defaultIcon
          ];
  }
  var aliasName = customNameObj.aliasName;
  if (aliasName === "") {
    return [
            defaultName,
            defaultIcon
          ];
  }
  var id = aliasName.split(" ");
  return [
          aliasName,
          Caml_option.some(PaymentMethodsRecord$OrcaPaymentPage.icon(19, undefined, Core__Option.getOr(id[0], "")))
        ];
}

function getPaymentMethodName(paymentMethodType, paymentMethodName) {
  if (paymentMethodType === "bank_debit") {
    return paymentMethodName.replace("_debit", "");
  } else if (paymentMethodType === "bank_transfer") {
    return paymentMethodName.replace("_transfer", "");
  } else {
    return paymentMethodName;
  }
}

function isAppendingCustomerAcceptance(isGuestCustomer, paymentType) {
  if (isGuestCustomer) {
    return false;
  } else if (paymentType === "NEW_MANDATE") {
    return true;
  } else {
    return paymentType === "SETUP_MANDATE";
  }
}

function appendedCustomerAcceptance(isGuestCustomer, paymentType, body) {
  if (isAppendingCustomerAcceptance(isGuestCustomer, paymentType)) {
    return body.concat([[
                  "customer_acceptance",
                  PaymentBody$OrcaPaymentPage.customerAcceptanceBody
                ]]);
  } else {
    return body;
  }
}

function usePaymentMethodTypeFromList(paymentMethodListValue, paymentMethod, paymentMethodType) {
  return React.useMemo((function () {
                return Core__Option.getOr(PaymentMethodsRecord$OrcaPaymentPage.getPaymentMethodTypeFromList(paymentMethodListValue, paymentMethod, getPaymentMethodName(paymentMethod, paymentMethodType)), PaymentMethodsRecord$OrcaPaymentPage.defaultPaymentMethodType);
              }), [
              paymentMethodListValue,
              paymentMethod,
              paymentMethodType
            ]);
}

function useAreAllRequiredFieldsPrefilled(paymentMethodListValue, paymentMethod, paymentMethodType) {
  var paymentMethodTypes = usePaymentMethodTypeFromList(paymentMethodListValue, paymentMethod, paymentMethodType);
  return Core__Array.reduce(paymentMethodTypes.required_fields, true, (function (acc, requiredField) {
                if (acc) {
                  return requiredField.value !== "";
                } else {
                  return false;
                }
              }));
}

function getIsKlarnaSDKFlow(sessions) {
  var dict = Utils$OrcaPaymentPage.getDictFromJson(sessions);
  var sessionObj = SessionsType$OrcaPaymentPage.itemToObjMapper(dict, "Others");
  var klarnaTokenObj = SessionsType$OrcaPaymentPage.getPaymentSessionObj(sessionObj.sessionsToken, "Klarna");
  if (klarnaTokenObj.TAG === "OtherTokenOptional") {
    return Core__Option.isSome(klarnaTokenObj._0);
  } else {
    return false;
  }
}

function usePaypalFlowStatus(sessions, paymentMethodListValue) {
  var sessionObj = SessionsType$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(sessions), "Others");
  var match = PayPalHelpers$OrcaPaymentPage.usePaymentMethodExperience(paymentMethodListValue, sessionObj);
  var paypalToken = match.paypalToken;
  var isPaypalTokenExist;
  isPaypalTokenExist = paypalToken.TAG === "OtherTokenOptional" ? paypalToken._0 !== undefined : false;
  return [
          match.isPaypalSDKFlow,
          match.isPaypalRedirectFlow,
          isPaypalTokenExist
        ];
}

function useGetPaymentMethodList(paymentOptions, paymentType, sessions) {
  var methodslist = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.paymentMethodList);
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var showCardFormByDefault = match.showCardFormByDefault;
  var paymentMethodOrder = match.paymentMethodOrder;
  var optionAtomValue = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var paymentOrder = Utils$OrcaPaymentPage.removeDuplicate(Utils$OrcaPaymentPage.getOptionalArr(paymentMethodOrder));
  var isKlarnaSDKFlow = getIsKlarnaSDKFlow(sessions);
  var paymentMethodListValue$1 = Recoil.useRecoilValue(paymentMethodListValue);
  var areAllApplePayRequiredFieldsPrefilled = useAreAllRequiredFieldsPrefilled(paymentMethodListValue$1, "wallet", "apple_pay");
  var areAllGooglePayRequiredFieldsPrefilled = useAreAllRequiredFieldsPrefilled(paymentMethodListValue$1, "wallet", "google_pay");
  var areAllPaypalRequiredFieldsPreFilled = useAreAllRequiredFieldsPrefilled(paymentMethodListValue$1, "wallet", "paypal");
  var isApplePayReady = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isApplePayReady);
  var isGooglePayReady = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isGooglePayReady);
  var match$1 = usePaypalFlowStatus(sessions, paymentMethodListValue$1);
  var isPaypalTokenExist = match$1[2];
  var isPaypalRedirectFlow = match$1[1];
  var isPaypalSDKFlow = match$1[0];
  return React.useMemo((function () {
                if (typeof methodslist !== "object") {
                  if (methodslist === "Loading") {
                    return [
                            [],
                            [],
                            []
                          ];
                  } else if (showCardFormByDefault && Utils$OrcaPaymentPage.checkPriorityList(paymentMethodOrder)) {
                    return [
                            [],
                            ["card"],
                            []
                          ];
                  } else {
                    return [
                            [],
                            [],
                            []
                          ];
                  }
                }
                if (methodslist.TAG !== "Loaded") {
                  return [
                          [],
                          [],
                          []
                        ];
                }
                var paymentOrder$1 = paymentOrder.length > 0 ? paymentOrder : PaymentModeType$OrcaPaymentPage.defaultOrder;
                var plist = PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(methodslist._0));
                var shouldDisplayApplePayInTabs = !paymentMethodListValue$1.collect_billing_details_from_wallets && !areAllApplePayRequiredFieldsPrefilled && isApplePayReady;
                var isShowPaypal = optionAtomValue.wallets.payPal === "Auto";
                var shouldDisplayPayPalInTabs = isShowPaypal && !paymentMethodListValue$1.collect_billing_details_from_wallets && !areAllPaypalRequiredFieldsPreFilled && isPaypalRedirectFlow && (!isPaypalSDKFlow || !isPaypalTokenExist);
                var match = paymentListLookupNew(plist, paymentOrder$1, isShowPaypal, optionAtomValue.wallets.klarna === "Auto", isKlarnaSDKFlow, plist, areAllGooglePayRequiredFieldsPrefilled, isGooglePayReady, shouldDisplayApplePayInTabs, shouldDisplayPayPalInTabs);
                var otherOptions = match[1];
                var klarnaPaymentMethodExperience = PaymentMethodsRecord$OrcaPaymentPage.getPaymentExperienceTypeFromPML(plist, "pay_later", "klarna");
                var isKlarnaInvokeSDKExperience = klarnaPaymentMethodExperience.includes("InvokeSDK");
                var filterPaymentMethods = function (paymentOptionsList) {
                  return paymentOptionsList.filter(function (paymentOptionsName) {
                              switch (paymentOptionsName) {
                                case "apple_pay" :
                                    return shouldDisplayApplePayInTabs;
                                case "klarna" :
                                    return !(isKlarnaSDKFlow && isKlarnaInvokeSDKExperience);
                                default:
                                  return true;
                              }
                            });
                };
                return [
                        Utils$OrcaPaymentPage.getWalletPaymentMethod(Utils$OrcaPaymentPage.removeDuplicate(match[0]), paymentType),
                        filterPaymentMethods(Utils$OrcaPaymentPage.removeDuplicate(paymentOptions.concat(otherOptions))),
                        otherOptions
                      ];
              }), [
              methodslist,
              paymentMethodOrder,
              optionAtomValue.wallets.payPal,
              optionAtomValue.wallets.klarna,
              paymentType,
              isKlarnaSDKFlow,
              areAllApplePayRequiredFieldsPrefilled,
              areAllGooglePayRequiredFieldsPrefilled,
              isApplePayReady,
              isGooglePayReady,
              showCardFormByDefault
            ]);
}

function useStatesJson(setStatesJson) {
  React.useEffect((function () {
          var fetchStates = async function () {
            try {
              var res = await import("./../States.json");
              return setStatesJson(function (param) {
                          return res.states;
                        });
            }
            catch (raw_err){
              var err = Caml_js_exceptions.internalToOCamlException(raw_err);
              console.error("Error importing states:", err);
              return ;
            }
          };
          fetchStates();
        }), []);
}

async function getStateJson(param) {
  try {
    return (await import("./../States.json")).states;
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    console.error("Error importing states:", err);
    return null;
  }
}

function sortCustomerMethodsBasedOnPriority(sortArr, priorityArr, displayDefaultSavedPaymentIconOpt) {
  var displayDefaultSavedPaymentIcon = displayDefaultSavedPaymentIconOpt !== undefined ? displayDefaultSavedPaymentIconOpt : true;
  if (priorityArr.length === 0) {
    return sortArr;
  }
  var getPaymentMethod = function (customerMethod) {
    if (customerMethod.paymentMethod === "card") {
      return customerMethod.paymentMethod;
    }
    var paymentMethodType = customerMethod.paymentMethodType;
    if (paymentMethodType !== undefined) {
      return paymentMethodType;
    } else {
      return customerMethod.paymentMethod;
    }
  };
  var getCustomerMethodPriority = function (paymentMethod) {
    var priorityArrLength = priorityArr.length;
    var index = priorityArr.indexOf(paymentMethod);
    if (index === -1) {
      return priorityArrLength;
    } else {
      return index;
    }
  };
  var handleCustomerMethodsSort = function (firstCustomerMethod, secondCustomerMethod) {
    var firstPaymentMethod = getPaymentMethod(firstCustomerMethod);
    var secondPaymentMethod = getPaymentMethod(secondCustomerMethod);
    if (displayDefaultSavedPaymentIcon && (firstCustomerMethod.defaultPaymentMethodSet || secondCustomerMethod.defaultPaymentMethodSet)) {
      if (firstCustomerMethod.defaultPaymentMethodSet) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return getCustomerMethodPriority(firstPaymentMethod) - getCustomerMethodPriority(secondPaymentMethod) | 0;
    }
  };
  return Belt_SortArray.stableSortBy(sortArr, handleCustomerMethodsSort);
}

function getSupportedCardBrands(paymentMethodListValue) {
  var cardPaymentMethod = paymentMethodListValue.payment_methods.find(function (ele) {
        return ele.payment_method === "card";
      });
  if (cardPaymentMethod === undefined) {
    return ;
  }
  var cardNetworks = cardPaymentMethod.payment_method_types.map(function (ele) {
        return ele.card_networks;
      });
  var cardNetworkNames = cardNetworks.map(function (ele) {
        return ele.map(function (val) {
                    return CardUtils$OrcaPaymentPage.getCardStringFromType(val.card_network).toLowerCase();
                  });
      });
  return Utils$OrcaPaymentPage.getUniqueArray(Core__Array.reduce(cardNetworkNames, [], (function (acc, ele) {
                    return acc.concat(ele);
                  })));
}

function checkIsCardSupported(cardNumber, supportedCardBrands) {
  var cardBrand = CardUtils$OrcaPaymentPage.getCardBrand(cardNumber);
  var clearValue = CardUtils$OrcaPaymentPage.clearSpaces(cardNumber);
  if (cardBrand === "" && (GlobalVars$OrcaPaymentPage.isInteg || GlobalVars$OrcaPaymentPage.isSandbox)) {
    return CardUtils$OrcaPaymentPage.cardValid(clearValue, cardBrand);
  } else if (CardUtils$OrcaPaymentPage.cardValid(clearValue, cardBrand)) {
    if (supportedCardBrands !== undefined) {
      return supportedCardBrands.includes(cardBrand.toLowerCase());
    } else {
      return true;
    }
  } else {
    return ;
  }
}

export {
  paymentMethodListValue ,
  paymentListLookupNew ,
  getMethod ,
  getMethodType ,
  getExperience ,
  getPaymentExperienceType ,
  getExperienceType ,
  getConnectors ,
  getDisplayNameAndIcon ,
  getPaymentMethodName ,
  isAppendingCustomerAcceptance ,
  appendedCustomerAcceptance ,
  usePaymentMethodTypeFromList ,
  useAreAllRequiredFieldsPrefilled ,
  getIsKlarnaSDKFlow ,
  usePaypalFlowStatus ,
  useGetPaymentMethodList ,
  useStatesJson ,
  getStateJson ,
  sortCustomerMethodsBasedOnPriority ,
  getSupportedCardBrands ,
  checkIsCardSupported ,
}
/* paymentMethodListValue Not a pure module */
