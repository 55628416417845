// Generated by ReScript, PLEASE EDIT WITH CARE


function RenderIf(props) {
  if (props.condition) {
    return props.children;
  } else {
    return null;
  }
}

var make = RenderIf;

export {
  make ,
}
/* No side effect */
