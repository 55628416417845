// Generated by ReScript, PLEASE EDIT WITH CARE


var eventListenerMap = {};

function addSmartEventListener(type_, handlerMethod, activity) {
  var value = eventListenerMap[activity];
  if (value !== undefined) {
    window.removeEventListener(type_, value);
  }
  eventListenerMap[activity] = handlerMethod;
  window.addEventListener(type_, handlerMethod);
}

export {
  eventListenerMap ,
  addSmartEventListener ,
}
/* No side effect */
