// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Recoil$OrcaPaymentPage from "../libraries/Recoil.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";
import * as PaymentType$OrcaPaymentPage from "../Types/PaymentType.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";
import * as PaymentField$OrcaPaymentPage from "./PaymentField.bs.js";
import * as DropdownField$OrcaPaymentPage from "./DropdownField.bs.js";

function PhoneNumberPaymentInput(props) {
  var phoneRef = React.useRef(null);
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var loggerState = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var showDetails = PaymentType$OrcaPaymentPage.getShowDetails(match.fields.billingDetails, loggerState);
  var match$1 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userPhoneNumber, "phone", loggerState);
  var setPhone = match$1[1];
  var clientCountry = Utils$OrcaPaymentPage.getClientCountry(Intl.DateTimeFormat().resolvedOptions().timeZone);
  var currentCountryCode = Utils$OrcaPaymentPage.getCountryCode(clientCountry.countryName);
  var match$2 = React.useState(function () {
        return "";
      });
  var setDisplayValue = match$2[1];
  var countryAndCodeCodeList = Utils$OrcaPaymentPage.getArray(Core__Option.getOr(Core__JSON.Decode.object(Utils$OrcaPaymentPage.phoneNumberJson), {}), "countries");
  var phoneNumberCodeOptions = Core__Array.reduce(countryAndCodeCodeList, [], (function (acc, countryObj) {
          var countryObjDict = Utils$OrcaPaymentPage.getDictFromJson(countryObj);
          var countryFlag = Utils$OrcaPaymentPage.getString(countryObjDict, "country_flag", "");
          var phoneNumberCode = Utils$OrcaPaymentPage.getString(countryObjDict, "phone_number_code", "");
          var countryName = Utils$OrcaPaymentPage.getString(countryObjDict, "country_name", "");
          var phoneNumberOptionsValue_value = countryFlag + "#" + phoneNumberCode;
          var phoneNumberOptionsValue_label = countryFlag + " " + countryName + " " + phoneNumberCode;
          var phoneNumberOptionsValue_displayValue = countryFlag + " " + phoneNumberCode;
          var phoneNumberOptionsValue = {
            value: phoneNumberOptionsValue_value,
            label: phoneNumberOptionsValue_label,
            displayValue: phoneNumberOptionsValue_displayValue
          };
          acc.push(phoneNumberOptionsValue);
          return acc;
        }));
  var defaultCountryCodeFilteredValue = Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(Core__Option.getOr(countryAndCodeCodeList.filter(function (countryObj) {
                      return Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(countryObj), "country_code", "") === currentCountryCode.isoAlpha2;
                    })[0], {
                phone_number_code: ""
              })), "phone_number_code", "");
  var match$3 = React.useState(function () {
        return defaultCountryCodeFilteredValue;
      });
  var valueDropDown = match$3[0];
  var getCountryCodeSplitValue = function (val) {
    return Core__Option.getOr(val.split("#")[1], "");
  };
  var changePhone = function (ev) {
    var val = ev.target.value.replace(/\D|\s/g, "");
    setPhone(function (prev) {
          var newrecord = Caml_obj.obj_dup(prev);
          newrecord.countryCode = getCountryCodeSplitValue(valueDropDown);
          newrecord.value = val;
          return newrecord;
        });
  };
  React.useEffect((function () {
          setPhone(function (prev) {
                var newrecord = Caml_obj.obj_dup(prev);
                newrecord.countryCode = getCountryCodeSplitValue(valueDropDown);
                return newrecord;
              });
        }), [valueDropDown]);
  React.useEffect((function () {
          var findDisplayValue = Core__Option.getOr(phoneNumberCodeOptions.find(function (ele) {
                    return ele.value === valueDropDown;
                  }), DropdownField$OrcaPaymentPage.defaultValue);
          setDisplayValue(function (param) {
                return Core__Option.getOr(findDisplayValue.displayValue, Core__Option.getOr(findDisplayValue.label, findDisplayValue.value));
              });
        }), [phoneNumberCodeOptions]);
  return React.createElement(RenderIf$OrcaPaymentPage.make, {
              condition: showDetails.phone === "Auto",
              children: React.createElement(PaymentField$OrcaPaymentPage.make, {
                    value: match$1[0],
                    valueDropDown: valueDropDown,
                    setValueDropDown: match$3[1],
                    dropDownOptions: phoneNumberCodeOptions,
                    onChange: changePhone,
                    fieldName: "Phone Number",
                    name: "phone",
                    type_: "tel",
                    paymentType: "Payment",
                    maxLength: 14,
                    placeholder: "000 000 000",
                    inputRef: phoneRef,
                    displayValue: match$2[0],
                    setDisplayValue: setDisplayValue
                  })
            });
}

var make = PhoneNumberPaymentInput;

export {
  make ,
}
/* react Not a pure module */
