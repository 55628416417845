// Generated by ReScript, PLEASE EDIT WITH CARE


var constantStrings = {
  formFieldCardNumberPlaceholder: "****** 4242",
  formFieldACHRoutingNumberPlaceholder: "110000000",
  formFieldAccountNumberPlaceholder: "**** 6789",
  formFieldSortCodePlaceholder: "11000",
  formFieldSepaIbanPlaceholder: "NL **** 6789",
  formFieldSepaBicPlaceholder: "ABNANL2A",
  formFieldPixIdPlaceholder: "**** 3251",
  formFieldBankAccountNumberPlaceholder: "**** 1232"
};

export {
  constantStrings ,
}
/* No side effect */
