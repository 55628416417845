// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__Int from "@rescript/core/src/Core__Int.bs.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "./ErrorUtils.bs.js";
import * as EnglishLocale$OrcaPaymentPage from "../LocaleStrings/EnglishLocale.bs.js";
import * as PaymentMethodCollectTypes$OrcaPaymentPage from "../Types/PaymentMethodCollectTypes.bs.js";

function getNestedValue(dict, key) {
  var keys = key.split(".");
  var length = keys.length;
  var _currentDict = dict;
  var _index = 0;
  while(true) {
    var index = _index;
    var currentDict = _currentDict;
    if (index === (length - 1 | 0)) {
      return currentDict[keys[index]];
    }
    var keyPart = keys[index];
    var subDict = currentDict[keyPart];
    if (subDict === undefined) {
      return ;
    }
    var innerDict = Core__JSON.Decode.object(subDict);
    if (innerDict === undefined) {
      return ;
    }
    _index = index + 1 | 0;
    _currentDict = innerDict;
    continue ;
  };
}

function getOrCreateSubDict(dict, key) {
  var subDict = dict[key];
  if (subDict !== undefined) {
    var innerDict = Core__JSON.Decode.object(subDict);
    if (innerDict !== undefined) {
      return innerDict;
    }
    var newSubDict = {};
    dict[key] = newSubDict;
    return newSubDict;
  }
  var newSubDict$1 = {};
  dict[key] = newSubDict$1;
  return newSubDict$1;
}

function setNestedValue(dict, key, value) {
  var keys = key.split(".");
  var _currentDict = dict;
  var _index = 0;
  while(true) {
    var index = _index;
    var currentDict = _currentDict;
    if (index === (keys.length - 1 | 0)) {
      currentDict[keys[index]] = value;
      return ;
    }
    var keyPart = keys[index];
    var subDict = getOrCreateSubDict(currentDict, keyPart);
    _index = index + 1 | 0;
    _currentDict = subDict;
    continue ;
  };
}

function getPaymentMethodForPmt(paymentMethodType) {
  switch (paymentMethodType.TAG) {
    case "Card" :
        return "Card";
    case "BankTransfer" :
        return "BankTransfer";
    case "Wallet" :
        return "Wallet";
    
  }
}

function getPaymentMethod(paymentMethod) {
  switch (paymentMethod) {
    case "Card" :
        return "card";
    case "BankTransfer" :
        return "bank_transfer";
    case "Wallet" :
        return "wallet";
    
  }
}

function getPaymentMethodForPayoutsConfirm(paymentMethod) {
  switch (paymentMethod) {
    case "Card" :
        return "card";
    case "BankTransfer" :
        return "bank";
    case "Wallet" :
        return "wallet";
    
  }
}

function getPaymentMethodType(paymentMethodType) {
  switch (paymentMethodType.TAG) {
    case "Card" :
        if (paymentMethodType._0 === "Credit") {
          return "credit";
        } else {
          return "debit";
        }
    case "BankTransfer" :
        switch (paymentMethodType._0) {
          case "ACH" :
              return "ach";
          case "Bacs" :
              return "bacs";
          case "Pix" :
              return "pix";
          case "Sepa" :
              return "sepa";
          
        }
    case "Wallet" :
        if (paymentMethodType._0 === "Paypal") {
          return "paypal";
        } else {
          return "venmo";
        }
    
  }
}

function getPaymentMethodLabel(paymentMethod) {
  switch (paymentMethod) {
    case "Card" :
        return "Card";
    case "BankTransfer" :
        return "Bank";
    case "Wallet" :
        return "Wallet";
    
  }
}

function getPaymentMethodTypeLabel(paymentMethodType) {
  switch (paymentMethodType.TAG) {
    case "Card" :
        return "Card";
    case "BankTransfer" :
        switch (paymentMethodType._0) {
          case "ACH" :
              return "ACH";
          case "Bacs" :
              return "BACS";
          case "Pix" :
              return "Pix";
          case "Sepa" :
              return "SEPA";
          
        }
    case "Wallet" :
        if (paymentMethodType._0 === "Paypal") {
          return "PayPal";
        } else {
          return "Venmo";
        }
    
  }
}

function getPaymentMethodDataFieldKey(key) {
  if (key.TAG === "BillingAddress") {
    var tmp = key._0;
    if (typeof tmp === "object") {
      if (tmp.TAG === "FullName") {
        return "billing.address.fullName";
      } else {
        return "billing.address.addressCountry";
      }
    }
    switch (tmp) {
      case "Email" :
          return "billing.address.email";
      case "CountryCode" :
          return "billing.address.countryCode";
      case "PhoneNumber" :
          return "billing.address.phoneNumber";
      case "PhoneCountryCode" :
          return "billing.address.phoneCountryCode";
      case "AddressLine1" :
          return "billing.address.addressLine1";
      case "AddressLine2" :
          return "billing.address.addressLine2";
      case "AddressCity" :
          return "billing.address.addressCity";
      case "AddressState" :
          return "billing.address.addressState";
      case "AddressPincode" :
          return "billing.address.addressPincode";
      
    }
  } else {
    var tmp$1 = key._0;
    if (typeof tmp$1 === "object") {
      return "card.cardExp";
    }
    switch (tmp$1) {
      case "CardNumber" :
          return "card.cardNumber";
      case "CardHolderName" :
          return "card.cardHolder";
      case "CardBrand" :
          return "card.brand";
      case "ACHRoutingNumber" :
          return "ach.routing";
      case "ACHAccountNumber" :
          return "ach.account";
      case "ACHBankName" :
          return "ach.bankName";
      case "ACHBankCity" :
          return "ach.bankCity";
      case "BacsSortCode" :
          return "bacs.sort";
      case "BacsAccountNumber" :
          return "bacs.account";
      case "BacsBankName" :
          return "bacs.bankName";
      case "BacsBankCity" :
          return "bacs.bankCity";
      case "SepaIban" :
          return "sepa.iban";
      case "SepaBic" :
          return "sepa.bic";
      case "SepaBankName" :
          return "sepa.bankName";
      case "SepaBankCity" :
          return "sepa.bankCity";
      case "SepaCountryCode" :
          return "sepa.countryCode";
      case "PaypalMail" :
          return "paypal.email";
      case "PaypalMobNumber" :
          return "paypal.phoneNumber";
      case "PixKey" :
          return "pix.key";
      case "PixBankAccountNumber" :
          return "pix.account";
      case "PixBankName" :
          return "pix.bankName";
      case "VenmoMobNumber" :
          return "venmo.phoneNumber";
      
    }
  }
}

function getPaymentMethodDataFieldLabel(key, localeString) {
  if (key.TAG === "BillingAddress") {
    var tmp = key._0;
    if (typeof tmp === "object") {
      if (tmp.TAG === "FullName") {
        return localeString.fullNameLabel;
      } else {
        return localeString.countryLabel;
      }
    }
    switch (tmp) {
      case "Email" :
          return localeString.emailLabel;
      case "CountryCode" :
          return localeString.countryLabel;
      case "PhoneNumber" :
          return localeString.formFieldPhoneNumberLabel;
      case "PhoneCountryCode" :
          return localeString.formFieldCountryCodeLabel;
      case "AddressLine1" :
          return localeString.line1Label;
      case "AddressLine2" :
          return localeString.line2Label;
      case "AddressCity" :
          return localeString.cityLabel;
      case "AddressState" :
          return localeString.stateLabel;
      case "AddressPincode" :
          return localeString.postalCodeLabel;
      
    }
  } else {
    var tmp$1 = key._0;
    if (typeof tmp$1 === "object") {
      return localeString.validThruText;
    }
    switch (tmp$1) {
      case "CardNumber" :
          return localeString.cardNumberLabel;
      case "CardHolderName" :
          return localeString.cardHolderName;
      case "CardBrand" :
          return "Misc.";
      case "ACHRoutingNumber" :
          return localeString.formFieldACHRoutingNumberLabel;
      case "BacsSortCode" :
          return localeString.sortCodeText;
      case "ACHAccountNumber" :
      case "BacsAccountNumber" :
          return localeString.accountNumberText;
      case "SepaIban" :
          return localeString.formFieldSepaIbanLabel;
      case "SepaBic" :
          return localeString.formFieldSepaBicLabel;
      case "ACHBankCity" :
      case "BacsBankCity" :
      case "SepaBankCity" :
          return localeString.formFieldBankCityLabel;
      case "SepaCountryCode" :
          return localeString.formFieldCountryCodeLabel;
      case "PaypalMail" :
          return localeString.emailLabel;
      case "PixKey" :
          return localeString.formFieldPixIdLabel;
      case "PixBankAccountNumber" :
          return localeString.formFieldBankAccountNumberLabel;
      case "PaypalMobNumber" :
      case "VenmoMobNumber" :
          return localeString.formFieldPhoneNumberLabel;
      default:
        return localeString.formFieldBankNameLabel;
    }
  }
}

function getPaymentMethodDataFieldPlaceholder(key, locale, constant) {
  if (key.TAG === "BillingAddress") {
    return "";
  }
  var tmp = key._0;
  if (typeof tmp === "object") {
    return locale.expiryPlaceholder;
  }
  switch (tmp) {
    case "CardNumber" :
        return constant.formFieldCardNumberPlaceholder;
    case "CardHolderName" :
        return locale.formFieldCardHoldernamePlaceholder;
    case "CardBrand" :
        return "Misc.";
    case "ACHRoutingNumber" :
        return constant.formFieldACHRoutingNumberPlaceholder;
    case "BacsSortCode" :
        return constant.formFieldSortCodePlaceholder;
    case "ACHAccountNumber" :
    case "BacsAccountNumber" :
        return constant.formFieldAccountNumberPlaceholder;
    case "SepaIban" :
        return constant.formFieldSepaIbanPlaceholder;
    case "SepaBic" :
        return constant.formFieldSepaBicPlaceholder;
    case "ACHBankCity" :
    case "BacsBankCity" :
    case "SepaBankCity" :
        return locale.formFieldBankCityPlaceholder;
    case "SepaCountryCode" :
        return locale.countryLabel;
    case "PaypalMail" :
        return locale.formFieldEmailPlaceholder;
    case "PixKey" :
        return constant.formFieldPixIdPlaceholder;
    case "PixBankAccountNumber" :
        return constant.formFieldBankAccountNumberPlaceholder;
    case "PaypalMobNumber" :
    case "VenmoMobNumber" :
        return locale.formFieldPhoneNumberPlaceholder;
    default:
      return locale.formFieldBankNamePlaceholder;
  }
}

function getPaymentMethodDataFieldMaxLength(key) {
  if (key.TAG === "BillingAddress") {
    return 32;
  }
  var tmp = key._0;
  if (typeof tmp === "object") {
    return 7;
  }
  switch (tmp) {
    case "CardNumber" :
        return 23;
    case "ACHRoutingNumber" :
        return 9;
    case "ACHAccountNumber" :
        return 12;
    case "BacsSortCode" :
        return 6;
    case "BacsAccountNumber" :
        return 18;
    case "SepaIban" :
        return 34;
    case "SepaBic" :
        return 8;
    default:
      return 32;
  }
}

function getPaymentMethodDataFieldCharacterPattern(key) {
  if (key.TAG === "BillingAddress") {
    var tmp = key._0;
    if (typeof tmp === "object") {
      return ;
    }
    switch (tmp) {
      case "PhoneNumber" :
          return Caml_option.some(/^[0-9]{1,12}$/);
      case "PhoneCountryCode" :
          return Caml_option.some(/^[0-9]{1,2}$/);
      case "AddressPincode" :
          return Caml_option.some(/^([0-9A-Z]| ){1,10}$/);
      default:
        return ;
    }
  } else {
    var tmp$1 = key._0;
    if (typeof tmp$1 === "object") {
      return ;
    }
    switch (tmp$1) {
      case "CardNumber" :
          return Caml_option.some(/^\d{1,18}$/);
      case "CardHolderName" :
          return Caml_option.some(/^([a-zA-Z]| ){1,32}$/);
      case "ACHRoutingNumber" :
          return Caml_option.some(/^\d{1,9}$/);
      case "ACHAccountNumber" :
          return Caml_option.some(/^\d{1,17}$/);
      case "BacsSortCode" :
          return Caml_option.some(/^\d{1,6}$/);
      case "BacsAccountNumber" :
          return Caml_option.some(/^\d{1,18}$/);
      case "SepaIban" :
          return Caml_option.some(/^([A-Z0-9]| ){1,34}$/);
      case "SepaBic" :
          return Caml_option.some(/^([A-Z0-9]| ){1,8}$/);
      case "PaypalMail" :
          return Caml_option.some(/^[a-zA-Z0-9._%+-]*[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*$/);
      case "PaypalMobNumber" :
          return Caml_option.some(/^[0-9]{1,12}$/);
      default:
        return ;
    }
  }
}

function getPaymentMethodDataFieldInputType(key) {
  if (key.TAG === "BillingAddress") {
    return "text";
  }
  var tmp = key._0;
  if (typeof tmp === "object") {
    return "tel";
  }
  switch (tmp) {
    case "PaypalMail" :
        return "email";
    case "CardNumber" :
    case "ACHRoutingNumber" :
    case "ACHAccountNumber" :
    case "BacsSortCode" :
    case "BacsAccountNumber" :
    case "PaypalMobNumber" :
    case "VenmoMobNumber" :
        return "tel";
    default:
      return "text";
  }
}

function getPayoutImageSource(payoutStatus) {
  switch (payoutStatus) {
    case "Success" :
        return "https://live.hyperswitch.io/payment-link-assets/success.png";
    case "Initiated" :
    case "Pending" :
    case "RequiresFulfillment" :
        return "https://live.hyperswitch.io/payment-link-assets/pending.png";
    default:
      return "https://live.hyperswitch.io/payment-link-assets/failed.png";
  }
}

function getPayoutReadableStatus(payoutStatus, localeString) {
  switch (payoutStatus) {
    case "Success" :
        return localeString.payoutStatusSuccessText;
    case "Initiated" :
    case "Pending" :
    case "RequiresFulfillment" :
        return localeString.payoutStatusPendingText;
    default:
      return localeString.payoutStatusFailedText;
  }
}

function getPayoutStatusString(payoutStatus) {
  switch (payoutStatus) {
    case "Success" :
        return "success";
    case "Failed" :
        return "failed";
    case "Cancelled" :
        return "expired";
    case "Initiated" :
        return "initiated";
    case "Expired" :
        return "cancelled";
    case "Reversed" :
        return "reversed";
    case "Pending" :
        return "pending";
    case "Ineligible" :
        return "ineligible";
    case "RequiresCreation" :
        return "requires_creation";
    case "RequiresConfirmation" :
        return "requires_confirmation";
    case "RequiresPayoutMethodData" :
        return "requires_payout_method_data";
    case "RequiresFulfillment" :
        return "requires_fulfillment";
    case "RequiresVendorAccountCreation" :
        return "requires_vendor_account_creation";
    
  }
}

function getPayoutStatusMessage(payoutStatus, localeString) {
  switch (payoutStatus) {
    case "Success" :
        return localeString.payoutStatusSuccessMessage;
    case "Initiated" :
    case "Pending" :
    case "RequiresFulfillment" :
        return localeString.payoutStatusPendingMessage;
    default:
      return localeString.payoutStatusFailedMessage;
  }
}

function getPaymentMethodDataErrorString(key, value, localeString) {
  var len = value.length;
  var notEmptyAndComplete = len >= 0 && len === getPaymentMethodDataFieldMaxLength(key);
  if (key.TAG === "BillingAddress") {
    var tmp = key._0;
    if (typeof tmp !== "object" && tmp === "AddressState") {
      return "Invalid state";
    } else {
      return "";
    }
  }
  var tmp$1 = key._0;
  if (typeof tmp$1 === "object") {
    if (notEmptyAndComplete) {
      return localeString.pastExpiryErrorText;
    } else {
      return localeString.inCompleteExpiryErrorText;
    }
  }
  switch (tmp$1) {
    case "CardNumber" :
        return localeString.inValidCardErrorText;
    case "ACHRoutingNumber" :
        if (notEmptyAndComplete) {
          return "";
        } else {
          return localeString.formFieldInvalidRoutingNumber;
        }
    default:
      return "";
  }
}

function getPaymentMethodIcon(paymentMethod) {
  switch (paymentMethod) {
    case "Card" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "default-card",
                    size: 20
                  });
    case "BankTransfer" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "bank",
                    size: 20
                  });
    case "Wallet" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "wallet-generic-line",
                    size: 20
                  });
    
  }
}

function getBankTransferIcon(bankTransfer) {
  switch (bankTransfer) {
    case "ACH" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "ach_bank_transfer",
                    size: 20
                  });
    case "Pix" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "wallet-pix",
                    size: 20
                  });
    case "Bacs" :
    case "Sepa" :
        break;
    
  }
  return React.createElement(Icon$OrcaPaymentPage.make, {
              name: "bank",
              size: 20
            });
}

function getWalletIcon(wallet) {
  if (wallet === "Paypal") {
    return React.createElement(Icon$OrcaPaymentPage.make, {
                name: "wallet-paypal",
                size: 20
              });
  } else {
    return React.createElement(Icon$OrcaPaymentPage.make, {
                name: "wallet-venmo",
                size: 20
              });
  }
}

function getPaymentMethodTypeIcon(paymentMethodType) {
  switch (paymentMethodType.TAG) {
    case "Card" :
        return getPaymentMethodIcon("Card");
    case "BankTransfer" :
        return getBankTransferIcon(paymentMethodType._0);
    case "Wallet" :
        return getWalletIcon(paymentMethodType._0);
    
  }
}

var defaultFormDataDict = {};

var defaultValidityDict = {};

var defaultAvailablePaymentMethods = [];

var defaultAvailablePaymentMethodTypes = [];

function defaultPmt(pmOpt) {
  var pm = pmOpt !== undefined ? pmOpt : "Card";
  switch (pm) {
    case "Card" :
        return {
                TAG: "Card",
                _0: "Debit"
              };
    case "BankTransfer" :
        return {
                TAG: "BankTransfer",
                _0: "ACH"
              };
    case "Wallet" :
        return {
                TAG: "Wallet",
                _0: "Paypal"
              };
    
  }
}

var defaultCardFields = [
  {
    pmdMap: "payout_method_data.card.card_number",
    displayName: "user_card_number",
    fieldType: "CardNumber",
    value: undefined
  },
  {
    pmdMap: "payout_method_data.card.expiry_month",
    displayName: "user_card_exp_month",
    fieldType: {
      TAG: "CardExpDate",
      _0: "CardExpMonth"
    },
    value: undefined
  },
  {
    pmdMap: "payout_method_data.card.expiry_year",
    displayName: "user_card_exp_year",
    fieldType: {
      TAG: "CardExpDate",
      _0: "CardExpYear"
    },
    value: undefined
  }
];

var defaultAchFields = [
  {
    pmdMap: "payout_method_data.ach.bank_routing_number",
    displayName: "user_routing_number",
    fieldType: "ACHRoutingNumber",
    value: undefined
  },
  {
    pmdMap: "payout_method_data.ach.bank_account_number",
    displayName: "user_bank_account_number",
    fieldType: "ACHAccountNumber",
    value: undefined
  }
];

var defaultBacsFields = [
  {
    pmdMap: "payout_method_data.bank.bank_sort_code",
    displayName: "user_sort_code",
    fieldType: "BacsSortCode",
    value: undefined
  },
  {
    pmdMap: "payout_method_data.bank.bank_account_number",
    displayName: "user_bank_account_number",
    fieldType: "BacsAccountNumber",
    value: undefined
  }
];

var defaultPixTransferFields = [
  {
    pmdMap: "payout_method_data.bank.bank_account_number",
    displayName: "user_bank_account_number",
    fieldType: "PixBankAccountNumber",
    value: undefined
  },
  {
    pmdMap: "payout_method_data.bank.pix_key",
    displayName: "user_pix_key",
    fieldType: "PixKey",
    value: undefined
  }
];

var defaultSepaFields = [{
    pmdMap: "payout_method_data.bank.iban",
    displayName: "user_iban",
    fieldType: "SepaIban",
    value: undefined
  }];

var defaultPaypalFields = [{
    pmdMap: "payout_method_data.wallet.telephone_number",
    displayName: "user_phone_number",
    fieldType: "PaypalMobNumber",
    value: undefined
  }];

function defaultDynamicPmdFields(pmtOpt) {
  var pmt = pmtOpt !== undefined ? pmtOpt : defaultPmt(undefined);
  switch (pmt.TAG) {
    case "Card" :
        return defaultCardFields;
    case "BankTransfer" :
        switch (pmt._0) {
          case "ACH" :
              return defaultAchFields;
          case "Bacs" :
              return defaultBacsFields;
          case "Pix" :
              return defaultPixTransferFields;
          case "Sepa" :
              return defaultSepaFields;
          
        }
    case "Wallet" :
        if (pmt._0 === "Paypal") {
          return defaultPaypalFields;
        } else {
          return [];
        }
    
  }
}

function defaultPayoutDynamicFields(pmtOpt) {
  var pmt = pmtOpt !== undefined ? pmtOpt : defaultPmt(undefined);
  return {
          address: undefined,
          payoutMethodData: defaultDynamicPmdFields(pmt)
        };
}

function defaultView(layout) {
  if (layout === "Journey") {
    return {
            TAG: "Journey",
            _0: "SelectPM"
          };
  } else {
    return {
            TAG: "Tabs",
            _0: "DetailsForm"
          };
  }
}

var defaultAmount = "0.01";

var defaultCurrency = "EUR";

var defaultEnabledPaymentMethods = [
  {
    TAG: "Card",
    _0: "Credit"
  },
  {
    TAG: "Card",
    _0: "Debit"
  },
  {
    TAG: "BankTransfer",
    _0: "ACH"
  },
  {
    TAG: "BankTransfer",
    _0: "Bacs"
  },
  {
    TAG: "BankTransfer",
    _0: "Sepa"
  },
  {
    TAG: "Wallet",
    _0: "Paypal"
  }
];

var defaultEnabledPaymentMethodsWithDynamicFields = [
  {
    TAG: "Card",
    _0: [
      "Credit",
      defaultPayoutDynamicFields({
            TAG: "Card",
            _0: "Credit"
          })
    ]
  },
  {
    TAG: "Card",
    _0: [
      "Debit",
      defaultPayoutDynamicFields({
            TAG: "Card",
            _0: "Debit"
          })
    ]
  },
  {
    TAG: "BankTransfer",
    _0: [
      "ACH",
      defaultPayoutDynamicFields({
            TAG: "BankTransfer",
            _0: "ACH"
          })
    ]
  },
  {
    TAG: "BankTransfer",
    _0: [
      "Bacs",
      defaultPayoutDynamicFields({
            TAG: "BankTransfer",
            _0: "Bacs"
          })
    ]
  },
  {
    TAG: "BankTransfer",
    _0: [
      "Sepa",
      defaultPayoutDynamicFields({
            TAG: "BankTransfer",
            _0: "Sepa"
          })
    ]
  },
  {
    TAG: "Wallet",
    _0: [
      "Paypal",
      defaultPayoutDynamicFields({
            TAG: "Wallet",
            _0: "Paypal"
          })
    ]
  }
];

var defaultPaymentMethodCollectOptions = {
  enabledPaymentMethods: defaultEnabledPaymentMethods,
  enabledPaymentMethodsWithDynamicFields: defaultEnabledPaymentMethodsWithDynamicFields,
  linkId: "",
  payoutId: "",
  customerId: "",
  theme: "#1A1A1A",
  collectorName: "HyperSwitch",
  logo: "",
  returnUrl: undefined,
  amount: defaultAmount,
  currency: defaultCurrency,
  flow: "PayoutLinkInitiate",
  sessionExpiry: "",
  formLayout: "Tabs"
};

var defaultStatusInfo_message = EnglishLocale$OrcaPaymentPage.localeStrings.payoutStatusSuccessMessage;

var defaultStatusInfo = {
  status: "Success",
  payoutId: "",
  message: defaultStatusInfo_message,
  code: undefined,
  errorMessage: undefined,
  reason: undefined
};

function itemToObjMapper(dict, logger) {
  ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
        "enabledPaymentMethods",
        "linkId",
        "payoutId",
        "customerId",
        "theme",
        "collectorName",
        "logo",
        "returnUrl",
        "amount",
        "currency",
        "flow",
        "sessionExpiry",
        "formLayout"
      ], dict, "options", logger);
  var json = dict["enabledPaymentMethods"];
  var match = json !== undefined ? PaymentMethodCollectTypes$OrcaPaymentPage.decodePaymentMethodTypeArray(json, defaultDynamicPmdFields) : [
      defaultEnabledPaymentMethods,
      defaultEnabledPaymentMethodsWithDynamicFields
    ];
  return {
          enabledPaymentMethods: match[0],
          enabledPaymentMethodsWithDynamicFields: match[1],
          linkId: Utils$OrcaPaymentPage.getString(dict, "linkId", ""),
          payoutId: Utils$OrcaPaymentPage.getString(dict, "payoutId", ""),
          customerId: Utils$OrcaPaymentPage.getString(dict, "customerId", ""),
          theme: Utils$OrcaPaymentPage.getString(dict, "theme", ""),
          collectorName: Utils$OrcaPaymentPage.getString(dict, "collectorName", ""),
          logo: Utils$OrcaPaymentPage.getString(dict, "logo", ""),
          returnUrl: Core__Option.flatMap(dict["returnUrl"], Core__JSON.Decode.string),
          amount: PaymentMethodCollectTypes$OrcaPaymentPage.decodeAmount(dict, defaultAmount),
          currency: Utils$OrcaPaymentPage.getString(dict, "currency", defaultCurrency),
          flow: PaymentMethodCollectTypes$OrcaPaymentPage.decodeFlow(dict, "PayoutLinkInitiate"),
          sessionExpiry: Utils$OrcaPaymentPage.getString(dict, "sessionExpiry", ""),
          formLayout: PaymentMethodCollectTypes$OrcaPaymentPage.decodeFormLayout(dict, "Tabs")
        };
}

function calculateValidity(key, value, defaultOpt) {
  var $$default = defaultOpt !== undefined ? Caml_option.valFromOption(defaultOpt) : undefined;
  if (key.TAG !== "BillingAddress") {
    var tmp = key._0;
    if (typeof tmp === "object") {
      if (value.length > 0 && CardUtils$OrcaPaymentPage.getExpiryValidity(value)) {
        return true;
      } else if (value.length === 0) {
        return $$default;
      } else {
        return false;
      }
    }
    switch (tmp) {
      case "CardNumber" :
          if (CardUtils$OrcaPaymentPage.cardNumberInRange(value).includes(true) && CardUtils$OrcaPaymentPage.calculateLuhn(value)) {
            return true;
          } else if (value.length === 0) {
            return $$default;
          } else {
            return false;
          }
      case "ACHRoutingNumber" :
          if (value.length !== 9) {
            return false;
          }
          var match = Core__Int.fromString(undefined, value.charAt(0));
          var match$1 = Core__Int.fromString(undefined, value.charAt(3));
          var match$2 = Core__Int.fromString(undefined, value.charAt(6));
          var p1 = match !== undefined && match$1 !== undefined && match$2 !== undefined ? Math.imul(3, (match + match$1 | 0) + match$2 | 0) : undefined;
          var match$3 = Core__Int.fromString(undefined, value.charAt(1));
          var match$4 = Core__Int.fromString(undefined, value.charAt(4));
          var match$5 = Core__Int.fromString(undefined, value.charAt(7));
          var p2 = match$3 !== undefined && match$4 !== undefined && match$5 !== undefined ? Math.imul(7, (match$3 + match$4 | 0) + match$5 | 0) : undefined;
          var match$6 = Core__Int.fromString(undefined, value.charAt(2));
          var match$7 = Core__Int.fromString(undefined, value.charAt(5));
          var match$8 = Core__Int.fromString(undefined, value.charAt(8));
          var p3 = match$6 !== undefined && match$7 !== undefined && match$8 !== undefined ? (match$6 + match$7 | 0) + match$8 | 0 : undefined;
          if (p1 !== undefined && p2 !== undefined && p3 !== undefined) {
            return ((p1 + p2 | 0) + p3 | 0) % 10 === 0;
          } else {
            return false;
          }
      case "SepaIban" :
          return value.length > 13 && value.length < 34;
      case "SepaBic" :
          return true;
      default:
        
    }
  }
  if (value.length > 0) {
    return true;
  } else {
    return false;
  }
}

function checkValidity(keys, fieldValidityDict, defaultValidityOpt) {
  var defaultValidity = defaultValidityOpt !== undefined ? defaultValidityOpt : true;
  return Core__Array.reduce(keys, true, (function (acc, key) {
                var validity = fieldValidityDict[key];
                if (validity !== undefined) {
                  if (acc) {
                    return Core__Option.getOr(Caml_option.valFromOption(validity), defaultValidity);
                  } else {
                    return false;
                  }
                } else {
                  return defaultValidity;
                }
              }));
}

function processPaymentMethodDataFields(dynamicFieldsInfo, paymentMethodDataDict, fieldValidityDict) {
  var match = Core__Array.reduce(dynamicFieldsInfo, [
        [],
        []
      ], (function (param, dynamicFieldInfo) {
          var keys = param[1];
          var dataArr = param[0];
          var match = dynamicFieldInfo.fieldType;
          if (typeof match !== "object") {
            var key = {
              TAG: "PayoutMethodData",
              _0: dynamicFieldInfo.fieldType
            };
            var info = {
              TAG: "PayoutMethodData",
              _0: dynamicFieldInfo
            };
            var fieldKey = getPaymentMethodDataFieldKey(key);
            Core__Option.map(paymentMethodDataDict[fieldKey], (function (value) {
                    dataArr.push([
                          info,
                          value.trim()
                        ]);
                  }));
            keys.push(fieldKey);
            return [
                    dataArr,
                    keys
                  ];
          }
          if (match._0 === "CardExpMonth") {
            var info$1 = {
              TAG: "PayoutMethodData",
              _0: dynamicFieldInfo
            };
            var fieldKey$1 = getPaymentMethodDataFieldKey({
                  TAG: "PayoutMethodData",
                  _0: {
                    TAG: "CardExpDate",
                    _0: "CardExpMonth"
                  }
                });
            Core__Option.map(paymentMethodDataDict[fieldKey$1], (function (value) {
                    return Core__Option.map(value.split("/")[0], (function (month) {
                                  dataArr.push([
                                        info$1,
                                        month.trim()
                                      ]);
                                }));
                  }));
            keys.push(fieldKey$1);
            return [
                    dataArr,
                    keys
                  ];
          }
          var info$2 = {
            TAG: "PayoutMethodData",
            _0: dynamicFieldInfo
          };
          var fieldKey$2 = getPaymentMethodDataFieldKey({
                TAG: "PayoutMethodData",
                _0: {
                  TAG: "CardExpDate",
                  _0: "CardExpMonth"
                }
              });
          Core__Option.map(paymentMethodDataDict[fieldKey$2], (function (value) {
                  return Core__Option.map(value.split("/")[1], (function (year) {
                                dataArr.push([
                                      info$2,
                                      "20" + year.trim()
                                    ]);
                              }));
                }));
          keys.push(fieldKey$2);
          return [
                  dataArr,
                  keys
                ];
        }));
  if (checkValidity(match[1], fieldValidityDict, false)) {
    return match[0];
  }
  
}

function processAddressFields(dynamicFieldsInfo, paymentMethodDataDict, fieldValidityDict) {
  var match = Core__Array.reduce(dynamicFieldsInfo, [
        [],
        []
      ], (function (param, dynamicFieldInfo) {
          var keys = param[1];
          var dataArr = param[0];
          var match = dynamicFieldInfo.fieldType;
          if (typeof match === "object" && match.TAG === "FullName") {
            if (match._0 === "FirstName") {
              var info = {
                TAG: "BillingAddress",
                _0: dynamicFieldInfo
              };
              var fieldKey = getPaymentMethodDataFieldKey({
                    TAG: "BillingAddress",
                    _0: {
                      TAG: "FullName",
                      _0: "FirstName"
                    }
                  });
              Core__Option.map(paymentMethodDataDict[fieldKey], (function (value) {
                      return Core__Option.map(value.split(" ")[0], (function (firstName) {
                                    dataArr.push([
                                          info,
                                          firstName
                                        ]);
                                  }));
                    }));
              keys.push(fieldKey);
              return [
                      dataArr,
                      keys
                    ];
            }
            var info$1 = {
              TAG: "BillingAddress",
              _0: dynamicFieldInfo
            };
            var fieldKey$1 = getPaymentMethodDataFieldKey({
                  TAG: "BillingAddress",
                  _0: {
                    TAG: "FullName",
                    _0: "FirstName"
                  }
                });
            Core__Option.map(paymentMethodDataDict[fieldKey$1], (function (value) {
                    var nameSplits = value.split(" ");
                    var lastName = nameSplits.slice(1, nameSplits.length).join(" ");
                    if (lastName.length > 0) {
                      dataArr.push([
                            info$1,
                            lastName
                          ]);
                    } else {
                      Core__Option.map(nameSplits[0], (function (firstName) {
                              dataArr.push([
                                    info$1,
                                    firstName
                                  ]);
                            }));
                    }
                  }));
            keys.push(fieldKey$1);
            return [
                    dataArr,
                    keys
                  ];
          }
          var key = {
            TAG: "BillingAddress",
            _0: dynamicFieldInfo.fieldType
          };
          var info$2 = {
            TAG: "BillingAddress",
            _0: dynamicFieldInfo
          };
          var fieldKey$2 = getPaymentMethodDataFieldKey(key);
          Core__Option.map(paymentMethodDataDict[fieldKey$2], (function (value) {
                  dataArr.push([
                        info$2,
                        value
                      ]);
                }));
          keys.push(fieldKey$2);
          return [
                  dataArr,
                  keys
                ];
        }));
  if (checkValidity(match[1], fieldValidityDict, false)) {
    return match[0];
  }
  
}

function formPaymentMethodData(paymentMethodDataDict, fieldValidityDict, requiredFields) {
  var addressFields = Core__Option.flatMap(requiredFields.address, (function (address) {
          return processAddressFields(address, paymentMethodDataDict, fieldValidityDict);
        }));
  return Core__Option.flatMap(processPaymentMethodDataFields(requiredFields.payoutMethodData, paymentMethodDataDict, fieldValidityDict), (function (fields) {
                return Core__Option.orElse(Core__Option.map(addressFields, (function (address) {
                                  return address.concat(fields);
                                })), fields);
              }));
}

function formBody(flow, paymentMethodData) {
  var paymentMethodType = paymentMethodData[0];
  var pmdDict = {};
  paymentMethodData[1].forEach(function (param) {
        var key = param[0]._0.pmdMap;
        var value = param[1];
        var keys = key.split(".");
        var addToDict = function (dict, keys) {
          var len = keys.length;
          if (len !== 1) {
            if (len === 0) {
              return ;
            }
            var head = Core__Option.getOr(keys[0], "");
            var nestedDict = Core__Option.getOr(dict[head], {});
            var newNestedDict;
            newNestedDict = !Array.isArray(nestedDict) && (nestedDict === null || typeof nestedDict !== "object") && typeof nestedDict !== "number" && typeof nestedDict !== "string" && typeof nestedDict !== "boolean" ? ({}) : (
                typeof nestedDict === "object" && !Array.isArray(nestedDict) ? nestedDict : ({})
              );
            addToDict(newNestedDict, keys.slice(1));
            dict[head] = newNestedDict;
            return ;
          }
          var lastKey = keys[0];
          dict[lastKey] = value;
        };
        addToDict(pmdDict, keys);
      });
  var body = [];
  Object.entries(pmdDict).map(function (param) {
        body.push([
              param[0],
              param[1]
            ]);
      });
  var paymentMethod = getPaymentMethodForPmt(paymentMethodType);
  if (flow === "PayoutLinkInitiate") {
    body.push([
          "payout_type",
          getPaymentMethodForPayoutsConfirm(paymentMethod)
        ]);
  } else {
    body.push([
          "payment_method",
          getPaymentMethod(paymentMethod)
        ]);
    body.push([
          "payment_method_type",
          getPaymentMethodType(paymentMethodType)
        ]);
  }
  return body;
}

function getPayoutDynamicFields(enabledPaymentMethodsWithDynamicFields, reqPmt) {
  return Core__Option.map(enabledPaymentMethodsWithDynamicFields.find(function (pmtr) {
                  switch (pmtr.TAG) {
                    case "Card" :
                        switch (reqPmt.TAG) {
                          case "Card" :
                              return true;
                          case "BankTransfer" :
                          case "Wallet" :
                              return false;
                          
                        }
                    case "BankTransfer" :
                        switch (pmtr._0[0]) {
                          case "ACH" :
                              switch (reqPmt.TAG) {
                                case "BankTransfer" :
                                    if (reqPmt._0 === "ACH") {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                case "Card" :
                                case "Wallet" :
                                    return false;
                                
                              }
                          case "Bacs" :
                              switch (reqPmt.TAG) {
                                case "BankTransfer" :
                                    if (reqPmt._0 === "Bacs") {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                case "Card" :
                                case "Wallet" :
                                    return false;
                                
                              }
                          case "Pix" :
                              switch (reqPmt.TAG) {
                                case "BankTransfer" :
                                    if (reqPmt._0 === "Pix") {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                case "Card" :
                                case "Wallet" :
                                    return false;
                                
                              }
                          case "Sepa" :
                              switch (reqPmt.TAG) {
                                case "BankTransfer" :
                                    if (reqPmt._0 === "Sepa") {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                case "Card" :
                                case "Wallet" :
                                    return false;
                                
                              }
                          
                        }
                    case "Wallet" :
                        if (pmtr._0[0] === "Paypal") {
                          switch (reqPmt.TAG) {
                            case "Card" :
                            case "BankTransfer" :
                                return false;
                            case "Wallet" :
                                if (reqPmt._0 === "Paypal") {
                                  return true;
                                } else {
                                  return false;
                                }
                            
                          }
                        } else {
                          switch (reqPmt.TAG) {
                            case "Card" :
                            case "BankTransfer" :
                                return false;
                            case "Wallet" :
                                if (reqPmt._0 === "Paypal") {
                                  return false;
                                } else {
                                  return true;
                                }
                            
                          }
                        }
                    
                  }
                }), (function (pmt) {
                return pmt._0[1];
              }));
}

function getDefaultsAndValidity(payoutDynamicFields) {
  return Core__Option.map(Core__Option.map(payoutDynamicFields.address, (function (address) {
                    return Core__Array.reduce(address, [
                                {},
                                {}
                              ], (function (param, field) {
                                  var validity = param[1];
                                  var values = param[0];
                                  var match = field.fieldType;
                                  var match$1 = field.value;
                                  var tmp;
                                  tmp = typeof match !== "object" || !(match.TAG === "AddressCountry" && match$1 === undefined) ? field.value : match._0[0];
                                  return Core__Option.getOr(Core__Option.map(tmp, (function (value) {
                                                    var key = {
                                                      TAG: "BillingAddress",
                                                      _0: field.fieldType
                                                    };
                                                    var isValid = calculateValidity(key, value, undefined);
                                                    var keyStr = getPaymentMethodDataFieldKey(key);
                                                    values[keyStr] = value;
                                                    validity[keyStr] = isValid;
                                                    return [
                                                            values,
                                                            validity
                                                          ];
                                                  })), [
                                              values,
                                              validity
                                            ]);
                                }));
                  })), (function (param) {
                return Core__Array.reduce(payoutDynamicFields.payoutMethodData, [
                            param[0],
                            param[1]
                          ], (function (param, field) {
                              var validity = param[1];
                              var values = param[0];
                              var value = field.value;
                              if (value !== undefined) {
                                var key = {
                                  TAG: "PayoutMethodData",
                                  _0: field.fieldType
                                };
                                var isValid = calculateValidity(key, value, undefined);
                                var keyStr = getPaymentMethodDataFieldKey(key);
                                values[keyStr] = value;
                                validity[keyStr] = isValid;
                              }
                              return [
                                      values,
                                      validity
                                    ];
                            }));
              }));
}

var defaultOptionsLimitInTabLayout = 2;

var defaultPm = "Card";

var defaultFormLayout = "Tabs";

var defaultJourneyView = "SelectPM";

var defaultTabView = "DetailsForm";

var defaultPaymentMethodCollectFlow = "PayoutLinkInitiate";

export {
  getNestedValue ,
  getOrCreateSubDict ,
  setNestedValue ,
  getPaymentMethodForPmt ,
  getPaymentMethod ,
  getPaymentMethodForPayoutsConfirm ,
  getPaymentMethodType ,
  getPaymentMethodLabel ,
  getPaymentMethodTypeLabel ,
  getPaymentMethodDataFieldKey ,
  getPaymentMethodDataFieldLabel ,
  getPaymentMethodDataFieldPlaceholder ,
  getPaymentMethodDataFieldMaxLength ,
  getPaymentMethodDataFieldCharacterPattern ,
  getPaymentMethodDataFieldInputType ,
  getPayoutImageSource ,
  getPayoutReadableStatus ,
  getPayoutStatusString ,
  getPayoutStatusMessage ,
  getPaymentMethodDataErrorString ,
  getPaymentMethodIcon ,
  getBankTransferIcon ,
  getWalletIcon ,
  getPaymentMethodTypeIcon ,
  defaultFormDataDict ,
  defaultValidityDict ,
  defaultOptionsLimitInTabLayout ,
  defaultAvailablePaymentMethods ,
  defaultAvailablePaymentMethodTypes ,
  defaultPm ,
  defaultPmt ,
  defaultCardFields ,
  defaultAchFields ,
  defaultBacsFields ,
  defaultPixTransferFields ,
  defaultSepaFields ,
  defaultPaypalFields ,
  defaultDynamicPmdFields ,
  defaultPayoutDynamicFields ,
  defaultFormLayout ,
  defaultJourneyView ,
  defaultTabView ,
  defaultView ,
  defaultPaymentMethodCollectFlow ,
  defaultAmount ,
  defaultCurrency ,
  defaultEnabledPaymentMethods ,
  defaultEnabledPaymentMethodsWithDynamicFields ,
  defaultPaymentMethodCollectOptions ,
  defaultStatusInfo ,
  itemToObjMapper ,
  calculateValidity ,
  checkValidity ,
  processPaymentMethodDataFields ,
  processAddressFields ,
  formPaymentMethodData ,
  formBody ,
  getPayoutDynamicFields ,
  getDefaultsAndValidity ,
}
/* defaultEnabledPaymentMethodsWithDynamicFields Not a pure module */
