// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Types$OrcaPaymentPage from "./Types.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Sentry$OrcaPaymentPage from "../hyper-log-catcher/Sentry.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "../Utilities/ApiEndpoint.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "../Utilities/EventListenerManager.bs.js";

var onCompleteDoThisUsed = {
  contents: false
};

var isPaymentButtonHandlerProvided = {
  contents: false
};

function make(componentType, options, setIframeRef, iframeRef, mountPostMessage, isPaymentManagementElementOpt, shouldUseTopRedirection) {
  var isPaymentManagementElement = isPaymentManagementElementOpt !== undefined ? isPaymentManagementElementOpt : false;
  try {
    var mountId = {
      contents: ""
    };
    var match = isPaymentManagementElement ? [
        "management-element",
        "payment-methods-management-element"
      ] : [
        "element",
        "payment-element"
      ];
    var elementIframeId = match[1];
    var elementIframeWrapperDivId = match[0];
    var sdkHandleOneClickConfirmPayment = Utils$OrcaPaymentPage.getDecodedBoolFromJson(options, Utils$OrcaPaymentPage.callbackFuncForExtractingValFromDict("sdkHandleOneClickConfirmPayment"), true);
    var asyncWrapper = async function (fn) {
      try {
        return await fn();
      }
      catch (raw_err){
        var err = Caml_js_exceptions.internalToOCamlException(raw_err);
        console.log("Async function call failure", err);
        return ;
      }
    };
    var currEventHandler = {
      contents: (function () {
          return new Promise((function (param, param$1) {
                        
                      }));
        })
    };
    var walletOneClickEventHandler = function ($$event) {
      var json;
      try {
        json = $$event.data;
      }
      catch (exn){
        json = null;
      }
      var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
      if (!Core__Option.isSome(dict["oneClickConfirmTriggered"])) {
        return ;
      }
      var eH = currEventHandler.contents;
      if (eH !== undefined) {
        asyncWrapper(eH).then(function () {
              var msg = Object.fromEntries([[
                      "walletClickEvent",
                      true
                    ]]);
              Window$OrcaPaymentPage.sendPostMessage($$event.source, msg);
              return Promise.resolve();
            });
        return ;
      }
      
    };
    window.addEventListener("message", walletOneClickEventHandler);
    var onSDKHandleClick = function (eventHandler) {
      currEventHandler.contents = eventHandler;
      if (Core__Option.isSome(eventHandler)) {
        isPaymentButtonHandlerProvided.contents = true;
        return ;
      }
      
    };
    var on = function (eventType, eventHandler) {
      var match = Types$OrcaPaymentPage.eventTypeMapper(eventType);
      switch (match) {
        case "Escape" :
            return EventListenerManager$OrcaPaymentPage.addSmartEventListener("keypress", (function (ev) {
                          if (ev.key === "Escape" && eventHandler !== undefined) {
                            return eventHandler(ev.data);
                          }
                          
                        }), "onEscape");
        case "Change" :
            return Utils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.elementType === componentType;
                        }), eventHandler, "Change", "onChange");
        case "Click" :
            return Utils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.clickTriggered;
                        }), eventHandler, "Click", "onClick");
        case "Ready" :
            return Utils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.ready;
                        }), eventHandler, "Ready", "onReady");
        case "Focus" :
            return Utils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.focus;
                        }), eventHandler, "Focus", "onFocus");
        case "Blur" :
            return Utils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.blur;
                        }), eventHandler, "Blur", "onBlur");
        case "CompleteDoThis" :
            if (Core__Option.isSome(eventHandler)) {
              return Utils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                            return ev.data.completeDoThis;
                          }), eventHandler, "CompleteDoThis", "onCompleteDoThis");
            } else {
              return ;
            }
        case "ConfirmPayment" :
            return Utils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.confirmTriggered;
                        }), eventHandler, "ConfirmPayment", "onHelpConfirmPayment");
        case "OneClickConfirmPayment" :
            return Utils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.oneClickConfirmTriggered;
                        }), eventHandler, "OneClickConfirmPayment", "onHelpOneClickConfirmPayment");
        case "None" :
            return ;
        
      }
    };
    var collapse = function () {
      
    };
    var blur = function () {
      iframeRef.forEach(function (iframe) {
            var message = Object.fromEntries([[
                    "doBlur",
                    true
                  ]]);
            Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
    };
    var focus = function () {
      iframeRef.forEach(function (iframe) {
            var message = Object.fromEntries([[
                    "doFocus",
                    true
                  ]]);
            Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
    };
    var clear = function () {
      iframeRef.forEach(function (iframe) {
            var message = Object.fromEntries([[
                    "doClearValues",
                    true
                  ]]);
            Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
    };
    var unmount = function () {
      var id = mountId.contents;
      var oElement = document.querySelector(id);
      if (oElement == null) {
        console.warn("INTEGRATION ERROR: Div does not seem to exist on which payment element is to mount/unmount");
      } else {
        oElement.innerHTML = "";
      }
    };
    var destroy = function () {
      unmount();
      mountId.contents = "";
    };
    var update = function (newOptions) {
      var flatOption = Utils$OrcaPaymentPage.flattenObject(options, true);
      var newFlatOption = Utils$OrcaPaymentPage.flattenObject(newOptions, true);
      var keys = Object.keys(flatOption);
      keys.forEach(function (key) {
            var op = newFlatOption[key];
            if (op !== undefined) {
              flatOption[key] = op;
              return ;
            }
            
          });
      var newEntries = Object.entries(newFlatOption);
      newEntries.forEach(function (entries) {
            var key = entries[0];
            if (Core__Option.isNone(flatOption[key])) {
              flatOption[key] = entries[1];
              return ;
            }
            
          });
      iframeRef.forEach(function (iframe) {
            var message = Object.fromEntries([
                  [
                    "paymentElementsUpdate",
                    true
                  ],
                  [
                    "options",
                    Utils$OrcaPaymentPage.unflattenObject(flatOption)
                  ]
                ]);
            Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
    };
    var mount = function (selector) {
      mountId.contents = selector;
      var localSelectorArr = selector.split("#");
      var localSelectorString = Core__Option.getOr(localSelectorArr[1], "someString");
      var iframeHeightRef = {
        contents: 25.0
      };
      var currentClass = {
        contents: "base"
      };
      var fullscreen = {
        contents: false
      };
      var fullscreenParam = {
        contents: ""
      };
      var fullscreenMetadata = {
        contents: {}
      };
      var optionsDict = Utils$OrcaPaymentPage.getDictFromJson(options);
      var handle = function (ev) {
        var eventDataObject = ev.data;
        var iframeHeight = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "iframeHeight");
        if (Core__Option.isSome(iframeHeight)) {
          var iframeId = Utils$OrcaPaymentPage.getStringFromOptionalJson(Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "iframeId"), "no-element");
          iframeHeightRef.contents = Utils$OrcaPaymentPage.getFloatFromJson(Core__Option.getOr(iframeHeight, null), 200.0);
          if (iframeId === localSelectorString) {
            var elem = document.querySelector("#orca-" + elementIframeId + "-iframeRef-" + localSelectorString);
            if (!(elem == null)) {
              switch (iframeId) {
                case "payment-method-collect" :
                case "payout-link" :
                    elem.style.height = "100vh";
                    break;
                default:
                  elem.style.height = iframeHeightRef.contents.toString() + "px";
              }
            }
            
          }
          
        }
        var val = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "openurl");
        if (val !== undefined) {
          var url = Utils$OrcaPaymentPage.getStringFromJson(val, "");
          Window$OrcaPaymentPage.replaceRootHref(url, shouldUseTopRedirection);
        }
        var isCopy = Utils$OrcaPaymentPage.getBoolFromOptionalJson(Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "copy"), false);
        var text = Utils$OrcaPaymentPage.getStringFromOptionalJson(Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "copyDetails"), "");
        if (isCopy) {
          navigator.clipboard.writeText(text).then(function (param) {
                return Promise.resolve();
              });
        }
        var combinedHyperClasses = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "concatedString");
        if (Core__Option.isSome(combinedHyperClasses)) {
          var id = Utils$OrcaPaymentPage.getStringFromOptionalJson(Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "id"), "");
          var decodeStringTest = Core__Option.flatMap(combinedHyperClasses, Core__JSON.Decode.string);
          if (decodeStringTest !== undefined) {
            currentClass.contents = decodeStringTest;
          }
          if (id === localSelectorString) {
            var elem$1 = document.querySelector("#orca-" + elementIframeWrapperDivId + "-" + localSelectorString);
            if (!(elem$1 == null)) {
              elem$1.className = currentClass.contents;
            }
            
          }
          
        }
        var iframeMounted = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "iframeMounted");
        var fullscreenIframe = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "fullscreen");
        var param = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "param");
        var metadata = Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "metadata");
        var iframeID = Utils$OrcaPaymentPage.getStringFromOptionalJson(Utils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "iframeId"), "");
        if (Core__Option.isSome(fullscreenIframe)) {
          fullscreen.contents = Utils$OrcaPaymentPage.getBoolFromOptionalJson(fullscreenIframe, false);
          fullscreenParam.contents = Utils$OrcaPaymentPage.getStringFromOptionalJson(param, "");
          fullscreenMetadata.contents = Core__Option.getOr(Core__Option.flatMap(metadata, Core__JSON.Decode.object), {});
          var fullscreenElem = document.querySelector("#orca-fullscreen-iframeRef-" + localSelectorString);
          if (!(fullscreenElem == null)) {
            fullscreenElem.innerHTML = "";
            var mainElement = document.querySelector("#orca-" + elementIframeId + "-iframeRef-" + localSelectorString);
            var iframeURL = fullscreenParam.contents !== "" ? ApiEndpoint$OrcaPaymentPage.sdkDomainUrl + "/fullscreenIndex.html?fullscreenType=" + fullscreenParam.contents : ApiEndpoint$OrcaPaymentPage.sdkDomainUrl + "/fullscreenIndex.html?fullscreenType=fullscreen";
            if (fullscreen.contents) {
              if (iframeID === localSelectorString) {
                var handleFullScreenCallback = function (ev) {
                  var json = ev.data;
                  var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                  if (Core__Option.isSome(dict["iframeMountedCallback"])) {
                    var fullScreenEle = document.querySelector("#orca-fullscreen");
                    Window$OrcaPaymentPage.iframePostMessage(fullScreenEle, Object.fromEntries([
                              [
                                "fullScreenIframeMounted",
                                true
                              ],
                              [
                                "metadata",
                                fullscreenMetadata.contents
                              ],
                              [
                                "options",
                                options
                              ]
                            ]));
                  }
                  if (!Core__Option.isSome(dict["driverMounted"])) {
                    return ;
                  }
                  Window$OrcaPaymentPage.iframePostMessage(mainElement, Object.fromEntries([
                            [
                              "fullScreenIframeMounted",
                              true
                            ],
                            [
                              "metadata",
                              fullscreenMetadata.contents
                            ],
                            [
                              "options",
                              options
                            ]
                          ]));
                  var fullScreenEle$1 = document.querySelector("#orca-fullscreen");
                  Window$OrcaPaymentPage.iframePostMessage(fullScreenEle$1, Object.fromEntries([[
                              "metadata",
                              fullscreenMetadata.contents
                            ]]));
                };
                EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleFullScreenCallback, "onFullScreenCallback");
                Utils$OrcaPaymentPage.makeIframe(fullscreenElem, iframeURL);
              }
              
            } else {
              fullscreenElem.innerHTML = "";
              Window$OrcaPaymentPage.iframePostMessage(mainElement, Object.fromEntries([
                        [
                          "fullScreenIframeMounted",
                          false
                        ],
                        [
                          "options",
                          options
                        ]
                      ]));
            }
          }
          
        }
        if (Core__Option.isSome(iframeMounted)) {
          return mountPostMessage(document.querySelector("#orca-" + elementIframeId + "-iframeRef-" + localSelectorString), localSelectorString, sdkHandleOneClickConfirmPayment);
        }
        
      };
      EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handle, "onMount-" + componentType);
      var oElement = document.querySelector(selector);
      var classesBase = Utils$OrcaPaymentPage.getClasses(optionsDict, "base");
      var additionalIframeStyle = Utils$OrcaPaymentPage.isOtherElements(componentType) ? "height: 2rem;" : "height: 0;";
      if (oElement == null) {
        return ;
      }
      var iframeDiv = "<div id=\"orca-" + elementIframeWrapperDivId + "-" + localSelectorString + "\" style=\"height: auto; font-size: 0;\" class=\"" + componentType + " " + currentClass.contents + " " + classesBase + "\">\n          <div id=\"orca-fullscreen-iframeRef-" + localSelectorString + "\"></div>\n           <iframe\n           id =\"orca-" + elementIframeId + "-iframeRef-" + localSelectorString + "\"\n           name=\"orca-" + elementIframeId + "-iframeRef-" + localSelectorString + "\"\n          src=\"" + ApiEndpoint$OrcaPaymentPage.sdkDomainUrl + "/index.html?componentName=" + componentType + "\"\n          allow=\"payment *\"\n          name=\"orca-payment\"\n          style=\"border: 0px; " + additionalIframeStyle + " outline: none;\"\n          width=\"100%\"\n        ></iframe>\n        </div>";
      oElement.innerHTML = iframeDiv;
      setIframeRef(document.querySelector("#orca-" + elementIframeId + "-iframeRef-" + localSelectorString));
      var elem = document.querySelector("#orca-" + elementIframeId + "-iframeRef-" + localSelectorString);
      if (!(elem == null)) {
        elem.style.transition = "height 0.35s ease 0s, opacity 0.4s ease 0.1s";
        return ;
      }
      
    };
    return {
            on: on,
            collapse: collapse,
            blur: blur,
            update: update,
            destroy: destroy,
            unmount: unmount,
            mount: mount,
            focus: focus,
            clear: clear,
            onSDKHandleClick: onSDKHandleClick
          };
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    Sentry$OrcaPaymentPage.captureException(e);
    return Types$OrcaPaymentPage.defaultPaymentElement;
  }
}

export {
  onCompleteDoThisUsed ,
  isPaymentButtonHandlerProvided ,
  make ,
}
/* Utils-OrcaPaymentPage Not a pure module */
