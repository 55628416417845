// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as ConfirmType$OrcaPaymentPage from "../Types/ConfirmType.bs.js";
import * as PaymentBody$OrcaPaymentPage from "./PaymentBody.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./RecoilAtoms.bs.js";
import * as PaymentUtils$OrcaPaymentPage from "./PaymentUtils.bs.js";
import * as UtilityHooks$OrcaPaymentPage from "../Hooks/UtilityHooks.bs.js";
import * as GooglePayType$OrcaPaymentPage from "../Types/GooglePayType.bs.js";
import * as DynamicFieldsUtils$OrcaPaymentPage from "./DynamicFieldsUtils.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

function getGooglePayBodyFromResponse(gPayResponse, isGuestCustomer, paymentMethodListValueOpt, connectors, requiredFieldsOpt, stateJson, isPaymentSessionOpt, isSavedMethodsFlowOpt) {
  var paymentMethodListValue = paymentMethodListValueOpt !== undefined ? paymentMethodListValueOpt : PaymentMethodsRecord$OrcaPaymentPage.defaultList;
  var requiredFields = requiredFieldsOpt !== undefined ? requiredFieldsOpt : [];
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var isSavedMethodsFlow = isSavedMethodsFlowOpt !== undefined ? isSavedMethodsFlowOpt : false;
  var obj = GooglePayType$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(gPayResponse));
  var gPayBody = PaymentUtils$OrcaPaymentPage.appendedCustomerAcceptance(isGuestCustomer, paymentMethodListValue.payment_type, PaymentBody$OrcaPaymentPage.gpayBody(obj, connectors));
  var billingContact = GooglePayType$OrcaPaymentPage.billingContactItemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(Utils$OrcaPaymentPage.getJsonObjectFromDict(Utils$OrcaPaymentPage.getDictFromJson(obj.paymentMethodData.info), "billingAddress")));
  var shippingContact = GooglePayType$OrcaPaymentPage.billingContactItemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(Utils$OrcaPaymentPage.getJsonObjectFromDict(Utils$OrcaPaymentPage.getDictFromJson(gPayResponse), "shippingAddress")));
  var email = Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(gPayResponse), "email", "");
  var requiredFieldsBody = isPaymentSession || isSavedMethodsFlow ? DynamicFieldsUtils$OrcaPaymentPage.getGooglePayRequiredFields(billingContact, shippingContact, undefined, stateJson, email) : DynamicFieldsUtils$OrcaPaymentPage.getGooglePayRequiredFields(billingContact, shippingContact, requiredFields, stateJson, email);
  return Utils$OrcaPaymentPage.mergeAndFlattenToTuples(gPayBody, requiredFieldsBody);
}

function processPayment(body, isThirdPartyFlowOpt, intent, options, publishableKey, isManualRetryEnabled) {
  var isThirdPartyFlow = isThirdPartyFlowOpt !== undefined ? isThirdPartyFlowOpt : false;
  intent(true, body, {
        return_url: options.wallets.walletReturnUrl,
        publishableKey: publishableKey
      }, undefined, isThirdPartyFlow, undefined, isManualRetryEnabled);
}

function useHandleGooglePayResponse(connectors, intent, isSavedMethodsFlowOpt, isWalletOpt, requiredFieldsBodyOpt) {
  var isSavedMethodsFlow = isSavedMethodsFlowOpt !== undefined ? isSavedMethodsFlowOpt : false;
  var isWallet = isWalletOpt !== undefined ? isWalletOpt : true;
  var requiredFieldsBody = requiredFieldsBodyOpt !== undefined ? requiredFieldsBodyOpt : ({});
  var options = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var publishableKey = match.publishableKey;
  var isManualRetryEnabled = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  var paymentMethodListValue = Recoil.useRecoilValue(PaymentUtils$OrcaPaymentPage.paymentMethodListValue);
  var isGuestCustomer = UtilityHooks$OrcaPaymentPage.useIsGuestCustomer();
  var match$1 = React.useState(function () {
        return null;
      });
  var stateJson = match$1[0];
  PaymentUtils$OrcaPaymentPage.useStatesJson(match$1[1]);
  var paymentMethodTypes = DynamicFieldsUtils$OrcaPaymentPage.usePaymentMethodTypeFromList(paymentMethodListValue, "wallet", "google_pay");
  React.useEffect((function () {
          var handle = function (ev) {
            var json = Utils$OrcaPaymentPage.safeParse(ev.data);
            var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
            if (Core__Option.isSome(dict["gpayResponse"])) {
              var metadata = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "gpayResponse");
              var body = getGooglePayBodyFromResponse(metadata, isGuestCustomer, paymentMethodListValue, connectors, paymentMethodTypes.required_fields, stateJson, undefined, isSavedMethodsFlow);
              var googlePayBody = isWallet ? body : Utils$OrcaPaymentPage.mergeAndFlattenToTuples(body, requiredFieldsBody);
              processPayment(googlePayBody, false, intent, options, publishableKey, isManualRetryEnabled);
            }
            if (Core__Option.isSome(dict["gpayError"])) {
              Utils$OrcaPaymentPage.messageParentWindow(undefined, [[
                      "fullscreen",
                      false
                    ]]);
              if (isSavedMethodsFlow || !isWallet) {
                return Utils$OrcaPaymentPage.postFailedSubmitResponse("server_error", "Something went wrong");
              } else {
                return ;
              }
            }
            
          };
          window.addEventListener("message", handle);
          return (function () {
                    window.removeEventListener("message", handle);
                  });
        }), [
        paymentMethodTypes,
        stateJson,
        isManualRetryEnabled,
        requiredFieldsBody,
        isWallet
      ]);
}

function handleGooglePayClicked(sessionObj, componentName, iframeId, readOnly) {
  var paymentDataRequest = GooglePayType$OrcaPaymentPage.getPaymentDataFromSession(sessionObj, componentName);
  Utils$OrcaPaymentPage.messageParentWindow(undefined, [
        [
          "fullscreen",
          true
        ],
        [
          "param",
          "paymentloader"
        ],
        [
          "iframeId",
          iframeId
        ]
      ]);
  if (!readOnly) {
    return Utils$OrcaPaymentPage.messageParentWindow(undefined, [
                [
                  "GpayClicked",
                  true
                ],
                [
                  "GpayPaymentDataRequest",
                  paymentDataRequest
                ]
              ]);
  }
  
}

function useSubmitCallback(isWallet, sessionObj, componentName) {
  var areRequiredFieldsValid = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.areRequiredFieldsValid);
  var areRequiredFieldsEmpty = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.areRequiredFieldsEmpty);
  var options = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var localeString = match.localeString;
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var iframeId = match$1.iframeId;
  return React.useCallback((function (ev) {
                if (isWallet) {
                  return ;
                }
                var json = Utils$OrcaPaymentPage.safeParse(ev.data);
                var confirm = ConfirmType$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(json));
                if (confirm.doSubmit && areRequiredFieldsValid && !areRequiredFieldsEmpty) {
                  return handleGooglePayClicked(sessionObj, componentName, iframeId, options.readOnly);
                } else if (areRequiredFieldsEmpty) {
                  return Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", localeString.enterFieldsText);
                } else if (!areRequiredFieldsValid) {
                  return Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", localeString.enterValidDetailsText);
                } else {
                  return ;
                }
              }), [
              areRequiredFieldsValid,
              areRequiredFieldsEmpty,
              isWallet,
              sessionObj,
              componentName,
              iframeId
            ]);
}

export {
  getGooglePayBodyFromResponse ,
  processPayment ,
  useHandleGooglePayResponse ,
  handleGooglePayClicked ,
  useSubmitCallback ,
}
/* react Not a pure module */
