// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";

function updateArrayOfStringToOptionsTypeArray(arrayOfString) {
  return arrayOfString.map(function (item) {
              return {
                      value: item
                    };
            });
}

var defaultValue = {
  value: ""
};

function DropdownField(props) {
  var __width = props.width;
  var __className = props.className;
  var __disabled = props.disabled;
  var options = props.options;
  var fieldName = props.fieldName;
  var setDisplayValue = props.setDisplayValue;
  var displayValue = props.displayValue;
  var __isDisplayValueVisible = props.isDisplayValueVisible;
  var setValue = props.setValue;
  var value = props.value;
  var isDisplayValueVisible = __isDisplayValueVisible !== undefined ? __isDisplayValueVisible : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var className = __className !== undefined ? __className : "";
  var width = __width !== undefined ? __width : "w-full";
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var localeString = match.localeString;
  var themeObj = match.themeObj;
  var config = match.config;
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var dropdownRef = React.useRef(null);
  var match$2 = React.useState(function () {
        return false;
      });
  var setInputFocused = match$2[1];
  var inputFocused = match$2[0];
  var match$3 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var parentURL = match$3.parentURL;
  var isSpacedInnerLayout = config.appearance.innerLayout === "Spaced";
  var handleFocus = function (param) {
    setInputFocused(function (param) {
          return true;
        });
    Utils$OrcaPaymentPage.handleOnFocusPostMessage(parentURL);
  };
  var handleChange = function (ev) {
    var target = ev.target;
    var value = target.value;
    setValue(function (param) {
          return value;
        });
    if (!isDisplayValueVisible) {
      return ;
    }
    var findDisplayValue = Core__Option.getOr(options.find(function (ele) {
              return ele.value === value;
            }), defaultValue);
    if (setDisplayValue !== undefined) {
      return setDisplayValue(function (param) {
                  return Core__Option.getOr(findDisplayValue.displayValue, value);
                });
    }
    
  };
  var disbaledBG = React.useMemo((function () {
          return themeObj.colorBackground;
        }), [themeObj]);
  React.useEffect((function () {
          if (value === "" || !options.map(function (val) {
                    return val.value;
                  }).includes(value)) {
            setValue(function (param) {
                  return Core__Option.getOr(options[0], defaultValue).value;
                });
          }
          
        }), []);
  var focusClass = inputFocused || value.length > 0 ? "mb-7 pb-1 pt-2 " + themeObj.fontSizeXs + " transition-all ease-in duration-75" : "transition-all ease-in duration-75";
  var floatinglabelClass = inputFocused ? "Label--floating" : "Label--resting";
  var inputClassStyles = isSpacedInnerLayout ? "Input" : "Input-Compressed";
  var cursorClass = disabled ? "cursor-not-allowed" : "cursor-pointer";
  return React.createElement(RenderIf$OrcaPaymentPage.make, {
              condition: options.length > 0,
              children: React.createElement("div", {
                    className: "flex flex-col " + width
                  }, React.createElement(RenderIf$OrcaPaymentPage.make, {
                        condition: fieldName.length > 0 && props.appearance.labels === "Above" && isSpacedInnerLayout,
                        children: React.createElement("div", {
                              className: "Label ",
                              style: {
                                fontSize: themeObj.fontSizeLg,
                                fontWeight: themeObj.fontWeightNormal,
                                marginBottom: "5px",
                                opacity: "0.6"
                              }
                            }, fieldName)
                      }), React.createElement("div", {
                        className: "relative"
                      }, React.createElement(RenderIf$OrcaPaymentPage.make, {
                            condition: isDisplayValueVisible && Core__Option.isSome(displayValue),
                            children: React.createElement("div", {
                                  className: "absolute top-1 left-1 right-0 bottom-0  pointer-events-none rounded-sm z-20 whitespace-nowrap",
                                  style: {
                                    background: disabled ? disbaledBG : themeObj.colorBackground,
                                    padding: themeObj.spacingUnit,
                                    width: "95%",
                                    opacity: disabled ? "35%" : ""
                                  }
                                }, Core__Option.getOr(displayValue, ""))
                          }), React.createElement("select", {
                            ref: Caml_option.some(dropdownRef),
                            className: inputClassStyles + " " + className + " w-full appearance-none outline-none overflow-hidden whitespace-nowrap text-ellipsis " + cursorClass,
                            style: {
                              background: disabled ? disbaledBG : themeObj.colorBackground,
                              padding: themeObj.spacingUnit,
                              paddingRight: "22px",
                              width: "100%",
                              opacity: disabled ? "35%" : ""
                            },
                            disabled: match$1.readOnly || disabled,
                            name: "",
                            value: value,
                            onFocus: handleFocus,
                            onChange: handleChange
                          }, options.map(function (item, index) {
                                return React.createElement("option", {
                                            key: index.toString(),
                                            value: item.value
                                          }, Core__Option.getOr(item.label, item.value));
                              })), React.createElement(RenderIf$OrcaPaymentPage.make, {
                            condition: config.appearance.labels === "Floating",
                            children: React.createElement("div", {
                                  className: "Label " + floatinglabelClass + " absolute bottom-0 ml-3 " + focusClass,
                                  style: {
                                    fontSize: inputFocused || value.length > 0 ? themeObj.fontSizeXs : "",
                                    marginBottom: inputFocused || value.length > 0 ? "" : themeObj.spacingUnit,
                                    opacity: "0.6"
                                  }
                                }, fieldName)
                          }), React.createElement("div", {
                            className: "self-center absolute pointer-events-none",
                            style: {
                              color: themeObj.colorText,
                              left: localeString.localeDirection === "rtl" ? "1%" : "97%",
                              marginLeft: localeString.localeDirection === "rtl" ? "1rem" : "-1rem",
                              top: "42%",
                              opacity: disabled ? "35%" : ""
                            }
                          }, React.createElement(Icon$OrcaPaymentPage.make, {
                                name: "arrow-down",
                                size: 10
                              }))))
            });
}

var make = DropdownField;

export {
  updateArrayOfStringToOptionsTypeArray ,
  defaultValue ,
  make ,
}
/* react Not a pure module */
