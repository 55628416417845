// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Icon(props) {
  var __shouldMirrorIcon = props.shouldMirrorIcon;
  var __style = props.style;
  var __iconType = props.iconType;
  var className = props.className;
  var __width = props.width;
  var __size = props.size;
  var size = __size !== undefined ? __size : 20;
  var width = __width !== undefined ? __width : size;
  var iconType = __iconType !== undefined ? __iconType : "orca";
  var style = __style !== undefined ? __style : ({});
  var shouldMirrorIcon = __shouldMirrorIcon !== undefined ? __shouldMirrorIcon : false;
  var otherClasses = className !== undefined ? className : "";
  return React.createElement("svg", {
              className: "fill-current " + otherClasses,
              style: style,
              height: size.toString() + "px",
              width: width.toString() + "px",
              onClick: props.onClick,
              transform: shouldMirrorIcon ? "scale(-1,1)" : ""
            }, React.createElement("use", {
                  xlinkHref: publicPath + "/icons/" + iconType + ".svg#" + props.name
                }));
}

var make = Icon;

export {
  make ,
}
/* react Not a pure module */
