// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";

var errorWarning = [
  [
    "INVALID_PK",
    "Error",
    {
      TAG: "Static",
      _0: "INTEGRATION ERROR: Invalid Publishable key, starts with pk_dev_(development), pk_snd_(sandbox/test) or pk_prd_(production/live)"
    }
  ],
  [
    "DEPRECATED_LOADSTRIPE",
    "Warning",
    {
      TAG: "Static",
      _0: "loadStripe is deprecated. Please use loadHyper instead."
    }
  ],
  [
    "REQUIRED_PARAMETER",
    "Error",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "INTEGRATION ERROR: " + str + " is a required field/parameter or " + str + " cannot be empty";
        })
    }
  ],
  [
    "UNKNOWN_KEY",
    "Warning",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "Unknown Key: " + str + " is a unknown/invalid key, please provide a correct key. This might cause issue in the future";
        })
    }
  ],
  [
    "TYPE_BOOL_ERROR",
    "Error",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "Type Error: '" + str + "' Expected boolean";
        })
    }
  ],
  [
    "TYPE_STRING_ERROR",
    "Error",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "Type Error: '" + str + "' Expected string";
        })
    }
  ],
  [
    "TYPE_INT_ERROR",
    "Error",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "Type Error: '" + str + "' Expected int";
        })
    }
  ],
  [
    "VALUE_OUT_OF_RANGE",
    "Warning",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "Value out of range: '" + str + "'. Please provide a value inside the range";
        })
    }
  ],
  [
    "UNKNOWN_VALUE",
    "Warning",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "Unknown Value: " + str + ". Please provide a correct value. This might cause issue in the future";
        })
    }
  ],
  [
    "SDK_CONNECTOR_WARNING",
    "Warning",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "INTEGRATION ERROR: " + str;
        })
    }
  ],
  [
    "INVALID_FORMAT",
    "Error",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return str;
        })
    }
  ],
  [
    "HTTP_NOT_ALLOWED",
    "Error",
    {
      TAG: "Dynamic",
      _0: (function (str) {
          return "INTEGRATION ERROR: " + str + " Serve your application over HTTPS. This is a requirement both in development and in production. One way to get up and running is to use a service like ngrok.";
        })
    }
  ],
  [
    "INTERNAL_API_DOWN",
    "Warning",
    {
      TAG: "Static",
      _0: "LOAD ERROR: Something went wrong! Please try again or contact out dev support https://hyperswitch.io/docs/support"
    }
  ]
];

function manageErrorWarning(key, dynamicStrOpt, logger) {
  var dynamicStr = dynamicStrOpt !== undefined ? dynamicStrOpt : "";
  var entry = errorWarning.find(function (param) {
        return param[0] === key;
      });
  if (entry === undefined) {
    return ;
  }
  var str = entry[2];
  var type_ = entry[1];
  var value;
  value = str.TAG === "Dynamic" ? str._0(dynamicStr) : str._0;
  var logType;
  logType = type_ === "Error" ? "ERROR" : "WARNING";
  logger.setLogError(value, undefined, entry[0], undefined, undefined, logType, "USER_ERROR", undefined);
  if (type_ === "Error") {
    console.error(value);
    return Js_exn.raiseError(value);
  }
  console.warn(value);
}

function unknownKeysWarning(validKeysArr, dict, dictType, logger) {
  Object.entries(dict).forEach(function (param) {
        var key = param[0];
        if (validKeysArr.includes(key)) {
          return ;
        } else {
          return manageErrorWarning("UNKNOWN_KEY", "'" + key + "' key in " + dictType, logger);
        }
      });
}

function unknownPropValueWarning(inValidValue, validValueArr, dictType, logger) {
  var expectedValues = validValueArr.map(function (item) {
          return "'" + item + "'";
        }).join(", ");
  manageErrorWarning("UNKNOWN_VALUE", "'" + inValidValue + "' value in " + dictType + ", Expected " + expectedValues, logger);
}

function valueOutRangeWarning(num, dictType, range, logger) {
  manageErrorWarning("VALUE_OUT_OF_RANGE", num.toString() + " value in " + dictType + " Expected value between " + range, logger);
}

export {
  errorWarning ,
  manageErrorWarning ,
  unknownKeysWarning ,
  unknownPropValueWarning ,
  valueOutRangeWarning ,
}
/* No side effect */
